import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { DateTimeService } from '@shared/modals/services/date-time.service';
import { ToastService, AppData } from '@shared/index';
import { OrderService } from '../../services/order.service';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';

@Component({
	selector: 'app-order-reject-modal',
	templateUrl: './order-reject-modal.page.html',
	styleUrls: ['./order-reject-modal.page.scss'],
})
export class OrderRejectModalPage implements OnInit {
	rejectReasonForm: FormGroup;
	form = [];
	modalObj;
	itemsList = [];
	itemNextAvailableList = [];
	reason: string;
	outletClose = 'OUTLET_CLOSE';
	itemOutOfStock = 'ITEM_OUT_OF_STOCK';
	other = 'OTHER';
	isOther: boolean;
	isOutletClose: boolean;
	isItemOutOfStock: boolean;
	options: string;
	comment = '';
	regex = '\\s++$';
	outletId: string;
	constructor(private modalController: ModalController,
		private fb: FormBuilder,
		navParams: NavParams,
		private dateTimeService: DateTimeService,
		private toast: ToastService,
		private orderService: OrderService) {
		// Init the parameters and fetching the values passed while calling the modal services
		this.modalObj = navParams.get('modalObj');
		this.itemsList = this.modalObj['orderObj']['items'];
		this.outletId = this.modalObj['orderObj']['outletId'];

		console.log('---->', this.modalObj);
	}

	ngOnInit() {
		this.initForm();
	}

	initForm() {
		const itemGroupControls = this.itemsList.map(item => new FormControl(false));
		this.rejectReasonForm = this.fb.group({
			'items': new FormArray(itemGroupControls),
		});
	}

	async onMenuItemChange(event, ind, itemId) {
		event.preventDefault();
		const menuItemValue = event.detail.checked;
		if (menuItemValue === true) {
			await this.dateTimeService.openDateTimeModal().then((response) => {
				const responseData = response['data'];
				if (!responseData['isAvailable']) {
					// for pushing next available time in next available item list
					this.itemNextAvailableList.push({
						itemId: itemId,
						nextAvailable: responseData['nextAvailable']
					});
				} else {
					const newItemsArr = this.rejectReasonForm.controls['items'].value;
					newItemsArr[ind] = false;
					this.rejectReasonForm.controls['items'].setValue(newItemsArr, { emitEvent: false });
				}
			});
		} else {
			// for remove next available time from next available item list
			const index = this.itemNextAvailableList.findIndex(item => item.itemId === itemId);
			this.itemNextAvailableList.splice(index, 1);
		}
	}

	onChangeOptions(value) {
		this.options = value;
		console.log('---------->', this.options);
		if (this.options === 'OUTLET_CLOSE') {
			this.options = value;
			this.itemNextAvailableList = [];
			this.rejectReasonForm.controls['items'].setValue(this.clearItems(), { emitEvent: false });
			this.isItemOutOfStock = false;
			this.isOutletClose = true;
			this.isOther = false;
			this.comment = '';

		} else if (this.options === 'ITEM_OUT_OF_STOCK' || this.options === 'ITEM') {
			this.isItemOutOfStock = true;
			this.isOutletClose = false;
			this.isOther = false;
			this.options = value;
			this.comment = '';

		} else if (this.options === 'OTHER' || this.options === 'COMMENT') {
			this.itemNextAvailableList = [];
			this.rejectReasonForm.controls['items'].setValue(this.clearItems(), { emitEvent: false });
			this.isItemOutOfStock = false;
			this.isOutletClose = false;
			this.isOther = true;
			this.options = value;
		}
	}



	// Clear item list when change the reason
	clearItems() {
		const items = this.rejectReasonForm.controls['items'].value;
		const newItems = [];
		items.forEach((val, ind) => {
			if (val === true) {
				val = false;
				newItems.push(val);
			} else {
				newItems.push(val);
			}
		});
		return newItems;
	}

	// for close dialog box
	closeModal() {
		this.modalController.dismiss({
			isClose: true, data: {
				'isReasonFill': false
			}
		});
	}

	onReasonSubmit() {
		if (this.options === 'OUTLET_CLOSE') {
			this.setOutletOff();
		} else if (this.options === 'ITEM_OUT_OF_STOCK' || this.options === 'ITEM') {
			if (this.itemNextAvailableList.length > 0) {
				this.nextAvailableItem();
				this.reason = 'Item Out Of Stock';
				this.saveReason(this.reason);
			} else {
				this.toast.presentToast(ErrorMessage.SELECT_OUT_OF_STOCK_MSG, 'Error', 'error');
			}
		} else if (this.options === 'OTHER' || this.options === 'COMMENT') {
			if (this.comment !== '' && this.comment.trim().length > 0) {
				this.saveReason(this.comment);
			} else {
				this.toast.presentToast(ErrorMessage.FILL_OTHER_REASON_MSG, 'Error', 'error');
			}
		} else {
			this.toast.presentToast(ErrorMessage.SELECT_REASON_MSG, 'Error', 'error');
		}
	}

	// for off outlet when user click on outlet close
	setOutletOff() {
		const body = {
			outletId: this.outletId,
			available: 'off'
		};
		this.orderService.setOnOff(body).then(response => {
			const outletList = JSON.parse(JSON.stringify(AppData.outletList));
			outletList.find(item => item.outletId === this.outletId).available = 'off';
			const obj = {
				changeIndex: false,
				outletList: outletList
			};
			AppData.outletListSubject.next(obj);
			this.reason = 'Outlet Close';
			this.saveReason(this.reason);
		});
	}

	// setting date and time for next available item
	nextAvailableItem() {
		this.itemNextAvailableList.forEach((item) => {
			const body = {
				itemId: item.itemId,
				nextAvailable: item.nextAvailable,
				outletId: this.outletId,
				status: 'inactive'
			};
			this.orderService.offMenuItems(body).then((response) => { });
		});
	}

	// for save reason
	saveReason(reason) {
		console.log('---->', reason);
		this.modalController.dismiss({
			isClose: true, data: {
				'reason': reason,
				'isReasonFill': true
			}
		});
	}
}
