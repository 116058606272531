import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OffersService } from 'src/app/modules/offers/services/offers.service';
import { CommonService } from '@shared/services/common.service';
import { processTimeString } from '@shared/utils/common-functions';

@Component({
  selector: 'app-outlet-offer-creation',
  templateUrl: './outlet-offer-creation.page.html',
  styleUrls: ['./outlet-offer-creation.page.scss'],
})
export class OutletOfferCreationPage implements OnInit {


  constructor(
    private offerService: OffersService,
    public modalController: ModalController) { }

  ngOnInit() {
  }
  close() {
    this.modalController.dismiss({ isClose: true, data: {} });
  }
  saveOfferData(offerData) {
    this.offerService.createOffer(offerData).then(response => {
      const offer = response['body']['data'];
      if (offer['activeSlots']['slots']['startTime'] !== '') {
        let start_time = offer['activeSlots']['slots']['startTime'];
        let end_time = offer['activeSlots']['slots']['endTime'];
        let startTimeString = processTimeString(start_time);
        let endTimeString = processTimeString(end_time);
        offer['activeSlots']['time'] = startTimeString + '-' + endTimeString;
      }
      this.offerService.offersDataSubject.next(offer);
      this.close();
    });
  }
}
