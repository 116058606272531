export const environment = {
	production: false,
	// baseUrl: 'https://dev.so.yumzy.ai',
	baseUrl: 'https://api.dev.yumzy.ai/restorder',
  reportUrl: 'https://common.laalsa.com/reports',
	// baseUrl: 'http://localhost:3001',
	// baseUrl: 'http://142.93.209.211:3001', //Production
	// baseUrl: 'http://10.9.50.114:3001',
	supportUrl: 'https://common.laalsa.com/support', // QA
  socketUrl: 'https://common.laalsa.com',
  // socketUrl: 'https://api.common.laalsa.com/restSockets',
	// baseUrl: 'http://10.9.50.254:3001',
	fssaiBaseUrl: 'https://fssai.laalsa.com',
	gstInCheckUrl: 'https://biz.coniferlabs.in:4001',
	ifscNumberUrl: 'http://api.techm.co.in',
	// supportUrl: 'https://beta.common.yumzy.ai/supportordering',
	termsConditionsUrl: 'https://laalsadev.sgp1.digitaloceanspaces.com/common_documents/Terms&Conditions.pdf',
	notificationSound: 'https://laalsadev.sgp1.digitaloceanspaces.com/common_documents/alarm-frenzy.mp3',
	googleMapUrl: 'https://maps.googleapis.com',
};
