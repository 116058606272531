import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';

export type ToasterType = 'success' | 'error' | 'warning' | 'info';

@Injectable({
	providedIn: 'root'
})


export class ToastService {

	private duration = 3000;
	private toasterConfig = {
		closeButton: true,
		timeOut: this.duration,
		// disableTimeOut: true
	};

	constructor(public toastCtrl: ToastController, public toasterService: ToastrService) {
		console.log('Hello ToastService ');
	}

	async presentToast(message, title, type: ToasterType) {
		this.toasterService[type](message, title, this.toasterConfig);
	}

	presentCommonErrorToast(key: string = '') {
		switch (key) {
			case 'offline':
				this.presentToast('Error', 'Device Offline. Please turn internet on and try again.', 'error');
				break;
			default:
				this.presentToast('Error', 'Please try after sometime.', 'error');
		}

	}
}
