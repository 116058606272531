import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { USER_ROLE_PERM, ALL_PERMISSION, EMAIL_PATTERN, CHAR_WITH_NUMBER } from 'src/app/core/constants/app-constants';
import { ToastService, AppData, ApiRequestService } from '@shared/index';
import { UserModel } from '@shared/models/user.model';
import { UserManagementService } from '@shared/modals/services/user-management.service';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';
import { getInputFieldValidation } from '@shared/utils/common-functions';

@Component({
	selector: 'app-user',
	templateUrl: './user.page.html',
	styleUrls: ['./user.page.scss'],
})
export class UserPage implements OnInit {
	userForm: FormGroup;
	stepForm: FormGroup;
	outletForm: FormGroup;

	userRoleSelectVal = USER_ROLE_PERM;
	permissions = ALL_PERMISSION;
	permissionsForSum = [];
	userData: UserModel;

	alloutlets = [];
	selectedOutletList = [];
	outletForminit = false;
	submitted = false;
	isOutletOn: boolean;
	constructor(private navParams: NavParams, private fb: FormBuilder, public modalController: ModalController,
		private toast: ToastService, private userManagementService: UserManagementService, private apiService: ApiRequestService,
	) {
		this.userData = this.navParams.get('userData');
		this.alloutlets = AppData.userData._permissions['outlets'];

	}

	ngOnInit() {
		this.initForm();
	}

	initForm() {
		const groupControls = this.permissions.map(c => new FormControl(c.value));
		this.userForm = this.fb.group({
			role: [this.userData._role ? this.userData._role : '', [Validators.required]],
			mobile: [this.userData._mobile, [Validators.required, Validators.minLength(10)]],
			userName: [this.userData._userName, [Validators.required, Validators.minLength(3), Validators.maxLength(30),
			Validators.pattern(CHAR_WITH_NUMBER)]],
			email: [this.userData._email, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
			permissionsArray: new FormArray(groupControls),
		});

		this.stepForm = this.fb.group({
			steps: ['1']
		});

		this.getOutlets();
	}

	getOutlets() {
		this.apiService.get('outletList').then((response) => {
			this.alloutlets = response['body']['data'];
			const outletControls = this.alloutlets.map(c => new FormControl(false));
			this.outletForm = this.fb.group({
				outlets: new FormArray(outletControls),
			});
			this.outletForminit = true;
		});
	}
	userRoleChange(ev) {
		const selectedRoleArray = this.userRoleSelectVal.filter((role) => {
			return this.userForm.value['role'] === role['role'];
		});

		const selectedRole = selectedRoleArray[0];
		const newData = [];

		this.permissions.forEach((perm) => {
			newData.push(selectedRole['permissions'][perm.name]);
		});

		this.userForm.controls['permissionsArray'].setValue(newData);
	}

	close() {
		this.modalController.dismiss({ isClose: true, data: {} });
	}

	// for checking valid fields
	getValid(fieldName) {
		return getInputFieldValidation(this.userForm, fieldName, this.submitted);
	}

	onPermissionChange(ind) {
		const newArray = this.userForm.get('permissionsArray').value;
		if (ind === 0) {
			newArray[4] = true
			this.isOutletOn = true;
			this.userForm.controls['permissionsArray'].setValue(newArray);
		} else {
			this.isOutletOn = false;
		}
	}
	setOutletOnReady(ind) {
		const newArray = this.userForm.get('permissionsArray').value;
		if (newArray[0] === true) {
			if (ind === 4) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}


	first() {
		this.submitted = true;
		if (!this.userForm.invalid) {
			const permissionObj = {};
			this.permissionsForSum = [];
			let trueFound = false;
			this.userForm.value['permissionsArray'].forEach((permission, index) => {
				permissionObj[this.permissions[index]['name']] = permission;
				if (permission) {
					trueFound = true;
					this.permissionsForSum.push(this.permissions[index]['name']);
				}
			});

			if (!trueFound) {
				this.toast.presentToast(ErrorMessage.SELECT_AT_LEAST_ONE_PERMISSION_MSG, 'Error', 'error');
			} else {
				this.stepForm.controls['steps'].setValue('2');
				this.userData = Object.assign(new UserModel(), this.userForm.value);
				this.userData._permissions = permissionObj;
			}
		}
	}

	second() {
		this.selectedOutletList = this.alloutlets.filter((outlet, index) => {
			return this.outletForm.value['outlets'][index];
		});
		this.userData._permissions['outlets'] = this.selectedOutletList.map((outlet, index) => {
			return outlet.outletId;
		});
		if (this.userData._permissions['outlets'].length >= 1) {
			this.stepForm.controls['steps'].setValue('3');
		} else {
			this.toast.presentToast(ErrorMessage.SELECT_AT_LEAST_ONE_OUTLET_MSG, 'Warning', 'warning');
		}
	}

	async invite() {
		let inputData = {};
		inputData = Object.assign(inputData, this.userData);
		inputData['displayType'] = 'newUser';
		inputData['subuserType'] = 'sub';

		this.userManagementService.sendInvite(inputData).then((data) => {
			if (!data['isclose']) {
				this.modalController.dismiss({
					isClose: false,
					data
				});
			} else {
				if (data['data']['isMobileWrong']) {
					this.stepForm.controls['steps'].setValue('1');
				}
			}
		});
	}
	back() {
		if (this.stepForm.value['steps'] > 1) {
			this.stepForm.controls['steps'].setValue((Number(this.stepForm.value['steps']) - 1).toString());
		} else {
			this.stepForm.controls['steps'].setValue('1');
		}
	}
}
