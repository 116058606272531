import { Injectable } from '@angular/core';
import { AppData } from '@shared/index';

@Injectable({
  providedIn: 'root'
})
export class PromoItemsAutocompleteService {
  labelAttribute = 'name';
  formValueAttribute = 'numericCode';

  constructor() { }

  getResults(keyword: string) {

    const menuData = AppData.promoItemsList;
    if (keyword !== '') {
      const filterData = menuData.filter((item) => {
        return item.name.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) > -1;
      });
      return filterData.slice(0, 5);
    } else {
      return menuData.slice(0, 5);
    }
  }
}
