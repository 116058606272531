import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@shared/services/common.service';
import { ToastService, AppData } from '@shared/index';
import { CreateOffers } from '@shared/models/create-offer.model';
import { AVOID_SPACE_ONLY } from 'src/app/core/constants/app-constants';
import { NavParams } from '@ionic/angular';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';
import { processTimeString } from '@shared/utils/common-functions';

@Component({
  selector: 'app-offer-form-data',
  templateUrl: './offer-form-data.page.html',
  styleUrls: ['./offer-form-data.page.scss'],
})
export class OfferFormDataPage implements OnInit {

  offerData = new CreateOffers();
  offerFormCreate: FormGroup;
  discountType = [{ type: 'percentage' }, { type: 'net' }];
  submitted = false;
  today = new Date().toISOString().split('T')[0];
  endDateStart;
  todayTime = new Date().toISOString().split('T')[0];
  timeSlot = '';
  priceLength = 7;
  discount;
  maxDiscount;
  maxDay = '9999-12-31';
  maxDiscountDisable = false;
  @Output() sendOutletOfferData: EventEmitter<any> = new EventEmitter();
  @Input() setOfferUrlFrom: string;
  constructor(private fb: FormBuilder, private navParams: NavParams,
    public commonService: CommonService, private toast: ToastService) {

    this.offerData = this.navParams.get('offerData');
  }

  ngOnInit() {
    if (this.setOfferUrlFrom === 'itemOffer') {
      this.discountType.pop();
    }

    this.initForm();
    // this.offerData = Object.assign(this.offerFormCreate.value, this.offerData);
    console.log(this.offerFormCreate.value);
  }
  initForm() {
    this.offerFormCreate = this.fb.group({
      'promoName': [this.offerData['promoName'], [Validators.required, Validators.minLength(3), Validators.pattern(AVOID_SPACE_ONLY), Validators.maxLength(25)]],
      'budget': [this.offerData['budget'], [Validators.maxLength(7)]],
      'discountType': [this.offerData['discountType'], Validators.required],
      'value': [this.offerData['value'], Validators.required],
      'minOrderAmount': [this.offerData['minOrderAmount'], [Validators.maxLength(7)]],
      'maxDiscount': [this.offerData['maxDiscount'], [Validators.maxLength(7)]],
      'startDate': [this.offerData['startDate']],
      'endDate': [this.offerData['endDate']],
      'startTime': [this.offerData['startTime'], Validators.required],
      'endTime': [this.offerData['endTime'], Validators.required]
    });
    this.endDateStart = this.today;
    if (this.setOfferUrlFrom === 'outletOffer') {
      this.offerFormCreate.get('budget').setValidators([Validators.required]);
      this.offerFormCreate.get('minOrderAmount').setValidators([Validators.required]);
      this.offerFormCreate.get('maxDiscount').setValidators([Validators.required]);
    }
  }
  discountTypeChange(event) {

    if (event.target.value === "percentage") {
      this.priceLength = 3;
      this.maxDiscountDisable = false;
    } else {
      this.priceLength = 7;
      this.offerFormCreate.get('value').setValidators([Validators.maxLength(7)]);
      this.maxDiscountDisable = true;
      this.offerFormCreate.controls.maxDiscount.setValue(0);
    }
  }
  getValid(fieldName) {

    if (this.offerFormCreate.value.budget !== null) {
      if (this.offerFormCreate.value.discountType === "net") {
        if (parseInt(this.offerFormCreate.value.budget) === 0) {
          this.discount = false;
        } else {
          if (parseInt(this.offerFormCreate.value.value) > parseInt(this.offerFormCreate.value.budget)) {
            this.discount = true;
          } else {
            this.discount = false;
          }
        }
        if (this.offerFormCreate.value.value === '' || parseInt(this.offerFormCreate.value.value) === 0) {
          this.discount = true;
        }
      } else if (this.offerFormCreate.value.discountType === "percentage") {

        if (parseInt(this.offerFormCreate.value.value) > 100) {
          this.discount = true;
        } else {
          this.discount = false;
        }
        if (parseInt(this.offerFormCreate.value.value) > 0) {
          this.discount = false;
        }

      } else {
        if (parseInt(this.offerFormCreate.value.value) > parseInt(this.offerFormCreate.value.budget)) {
          this.discount = true;
        } else {
          this.discount = false;
        }
      }

      if (parseInt(this.offerFormCreate.value.budget) === 0) {
        this.maxDiscount = false;
      } else {
        if (parseInt(this.offerFormCreate.value.maxDiscount) > parseInt(this.offerFormCreate.value.budget)) {
          this.maxDiscount = true;
        } else {
          this.maxDiscount = false;
        }
      }

    } else if (this.offerFormCreate.value.budget === null) {
      this.discount = false;
      this.maxDiscount = false;
    }

    if (this.offerFormCreate.value.discountType === "percentage") {
      if (parseInt(this.offerFormCreate.value.value) > 100) {
        this.discount = true;
      } else {
        if (parseInt(this.offerFormCreate.value.value) > 0) {
          this.discount = false;
        }
        if (this.offerFormCreate.value.value === '' || this.offerFormCreate.value.value == 0) {
          this.discount = true;
        } else {
          this.discount = false;
        }
      }
    }


    if (this.offerFormCreate.controls['maxDiscount'].invalid && (this.offerFormCreate.controls['maxDiscount'].dirty ||
      this.offerFormCreate.controls['maxDiscount'].touched || this.submitted)) {
      this.maxDiscount = true;
    }
    if (this.offerFormCreate.controls['value'].invalid && (this.offerFormCreate.controls['value'].dirty ||
      this.offerFormCreate.controls['value'].touched || this.submitted)) {
      this.discount = true;
    }
    if (this.offerFormCreate.controls[fieldName].invalid && (this.offerFormCreate.controls[fieldName].dirty ||
      this.offerFormCreate.controls[fieldName].touched || this.submitted)) {
      return true;
    } else {
      return false;
    }
  }
  setTime(type, data) {
    if (data.isAm) {
      this.offerFormCreate.controls[`${type}`].setValue(data.hour + data.mins, { emitEvent: false });
    } else {
      if (Number(data.hour) === 12) {
        this.offerFormCreate.controls[`${type}`].setValue(data.hour + data.mins, { emitEvent: false });
      } else {
        this.offerFormCreate.controls[`${type}`].setValue((Number(data.hour) + 12) + data.mins, { emitEvent: false });
      }
    }

  }
  outletOfferSubmit() {
    this.submitted = true;
    this.offerData['endTime'] = this.offerFormCreate.value['endTime'];
    this.offerData['startTime'] = this.offerFormCreate.value['startTime'];
    let startTimeString = processTimeString(this.offerData['startTime']);
    let endTimeString = processTimeString(this.offerData['endTime']);
    this.timeSlot = startTimeString + '-' + endTimeString;
    if (this.offerFormCreate.valid) {
      if (this.maxDiscount === false && this.discount === false) {
        if (this.offerFormCreate.value['startDate'] !== null) {
          if (this.today <= this.offerFormCreate.value['startDate']) {
            if (this.offerFormCreate.value['endDate'] !== null) {
              if (this.offerFormCreate.value['startDate'] <= this.offerFormCreate.value['endDate']) {
                console.log(this.todayTime, this.offerFormCreate.value['startTime']);
                if (this.offerFormCreate.value['startDate'] === this.today) {
                  this.timeValidation();
                  if (Number(this.todayTime) < Number(this.offerFormCreate.value['startTime'])) {
                    this.timeValidationToNext();
                  } else {
                    this.toast.presentToast(ErrorMessage.CHECK_START_AND_CURRENT_TIME_MSG, 'Warning', 'warning');
                  }
                } else {
                  this.timeValidationToNext();
                }
              } else {
                this.toast.presentToast(ErrorMessage.CHECK_END_AND_START_DATE_MSG, 'Warning', 'warning');
              }
            } else {
              this.toast.presentToast(ErrorMessage.SELECT_END_DATE_MSG, 'Warning', 'warning');
            }
          } else {
            this.toast.presentToast(ErrorMessage.START_DATE_SHOULD_BE_GREATER_THEN_TODAY_MSG, 'Warning', 'warning');
          }

        } else {
          this.toast.presentToast(ErrorMessage.SELECT_START_DATE_MSG, 'Warning', 'warning');
        }
      }
    }
  }
  timeValidationToNext() {
    if (Number(this.offerFormCreate.value['startTime']) < Number(this.offerFormCreate.value['endTime'])) {
      this.offerData = Object.assign(this.offerData, this.offerFormCreate.value);
      this.offerData['outletId'] = AppData.outletId;
      this.offerData['value'] = this.offerData['value'] === "" ? "0" : this.offerData['value'];
      console.log(this.offerData);

      if (this.setOfferUrlFrom === 'outletOffer') {
        this.offerData['offerFor'] = 'outlets';
      } else {
        this.offerData['offerFor'] = 'items';
      }
      if (this.setOfferUrlFrom === 'itemOffer') {
        delete this.offerData['budget'];
        delete this.offerData['minOrderAmount'];
        delete this.offerData['maxDiscount'];
      }
      this.sendOutletOfferData.emit(this.offerData);
    } else {
      this.toast.presentToast(ErrorMessage.CHECK_END_START_DATE_MSG, 'Warning', 'warning');
    }
  }
  timeValidation() {
    this.endDateStart = this.offerFormCreate.value['startDate'];
    if (this.offerFormCreate.value['startDate'] === this.today) {
      var toDate = new Date();
      this.todayTime = String(toDate.getHours()) + String(toDate.getMinutes());
    } else {
      this.todayTime = '';
    }
  }
}
