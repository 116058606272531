// import { FirebaseMessagingService } from './shared/services/firebase-messaging.service';
import { Component, OnDestroy } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavService } from './core/services/nav/nav.service';
import { AuthService } from './core/services/auth.service';
import { AppData } from './shared';
import { Router, NavigationEnd } from '@angular/router';
import { SocketService } from '@shared/services/socket.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html'
})
export class AppComponent implements OnDestroy {
	showMenu = false;
	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private navService: NavService,
		private appData: AppData,
		private authService: AuthService,
		private router: Router,
		// private firebaseMessagingService: FirebaseMessagingService,
		private socketService: SocketService
	) {
		this.initializeApp();
		this.router.events.subscribe(response => {
			if (response instanceof NavigationEnd) {
				console.log('Router--->', response);
				console.log("------------->", response.url);
			}
		});
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.statusBar.styleDefault();
			this.splashScreen.hide();
		});

		this.navService.showMenu$.subscribe((showMenu: boolean) => {
			this.showMenu = showMenu;
		});
		if (this.authService.isAuthenticated()) {
			this.navService.showMenu$.next(true);
		}
	}


	ngOnDestroy() { }
}
