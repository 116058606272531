import { ALERT_CANCEL_BUTTON_OBJ } from './../../../../core/constants/app-constants';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { DateTimeService } from '@shared/modals/services/date-time.service';
import { MenuItemCreationService } from '@shared/modals/components/menu-item-creation/service/menu-item-creation.service';
import { AppData, ToastService } from '@shared/index';
import { MenuItemService } from '../../services/menu-item.service';
import { CommonService } from '@shared/services/common.service';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';

@Component({
	selector: 'app-menu-details',
	templateUrl: './menu-details.component.html',
	styleUrls: ['./menu-details.component.scss'],
})
export class MenuDetailsComponent implements OnInit {
	@Input() item: any;
	@Input() addons = [];
	@Input() showEditButton: boolean;
	@Output() edit: EventEmitter<any> = new EventEmitter();
	@Output() delete: EventEmitter<any> = new EventEmitter();
	arrayAddonsArray = [];

	constructor(public alertController: AlertController,
		private dateTimeService: DateTimeService,
		private menuItemService: MenuItemCreationService,
		public menuService: MenuItemService,
		private toast: ToastService,
		private commonService: CommonService) {
	}

	ngOnInit() {
		this.item.addons.forEach((itemAddon) => {
			const foundAddon = this.addons.find((addon) => {
				return itemAddon === addon.addonId;
			});
			if (foundAddon) {
				this.arrayAddonsArray = this.arrayAddonsArray.concat(foundAddon);
			}
		});
	}

	async enable(value) {
		if (!value) {
			await this.dateTimeService.openDateTimeModal().then((response) => {
				const responseData = response['data'];
				if (!responseData['isAvailable']) {
					const body = {
						'outletId': AppData.outletId,
						'status': 'inactive',
						'nextAvailable': responseData['nextAvailable'],
						itemId: this.item.id,

					}
					this.menuItemService.nextAvailability(body).then((response) => {
						this.item.availableForOrder = responseData['isAvailable'];
						this.item.nextAvailable = responseData['nextAvailable'];

					});
				} else {
					this.item.availableForOrder = responseData['isAvailable'];
					this.item.nextAvailable = '';
				}
			});
		} else {
			const body = {
				outletId: AppData.outletId,
				itemId: this.item.id,
				status: 'active'
			};
			this.menuItemService.nextAvailability(body).then((response) => {
				this.item.nextAvailable = '';
			});
		}
	}

	async openMenuitem() {
		if (await this.commonService.isOutletOnOff()) {
			this.edit.emit();
		} else {
			this.toast.presentToast(ErrorMessage.OUTLET_OFF_BEFORE_EDIT_ITEM_MSG, 'Error', 'error');
		}
	}

	async removeMenuItem(menuItemId) {
		if (await this.commonService.isOutletOnOff()) {
			this.showDeleteAlert(menuItemId);
		} else {
			this.toast.presentToast(ErrorMessage.OUTLET_OFF_BEFORE_DELETE_ITEM_MSG, 'Error', 'error');
		}
	}
	async showDeleteAlert(menuItemId) {
		const alert = await this.alertController.create({
			message: ErrorMessage.DELETE_CONFIRM_MSG,
			buttons: [ALERT_CANCEL_BUTTON_OBJ, {
				text: 'Okay',
				handler: () => {
					this.menuService.menuItemDelete(menuItemId.id).then((response) => {
						this.delete.emit(menuItemId.id);
					});
				}
			}
			]
		});
		await alert.present();
	}
}
