import { getInputFieldValidation } from '@shared/utils/common-functions';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ToastService, AppData } from '@shared/index';
import { AddonsItemService } from 'src/app/modules/menu-management/services/addons-item.service';
import { CreateAddons } from '@shared/models/create-addons.model';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';

@Component({
	selector: 'app-addons-item-creation',
	templateUrl: './addons-item-creation.page.html',
	styleUrls: ['./addons-item-creation.page.scss'],
})
export class AddonsItemCreationPage implements OnInit {
	addonsVariantForm: FormGroup;
	addonsVariantModel = new CreateAddons();
	submitted = false;
	isVariant = false;
	addonData;
	isVegNonVeg = true;
	editOptionInd = null;
	constructor(private modalController: ModalController,
		private fb: FormBuilder,
		private toast: ToastService,
		private addonItemService: AddonsItemService,
		private navParams: NavParams,
	) { }

	ngOnInit() {
		this.addonData = this.navParams.get('addonData');
		this.addonsVariantModel = Object.assign(this.addonsVariantModel, this.addonData);
		this.initForm();
	}

	initForm() {
		this.addonsVariantForm = this.fb.group({
			'addonName': [this.addonsVariantModel['addonName'], [Validators.required,
			Validators.minLength(3), Validators.max(25)]],
			'displayName': [this.addonsVariantModel['displayName'], [Validators.required,
			Validators.minLength(3), Validators.max(25)]],
			'description': [this.addonsVariantModel['description'], [Validators.minLength(3),
			Validators.max(50)]],
			'isVariant': [this.addonsVariantModel['isVariant'], Validators.required],
			'maxSelection': [this.addonsVariantModel['maxSelection']],
			'minSelection': [this.addonsVariantModel['minSelection']],
			'optionName': [this.addonsVariantModel['optionName'], [Validators.minLength(3), Validators.max(25)]],
			'cost': [this.addonsVariantModel['cost']],
			'options': this.fb.array(this.addonsVariantModel['options'].map(item => this.initAddOptions(item)))
		});
		this.addonsVariantForm.valueChanges.subscribe(data => {
			this.addonsVariantModel = Object.assign(this.addonsVariantModel, data);
			this.minValidation();
		});
	}

	get formOptionsData() { return <FormArray>this.addonsVariantForm.get('options'); }

	// for init add options
	initAddOptions(addOption): FormGroup {
		return this.fb.group({
			'optionName': [addOption.optionName],
			'cost': [addOption.cost],
			'isVeg': [addOption.isVeg]
		});
	}

	// for returning name and price value for adding the addOptions list
	optionsItems() {
		const body = {
			'optionName': this.addonsVariantForm.controls['optionName'].value,
			'cost': this.addonsVariantForm.controls['cost'].value,
			'isVeg': this.isVegNonVeg
		};
		return body;
	}

	vegNonVegToggle() {
		this.isVegNonVeg = !this.isVegNonVeg;
	}

	get addOptions() {
		return this.addonsVariantForm.get('options') as FormArray;
	}

	get optionName() {
		return this.addonsVariantForm.get('optionName').value;
	}

	get cost() {
		return this.addonsVariantForm.get('cost').value;
	}

	// for change max and min value while change is variant value
	onAddonVariantChange(value) {
		if (value) {
			this.isVariant = true;
			this.addonsVariantForm.controls['minSelection'].setValue(1);
			this.addonsVariantForm.controls['maxSelection'].setValue(1);
		} else {
			this.isVariant = false;
			this.addonsVariantForm.controls['minSelection'].setValue(0);
			this.addonsVariantForm.controls['maxSelection'].setValue(0);
		}
	}

	// Checking condition for max value should not be options
	maxOptionValidation() {
		if (this.addOptions.length >= this.addonsVariantForm.get('maxSelection').value) {
			return true;
		} else {
			return false;
		}
	}

	// Checking condition for min value should not be max value
	minValidation() {
		if (Number(this.addonsVariantModel['maxSelection']) >= Number(this.addonsVariantModel['minSelection'])) {
			return true;
		} else {
			return false;
		}
	}

	// for add options item
	addOptionItems() {
		const optionsList = this.addonsVariantForm.controls['options'].value;
		if (this.editOptionInd === null) {
			if (!optionsList.some(item => (item.optionName.trim()) === (this.addonsVariantForm.get('optionName').value).trim())) {
				this.addOptions.push(this.initAddOptions(this.optionsItems()));
				this.clearOtpFields();
			} else {
				this.toast.presentToast(ErrorMessage.OPTIONS_DUPLICATE_MSG, 'Warning', 'warning');
			}
		} else {
			const optionLists = this.addonsVariantForm.controls['options'].value;
			for (let ind = 0; ind < optionLists.length; ind++) {
				if (!optionsList.some((item, opInd) =>
					item.optionName.trim() === (this.addonsVariantForm.get('optionName').value.trim()) && opInd !== this.editOptionInd)) {
					if (ind === this.editOptionInd) {
						this.addonsVariantForm.controls['options'].value[ind] = this.optionsItems();
						this.addonsVariantForm.controls['options'].setValue(this.addonsVariantForm.controls['options'].value);
						this.clearOtpFields();
						this.editOptionInd = null;
					}
				} else {
					this.toast.presentToast(ErrorMessage.OPTIONS_DUPLICATE_MSG, 'Warning', 'warning');
					break;
				}
			}
			// const index = this.addonsVariantForm.controls['options'].value.indexOf((itm, ind) => ind === this.editOptionInd);
			// console.log(index);
			// if (!optionsList.some((item, opInd) =>
			// 	item.optionName.trim() === (this.addonsVariantForm.get('optionName').value.trim()) && opInd === index)) {
			// 	if (index === this.editOptionInd) {
			// 		this.addonsVariantForm.controls['options'].value[index] = this.optionsItems();
			// 		this.addonsVariantForm.controls['options'].setValue(this.addonsVariantForm.controls['options'].value);
			// 		this.clearOtpFields();
			// 		this.editOptionInd = null;
			// 	}
			// } else {
			// 	this.toast.presentToast(ErrorMessage.OPTIONS_DUPLICATE_MSG, 'Warning', 'warning');
			// }
		}
	}

	clearOtpFields() {
		this.addonsVariantForm.controls['optionName'].reset();
		this.addonsVariantForm.controls['cost'].reset();
		this.isVegNonVeg = true;
	}

	onClearOptInp() {
		this.clearOtpFields();
		this.editOptionInd = null;
	}
	// for remove options
	removeOptions(index: number) {
		if (this.editOptionInd === index) {
			this.editOptionInd = null;
		}
		this.addOptions.removeAt(index);
	}

	editOptions(obj, ind) {
		this.editOptionInd = ind;
		const editOptObj = obj.value;
		this.addonsVariantForm.controls['optionName'].setValue(editOptObj.optionName, { emit: false });
		this.addonsVariantForm.controls['cost'].setValue(editOptObj.cost, { emit: false });
		this.isVegNonVeg = editOptObj.isVeg
	}

	// for close dialog box
	close() {
		this.modalController.dismiss({ isClose: true, data: {} });
	}

	// for checking valid fields
	getValid(fieldName) {
		return getInputFieldValidation(this.addonsVariantForm, fieldName, this.submitted);
	}

	// for save addons or variant details
	saveAddonsAndVariant() {
		this.submitted = true;
		this.clearOtpFields();
		const maxOptionValue = this.addonsVariantForm.get('maxSelection').value;
		if (this.addonsVariantForm.valid) {
			if (this.addOptions.length > 0) {
				if (this.minValidation()) {
					if (this.maxOptionValidation()) {
						if (maxOptionValue >= 1) {
							this.createAddonsOrVariants();
						} else {
							this.toast.presentToast(ErrorMessage.MAX_VAL_CAN_NOT_LESS_THAN_ONE_MSG, 'Error', 'error');
						}
					} else {
						this.toast.presentToast(ErrorMessage.MAX_VAL_FOR_ADDED_OPTION_MSG, 'Error', 'error');
					}
				} else {
					this.toast.presentToast(ErrorMessage.MIN_MAX_OPTION_SELECTION_MSG, 'Error', 'error');
				}
			} else {
				this.toast.presentToast(ErrorMessage.ADD_AT_LEAST_ONE_OPTION, 'Error', 'error');
			}
		} else {
			return;
		}
	}

	// for create addon or variant
	createAddonsOrVariants() {
		this.addonsVariantModel = Object.assign(this.addonsVariantModel, this.addonsVariantForm.value);
		this.addonsVariantModel['outletId'] = AppData.outletId;
		console.log(this.addonsVariantModel);

		this.addonItemService.createAddonsOrVariant(this.addonsVariantModel).then((response) => {
			const addons = response['body']['data'];
			const currentAddonsList = this.addonItemService.addonsDataSubject.value;
			let updateAddonList;
			if (this.addonsVariantModel._addonId !== '') {
				const index = currentAddonsList.findIndex(item => item.addonId ===
					this.addonsVariantModel._addonId);
				// for remove edit addon item from current addons item list
				currentAddonsList.splice(index, 1);

				// After remove edit addon item from list add same item in same index position in addon list
				currentAddonsList.splice(index, 0, addons);
				updateAddonList = [...currentAddonsList]
			} else {
				// added new addon in addon list
				updateAddonList = [...currentAddonsList, addons];
			}
			this.addonItemService.addonsDataSubject.next(updateAddonList);
			this.close();

		});
	}
}
