import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserPage } from '../components/user/user.page';
import { UserModel } from '@shared/models/user.model';

@Injectable({
	providedIn: 'root'
})
export class UserModalService {

	constructor(public modalController: ModalController) { }

	async presentModal(user = new UserModel()) {
		return new Promise(async (resolve, reject) => {
			const modal = await this.modalController.create({
				component: UserPage,
				cssClass: 'user-modal-css',
				backdropDismiss: false,
				componentProps: {
					'userData': user
				}
			});
			await modal.present();
			const { data } = await modal.onDidDismiss();
			console.log(data);
			resolve(data);
		});

	}


}
