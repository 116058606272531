import { AppData } from './app-data.service';

/**
 * Type URL_KEYS for restricting the url entered
 */
export type URL_KEYS =

	// Accounts Related URLs
	'login' | 'logout' | 'initSignup' | 'sendOtp' | 'completeSignup' | 'forgetPassword' |
	'resetPassword' | 'completeRestProfile' | 'subinitSignup' | 'subcompleteSignup' | 'restData'
	// OutLet Levels URLs
	| 'fetchOutletDetails' | 'outlet' | 'allowOutletAdd' | 'outletList' | 'viewUsers' | 'setAvailability' | 'fssaiCertificateLink'


	// Others URLs
	| 'fssaiCheck' | 'gstInCheck' | 'getBankDetails'

	// menu import
	| 'addMappingItem'
	| 'menuImport' | 'menuItemCreate' | 'menuItemsList' | 'menuItemImage' | 'menuItemById' | 'uploadStatus'
	| 'processImageMapping' | 'menuItemUpdate' | 'nextAvailabilityItem' | 'menuItemDelete' | 'zipUploadUrl'
	| 'addMenuSlot' | 'getMenuSlotDetails'

	// category Urls
	| 'getCategory' | 'addSubCategory' | 'addCategory' | 'updateCategory'
	| 'deleteCategory' | 'updateSubcategory' | 'deleteSubcategory' |

	// Addons
	'createAddons' | 'getAddons' | 'deleteAddon' |

	// offers
	'createOffer' | 'getOffers' | 'deleteOffer' |

	// Order Management
	'changeStatus' | 'getOrders' | 'offMenuItem' | 'deliveryInfo'

	// subscription
	| 'getPlansList' | 'createPlanPayment' | 'checkPaymentStatus' |

	// support
	'getSupportCategories' | 'createSupport' |

	'categoryReorder' |

	// Report
	'getReport' | 'getOrderDetails' |

	// Setup
	'setupDetails' |

	//promotion
	'promotionList' | 'themeValidation' | 'themeEnroll' | 'themeLeave' | 'promoItemList' |


	'newOrderSubscription' | 'getCurrentAddress' |

	// cuisines and cityLocality
	'getCuisinesAndCities';


const UrlMapping = {
	// Accounts Related URLs

	login: { url: AppData.baseUrl + '/account/login', showMsg: false },
	logout: { url: AppData.baseUrl + '/account/logout', showMsg: true },
	initSignup: { url: AppData.baseUrl + '/account/initSignup', showMsg: false },
	sendOtp: { url: AppData.baseUrl + '/account/sendOtp', showMsg: false },
	completeSignup: { url: AppData.baseUrl + '/account/completeSignup', showMsg: true },
	forgetPassword: { url: AppData.baseUrl + '/account/forgetPassword', showMsg: false },
	resetPassword: { url: AppData.baseUrl + '/account/resetPassword', showMsg: true },
	completeRestProfile: { url: AppData.baseUrl + '/account/completeRestProfile', showMsg: true },
	subinitSignup: { url: AppData.baseUrl + '/account/subuser/initSignup', showMsg: false },
	subcompleteSignup: { url: AppData.baseUrl + '/account/subuser/completeSignup', showMsg: true },
	restData: { url: AppData.baseUrl + '/account/restData', showMsg: false },

	// OutLet Levels URLs
	outlet: { url: AppData.baseUrl + '/outlet', showMsg: true },
	allowOutletAdd: { url: AppData.baseUrl + '/outlet/allowOutletAdd', showMsg: false },
	fetchOutletDetails: { url: AppData.baseUrl + '/outlet', showMsg: false },
	outletList: { url: AppData.baseUrl + '/outlet/outletList', showMsg: false },
	viewUsers: { url: AppData.baseUrl + '/outlet/viewUsers', showMsg: false },
	setAvailability: { url: AppData.baseUrl + '/outlet/setAvailability', showMsg: false },
	fssaiCertificateLink: { url: AppData.baseUrl + '/outlet/fssaiLink', showMsg: false },

	// Others URLs
	fssaiCheck: { url: AppData.fssaiBaseUrl + '/fssai-validator/', showMsg: false },
	gstInCheck: { url: AppData.gstInCheckUrl + '/service/gstnSearch', showMsg: false },
	getBankDetails: { url: AppData.ifscNumberUrl + '/api/v1/ifsc', showMsg: false },

	// Menu import
	menuImport: { url: AppData.baseUrl + '/menu/menuExcelImport', showMsg: true },
	zipUploadUrl: { url: AppData.baseUrl + '/menu/zipUploadUrl', showMsg: false },
	uploadStatus: { url: AppData.baseUrl + '/outlet/uploadStatus', showMsg: false },
	processImageMapping: { url: AppData.baseUrl + '/menu/processImageMapping', showMsg: false },


	// Menu item Create
	menuItemCreate: { url: AppData.baseUrl + '/menu/item/addItem', showMsg: true },
	menuItemImage: { url: AppData.baseUrl + '/api/upload/uploadOne', showMsg: true },
	menuItemDelete: { url: AppData.baseUrl + '/menu/item/deleteItem', showMsg: true },
	menuItemUpdate: { url: AppData.baseUrl + '/menu/item/updateItem', showMsg: true },

	// Menu management
	menuItemsList: { url: AppData.baseUrl + '/menu/item/getAllItemsByCategory', showMsg: false },
	menuItemById: { url: AppData.baseUrl + '/menu/item/getItem', showMsg: false },
	nextAvailabilityItem: { url: AppData.baseUrl + '/menu/item/setAvailability', showMsg: true },
	addMenuSlot: { url: AppData.baseUrl + '/menu/item/opHours', showMsg: true },
	getMenuSlotDetails: { url: AppData.baseUrl + '/menu/item/opHours', showMsg: false },

	// category Url
	getCategory: { url: AppData.baseUrl + '/menu/category/getCatsByOutlet', showMsg: false },
	addCategory: { url: AppData.baseUrl + '/menu/category/addCategory', showMsg: true },
	addSubCategory: { url: AppData.baseUrl + '/menu/subCategory/addSubCategory', showMsg: true },
	updateCategory: { url: AppData.baseUrl + '/menu/category/editCategory', showMsg: true },
	updateSubcategory: { url: AppData.baseUrl + '/menu/subCategory/editSubCategory', showMsg: true },
	deleteCategory: { url: AppData.baseUrl + '/menu/category/deleteCategory', showMsg: true },
	deleteSubcategory: { url: AppData.baseUrl + '/menu/subCategory/deleteSubCategory', showMsg: true },
	offMenuItem: { url: AppData.baseUrl + '/menu/item/setAvailability', showMsg: false },

	// Mapping Items
	addMappingItem: { url: AppData.baseUrl + '/menu/item/mapItems', showMsg: true },
	// addons
	createAddons: { url: AppData.baseUrl + '/menu/addon/createAddon', showMsg: true },
	getAddons: { url: AppData.baseUrl + '/menu/addon/getAddons', showMsg: false },
	deleteAddon: { url: AppData.baseUrl + '/menu/addon/deleteAddons', showMsg: true },

	// offers
	createOffer: { url: AppData.baseUrl + '/offer/createOffer', showMsg: true },
	getOffers: { url: AppData.baseUrl + '/offer/getOffersList', showMsg: false },
	deleteOffer: { url: AppData.baseUrl + '/offer/deleteOffer', showMsg: true },

	// Order Management
	changeStatus: { url: AppData.baseUrl + '/order/changeStatus', showMsg: true },
	getOrders: { url: AppData.baseUrl + '/order/orderList', showMsg: false },
	deliveryInfo: { url: AppData.baseUrl + '/order/deliveryInfo', showMsg: false },

	// subscription
	getPlansList: { url: AppData.baseUrl + '/plans/getPlanList', showMsg: false },
	createPlanPayment: { url: AppData.baseUrl + '/plans/createPlanPayment', showMsg: false },
	checkPaymentStatus: { url: AppData.baseUrl + '/plans/checkPayment', showMsg: false },
	// support
	getSupportCategories: { url: AppData.supportUrl + '/support/supportTicket/supportCategories/restPanel', showMsg: false },
	createSupport: { url: AppData.supportUrl + '/support/supportTicket/supportMail', showMsg: true },
	categoryReorder: { url: AppData.baseUrl + '/menu/category/reorderCat', showMsg: true },

	// Report 
	getOperationalReport: { url: AppData.baseUrl + '/report/getOperationalReport', showMsg: false },
	getReport: { url: AppData.baseUrl + '/report/getReports', showMsg: false },
  getOrderDetails : { url: AppData.baseUrl + '/report/getOrderDetails', showMsg: false },
  // getOrderDetails : { url: AppData.reportUrl + '/restComm/orderDetails', showMsg: false },

	// Setup 
	setupDetails: { url: AppData.baseUrl + '/outlet', showMsg: true },

	// promotion
	promotionList: { url: AppData.baseUrl + '/promotion/getPromoList/', showMsg: false },
	themeValidation: { url: AppData.baseUrl + '/promotion/validatePromo', showMsg: false },
	themeEnroll: { url: AppData.baseUrl + '/promotion/enrollPromo', showMsg: true },
	themeLeave: { url: AppData.baseUrl + '/promotion/rollback', showMsg: true },
	promoItemList: { url: AppData.baseUrl + '/promotion/getPromoItemList', showMsg: false },

	newOrderSubscription: { url: AppData.baseUrl + '/account/newOrderSubscription', showMsg: false },
	getCurrentAddress: { url: AppData.googleMapUrl + '/maps/api/geocode/json', showMsg: false },

	// Common
	getCuisinesAndCities: { url: AppData.supportUrl + '/data', showMsg: false }

};

/**
 * add url here for generating urls, all url entry is restricted to above mentioned URL_KEYS
 */

export class RequestUrl {
	static get(key: URL_KEYS): any {
		return UrlMapping[key];
	}
}
