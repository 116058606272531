import { Injectable } from '@angular/core';
import { ApiRequestService, AppData } from '@shared/index';
import { OtpPasswordModalService } from './otp-password-modal.service';
import { UserModalService } from './user-modal.service';
import { ProfileService } from 'src/app/modules/profile/service/profile.service';

@Injectable({
	providedIn: 'root'
})
export class UserManagementService {

	constructor(private apiService: ApiRequestService, private otpModalService: OtpPasswordModalService,
		private profileService: ProfileService) { }

	sendInvite(inputData) {
		return new Promise(async (resolve, reject) => {
			const reqBody = {
				'method': 'sendOtp',
        'mobile': inputData['mobile'],
        'countryCode' : '+91'
			};

			this.apiService.post('sendOtp', reqBody).then(async (response) => {
				console.log(response);
				const respData = await this.otpModalService.presentModal(inputData);
				console.log(respData);
				const reqData = respData['data'];
				if (reqData && !respData['isclose']) {
					reqData['deviceId'] = 'web';
					this.apiService.post('subcompleteSignup', reqData).then(async (compResponse) => {
						console.log(compResponse);
						await this.profileService.getuserList(AppData.outletId);
						resolve(compResponse);
					});
				} else {
					resolve(respData);
				}
			});
		});
	}
}
