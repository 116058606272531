import { AudioService } from '@shared/index';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from './storage/storage.service';
import { AppData } from '@shared/services/app-data.service';
import { NavService } from './nav/nav.service';
import { OutletDetails } from 'src/app/modules/profile/models/outlet-details.model';
import { OrderService } from 'src/app/modules/orders/services/order.service';
import { Order } from 'src/app/modules/orders/models/order.model';
import { SocketService } from '@shared/services/socket.service';


@Injectable()
export class AuthService {
	private state: RouterStateSnapshot;
	constructor(private storage: StorageService, private router: Router, private navService: NavService,
		private orderService: OrderService, private audioService: AudioService,private socketService: SocketService, ) {
		console.log('AuthService Started');
		this.state = router.routerState.snapshot;
		if (this.isAuthenticated()) {
			this.reInitializeAppData();
		} else {
			console.log('inside initApp');
			this.initializeAppData();
		}

	}

	isAuthenticated() {
		return AppData.isAuthenticated;
	}

	initializeAppData() {
		AppData.userDataSubject.next(null);
		AppData.outletListSubject.next(null);
		AppData.restData.next(null);
		AppData.token.next(null);
		AppData.outletNameSubject$.next(null);
		AppData.isAuthenticated = false;
	}

	reInitializeAppData() {
		console.log('re-init called');
		AppData.token.next(this.storage.getItem('token'));
		AppData.userDataSubject.next(this.storage.getItem('userData'));
		const obj = {
			changeIndex: true,
			outletList: this.storage.getItem('outletList') ? this.storage.getItem('outletList') : []
		};
		AppData.outletListSubject.next(obj);

		AppData.isAuthenticated = true;
		console.log(this.state.url);
		if (this.state.url === '') {
			if (AppData.userData._status === 'incomplete') {
				this.router.navigate(['/panel/restaurant-profile']);
			} else if (AppData.outletList && AppData.outletList.length === 0) {
				this.router.navigate(['/panel/profile']);
				AppData.selectedIndexSubject.next(4);
			} else {
				this.router.navigate(['/panel/order']);
				AppData.selectedIndexSubject.next(0);
			}
		}
	}

	logout() {
		this.storage.clearStorage();
		this.initializeAppData();
		this.navService.showMenu$.next(false);
		console.log('logging out');
		AppData.selectedIndexSubject.next(-1);
		this.orderService.ordersSubject$.next({ model: new Order(), modelKey: '' });
		this.orderService.orderListIndexSubject$.next(0);
		this.orderService.orderStatusListModelKeySubject$.next('acceptanceWait');
		this.orderService.orderFilterSubject$.next(null);
		AppData.outletDetails.next(new OutletDetails());
		this.router.navigate(['/login']);
		this.audioService.stopNotificationSound();
		this.socketService.closeSocket();
	}
}
