import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'filterText'
})
export class FilterPipe implements PipeTransform {
	transform(items: any[], value: string, label: string): any[] {
		if (!items) { return []; }
		if (!value) { return items; }
		if (value === '' || value == null) { return items; }
		// Issue#13  | Jimit | 23-05-2019 | toLowerCase() added for value | START
		return items.filter(e => e[label].toLowerCase().indexOf(value.toLowerCase()) > -1);
		// Issue#13  | Jimit | 23-05-2019 | END
	}
}
