import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppData } from '@shared/index';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.page.html',
  styleUrls: ['./terms-conditions.page.scss'],
})
export class TermsConditionsPage implements OnInit {
  termsConditionsUrl :any;
  constructor(public modalController: ModalController,private sanitizer: DomSanitizer) { 
    console.log(this.termsConditionsUrl);
    this.termsConditionsUrl=this.sanitizer.bypassSecurityTrustResourceUrl( AppData.termsConditionsUrl )
    
  }

  ngOnInit() {
  }
  close() {
		this.modalController.dismiss({ isClose: true, data: { 'isChecked':false} });
  }
  acceptTermsConditions() {
    this.modalController.dismiss({ isClose: true, data: { 'isChecked':true } });
  }

}
