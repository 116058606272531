import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CategoryReorderModalService } from './service/category-reorder-modal.service';
import { AppData } from '@shared/index';

@Component({
  selector: 'app-category-reorder',
  templateUrl: './category-reorder.page.html',
  styleUrls: ['./category-reorder.page.scss'],
})
export class CategoryReorderPage implements OnInit {
  categoryReorderList = [];
  constructor(private modalController: ModalController,
    private navParams: NavParams, private catReorderService: CategoryReorderModalService) {

    this.categoryReorderList = this.navParams.get('ReorderData');
    const categoryItem = this.categoryReorderList.filter(category => category.name !== 'unassigned');
    this.categoryReorderList = categoryItem;
  }

  ngOnInit() { }

  close() {
    this.modalController.dismiss({ isClose: true, data: {} });
  }
  onRenderItems(event) {
    console.log(`Moving item from ${event.detail.from} to ${event.detail.to}`);
    let draggedItem = this.categoryReorderList.splice(event.detail.from, 1)[0];
    this.categoryReorderList.splice(event.detail.to, 0, draggedItem)
    //this.listItems = reorderArray(this.listItems, event.detail.from, event.detail.to);
    event.detail.complete();
  }

  createReorderingListObj() {
    let catObj = [];
    this.categoryReorderList.map((cat, index) => {
      let obj = { catId: cat.catId, sortIndex: index }
      catObj.push(obj);
    });
    const body = {
      'outletId': AppData.outletId,
      'indexData': catObj
    }
    return body;
  }

  async saveList() {
    const reqBody = await this.createReorderingListObj();
    this.catReorderService.categoryReorder(reqBody).then(res => {
      this.modalController.dismiss({
        isClose: true, data: {
          'isSave': true
        }
      });
    });
  }
}
