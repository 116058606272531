export class TimeSlotModel {
	private index: number;
	private days: Array<boolean>;
	private startTime: String;
	private endTime: String;
	private prepTime: number;


	constructor() {
		this.index = -1;
		this.days = [false, false, false, false, false, false, false];
		this.startTime = '0000';
		this.endTime = '0000';
		this.prepTime = 15;
	}
	public get _index(): number {
		return this.index;
	}

	public set _index(value: number) {
		this.index = value;
	}

	public get _days(): Array<boolean> {
		return this.days;
	}

	public set _days(value: Array<boolean>) {
		this.days = value;
	}

	public get _startTime(): String {
		return this.startTime;
	}

	public set _startTime(value: String) {
		this.startTime = value;
	}

	public get _endTime(): String {
		return this.endTime;
	}

	public set _endTime(value: String) {
		this.endTime = value;
	}


	public get _prepTime(): number {
		return this.prepTime;
	}

	public set _prepTime(value: number) {
		this.prepTime = value;
	}

}
