export class CreateOffers {

    promoName: string;
    budget: number;
    discountType: [];
    discountAmount: number;
    minOrderAmount: number;
    maxDiscount: number;
    startDate: string;
    endDate: string;
    private startTime: String;
	private endTime: String;
    constructor() {
        this.promoName = '';
        this.budget ;
        this.discountType = [];
        this.discountAmount ;
        this.minOrderAmount;
        this.maxDiscount;
        this.startDate;
        this.endDate;
        this.startTime = '0000';
		this.endTime = '0000';
    }
    public get _promoName(): string {
        return this.promoName;
    }
    public set _promoName(value: string) {
        this.promoName = value;
    }
    public get _startDate(): string {
        return this.startDate;
    }
    public set _startDate(value: string) {
        this.startDate = value;
    }
    public get _endDate(): string {
        return this.endDate;
    }
    public set _endDate(value: string) {
        this.endDate = value;
    }
    public get _budget(): number {
        return this.budget;
    }
    public set _budget(value: number) {
        this.budget = value;
    }
    public get _discountAmount(): number {
        return this.discountAmount;
    }
    public set _discountAmount(value: number) {
        this.discountAmount = value;
    }
    public get _minOrderAmount(): number {
        return this.minOrderAmount;
    }
    public set _minOrderAmount(value: number) {
        this.minOrderAmount = value;
    }
    public get _maxDiscount(): number {
        return this.maxDiscount;
    }
    public set _maxDiscount(value: number) {
        this.maxDiscount = value;
    }
    public get _discountType(): [] {
        return this.discountType;
    }
    public set _discountType(value: []) {
        this.discountType = value;
    }
    public get _startTime(): String {
		return this.startTime;
	}

	public set _startTime(value: String) {
		this.startTime = value;
	}

	public get _endTime(): String {
		return this.endTime;
	}

	public set _endTime(value: String) {
		this.endTime = value;
	}

}