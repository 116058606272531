import { Injectable } from '@angular/core';
import { ApiRequestService} from '@shared/index';

@Injectable({
	providedIn: 'root'
})
export class CategoryService {
  constructor(private apiService: ApiRequestService) {

  console.log("=======>Category Service");

  }
  // category add
  addcategory(obj){
    return this.apiService.post('addCategory', obj);
  }
  addSubcategory(obj){
    return this.apiService.post('addSubCategory', obj);
  }
  updateCategory(obj){
    return this.apiService.post('updateCategory', obj);
  }
  updateSubcategory(obj){
    return this.apiService.post('updateSubcategory', obj);
  }

}