import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OffersService } from 'src/app/modules/offers/services/offers.service';
import { CommonService } from '@shared/services/common.service';
import { ToastService } from '@shared/index';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';
import { processTimeString } from '@shared/utils/common-functions';

@Component({
  selector: 'app-offer-item-creation',
  templateUrl: './offer-item-creation.page.html',
  styleUrls: ['./offer-item-creation.page.scss'],
})
export class OfferItemCreationPage implements OnInit {

  offerData: any;
  stepForm: FormGroup;
  offerFormCreate: FormGroup;
  MenuSelectionsArray = [];
  timeSlot = '';
  constructor(private fb: FormBuilder,
    public modalController: ModalController, private offerService: OffersService,
    public commonService: CommonService, private toast: ToastService) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.stepForm = this.fb.group({ steps: ['1'] });
  }
  menuSelections(MenuItems, event) {
    this.MenuSelectionsArray = event;
  }

  close() {
    this.modalController.dismiss({ isClose: true, data: {} });
  }
  getItemOfferData(offerData) {
    console.log(offerData);
    this.offerData = offerData;
    console.log(this.offerData);
    this.stepForm.controls['steps'].setValue('2');
  }
  second() {
    if (this.MenuSelectionsArray.length >= 1) {
      let startTimeString = processTimeString(this.offerData.startTime);
      let endTimeString = processTimeString(this.offerData.endTime);
      this.timeSlot = startTimeString + '-' + endTimeString;
      this.stepForm.controls['steps'].setValue('3');
      // this.offerFormCreate.get('value').setValidators([Validators.pattern('^[1-9][0-9]?$|^100$')]);
    } else {
      this.toast.presentToast(ErrorMessage.ADD_MENU_ITEM_MSG, 'Warning', 'warning');
    }
  }
  saveOffer() {
    let arrayItemArray = [];
    let myNewList = [];
    console.log('-----save item offer creation-----');
    console.log(this.MenuSelectionsArray);

    this.MenuSelectionsArray.forEach((tag) => {
      arrayItemArray = arrayItemArray.concat(tag.menuItems.map(menuItem => menuItem.id));
    });
    if (arrayItemArray.length > 0) {
      myNewList = Array.from(new Set(arrayItemArray));
    }
    this.offerData['itemIds'] = myNewList;
    if (myNewList.length > 0) {
      this.saveOfferData(this.offerData);
    } else {
      this.toast.presentToast(ErrorMessage.ADD_ITEM_AND_CATE_HAVE_MENU_ITEM_MSG, 'Warning', 'warning');
    }

  }
  saveOfferData(offerData) {
    console.log(offerData);
    this.offerService.createOffer(offerData).then(response => {
      console.log(response);
      const offer = response['body']['data'];
      if (offer['activeSlots']['slots']['startTime'] !== '') {
        let start_time = offer['activeSlots']['slots']['startTime'];
        let end_time = offer['activeSlots']['slots']['endTime'];
        let startTimeString = processTimeString(start_time);
        let endTimeString = processTimeString(end_time);
        offer['activeSlots']['time'] = startTimeString + '-' + endTimeString;
      }
      this.offerService.offersDataSubject.next(offer);
      console.log(offer);
      this.close();
    })
  }
  back() {
    if (this.stepForm.value['steps'] > 1) {
      this.stepForm.controls['steps'].setValue((Number(this.stepForm.value['steps']) - 1).toString());
    } else {
      this.stepForm.controls['steps'].setValue('1');
    }
  }
}