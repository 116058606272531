import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SlotCardComponent } from '../slot-card/slot-card.component';

@Component({
	selector: 'app-clock',
	templateUrl: './clock.component.html',
	styleUrls: ['./clock.component.scss'],
})
export class ClockComponent implements OnChanges {
	@Input() timeType: string;
	@Input() timeString: string;
	@Output() emitForm: EventEmitter<any> = new EventEmitter();

	isAm = true;
	hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
	viewHours = JSON.parse(JSON.stringify(this.hours));
	mins = ['00', '15', '30', '45'];
	selectedMins = '00';
	selectedHours = '00';
	constructor(private modalController: ModalController, private fb: FormBuilder) { }
	public timeForm: FormGroup;

	ngOnChanges() {
		console.log('on Changes Called');
		this.initForm();

	}

	initForm() {
		if (Number(this.timeString.substring(0, 2)) >= 12) {
			this.isAm = false;
			// Issue#57 | Jimit | Handled pop and shift | 07-06-2019 | START
			this.viewHours = JSON.parse(JSON.stringify(this.hours));
			this.viewHours.shift();
			// Issue#57 | Jimit | Handled pop and shift | 07-06-2019 | END
			this.selectedHours = (Number(this.timeString.substring(0, 2)) === 12 ?
				Number(this.timeString.substring(0, 2)) : Number(this.timeString.substring(0, 2)) - 12).toString().padStart(2, '0');
		} else {
			this.selectedHours = Number(this.timeString.substring(0, 2)).toString().padStart(2, '0');
			this.isAm = true;
			// Issue#57 | Jimit | Handled pop and shift | 07-06-2019 | START
			this.viewHours = JSON.parse(JSON.stringify(this.hours));
			this.viewHours.pop();
			// Issue#57 | Jimit | Handled pop and shift | 07-06-2019 | END
		}
		this.selectedMins = this.timeString.substring(2, 4).padStart(2, '0');
		console.log(this.selectedHours, this.selectedMins);
		this.timeForm = this.fb.group({
			hour: [this.selectedHours],
			mins: [this.selectedMins],

		});
		this.timeForm.valueChanges.subscribe((formData) => {
			this.emitData();

		});
	}

	toggle() {
		this.isAm = !this.isAm;
		if (this.isAm) {
			this.viewHours = JSON.parse(JSON.stringify(this.hours));
			this.viewHours.pop();
		} else {
			this.viewHours = JSON.parse(JSON.stringify(this.hours));
			this.viewHours.shift();
		}
		this.emitData();
	}

	emitData() {
		const emitData = Object.assign({}, this.timeForm.value);
		emitData['isAm'] = this.isAm;
		console.log(emitData);
		this.emitForm.emit(emitData);
	}

	// async presentModal(type) {
	// 	return new Promise(async (resolve, reject) => {
	// 		const modal = await this.modalController.create({
	// 			component: SlotCardComponent,
	// 			cssClass: 'time-content',
	// 			// backdropDismiss: false,
	// 			componentProps: {
	// 				'inputList': type === 'mins' ? this.mins : this.hours
	// 			}

	// 		});
	// 		await modal.present();
	// 		const { data } = await modal.onDidDismiss();
	// 		console.log(data);
	// 		if (type === 'mins') {
	// 			// this.selectedMins = data.selectedItem;
	// 		} else {
	// 			// this.selectedHours = data.selectedItem;
	// 		}
	// 		resolve(data);
	// 	});

	// }
}
