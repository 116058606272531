import { MENUS } from 'src/app/core/constants/app-constants';
import { AppData } from '../services/app-data.service';
import { FormGroup } from '@angular/forms';

// for get single group input filed validation
export function getInputFieldValidation(formName, fieldName, isSubmitted?) {
    if (formName.controls[fieldName].invalid && (formName.controls[fieldName].dirty ||
        formName.controls[fieldName].touched || isSubmitted)) {
        return true;
    }
    return false;
}

// for get nested group input filed validation
export function getNestedGrpFieldValidation(formName, grpFieldName, fieldName, isSubmitted?) {
    if (formName.get(grpFieldName).get(fieldName).invalid && (formName.get(grpFieldName).get(fieldName).dirty ||
        formName.get(grpFieldName).get(fieldName).touched || isSubmitted)) {
        return true;
    }
    return false;
}

// filter list 
export function filterDataList(tempList, fieldName, searchStr) {
    return tempList.filter((inputObj) => {
        return inputObj[fieldName].toLowerCase().indexOf(searchStr) >= 0;
    });
}

// after login app redirect based on permission
export function routeRedirect() {
    let url = '';
    let index = 0;
    if (AppData.userData._status === 'incomplete' && AppData.userData._userType === 'owner') {
        url = 'panel/restaurant-profile';
        index = -1;
        return { url, index };
    } else if (AppData.outletList.length === 0 && AppData.userData._permissions['profile']) {
        url = 'panel/profile';
        index = 4;
        return { url, index };
    } else {
        for (let i = 0; i < MENUS.length; i++) {
            if (AppData.userData._permissions[MENUS[i].permName]) {
                url = MENUS[i].url;
                index = i;
                break;
            }
        }
        return { url, index };

    }
}

// for checking condition outlet on/off 
export function outletLetIsOff() {
    const obj = AppData.outletList.find(item => item.outletId === AppData.outletId);
    if (obj.available === 'off') {
        return true;
    }
    return false;
}

// for checking outlet active/inactive
export function outletIsActive() {
    if (AppData.outletList[AppData.selectedOutLet].status === 'inactive') {
        this.toast.presentToast('please complete outlet details.', 'Inactive Outlet', 'warning');
        return false;
    }
    return true;
}

// custom validator to check that two fields match
export function mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function processTimeString(timeString) {
    let startDateHourString = timeString.substring(0, 2);
    const startDateMinString = timeString.substring(2, 4);
    let startDateAmPmString = 'AM';
    if (Number(startDateHourString) === 12) {
        startDateAmPmString = 'PM';
    } else if (Number(startDateHourString) > 12) {
        startDateHourString = Number(startDateHourString) - 12;
        startDateAmPmString = 'PM';
    } else if (Number(startDateHourString) > 24) {
        startDateHourString = Number(startDateHourString) - 24;
        startDateAmPmString = 'AM';
    }
    return (startDateHourString + ':' + startDateMinString + ' ' + startDateAmPmString);
}