import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NavService {
	showMenu$: Subject<boolean> = new Subject<boolean>();


	constructor() { }
}
