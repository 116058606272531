import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { AppData } from '@shared/index';
@Directive({
	selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {

	feature: string;
	init = false;
	@Input()
	set appPermission(value) {
		this.feature = value;
	}

	constructor(private eleRef: ElementRef, private renderer2: Renderer2) {
		AppData.appPermissionCheck.subscribe((data) => {
			if (this.init) {
				console.log('permission check subscribe', data);
				this.permissionCheck(data);
			}
		});
	}
	ngOnInit(): void {
		this.permissionCheck();
		this.init = true;
	}

	permissionCheck(permissionName = '') {
		if (AppData.userData._status === 'incomplete' || AppData.outletList.length === 0) {
			this.renderer2.addClass(this.eleRef.nativeElement, 'no-permission');
		} else {
			this.renderer2.removeClass(this.eleRef.nativeElement, 'no-permission');
		}
		if (permissionName === '') {
			if (!AppData.userData._permissions[this.feature]) {
				if (this.feature !== 'support') {
					this.renderer2.addClass(this.eleRef.nativeElement, 'no-permission');
				}
				// if (this.feature !== 'promotion') {
				// 	this.renderer2.addClass(this.eleRef.nativeElement, 'no-permission');
				// }
			}
		} else {
			if (this.feature === permissionName) {
				console.log('Called For : ', this.feature);
				this.renderer2.removeClass(this.eleRef.nativeElement, 'no-permission');
			}
		}
	}
}
