export class MenuModel {
	private menuId: string;
	private name: string;
	private isVeg: boolean;
	private containsEgg: boolean;
	private price: number;
	private packingCharges: number;
	private gst: number;
	private cgst: number;
	private sgst: number;
	private finalPrice: number;
	private additionalCharges = [];
	private ribbon: string;
	private category: string;
	private subCategory: string;
	private description: string;
	private availableForOrder: boolean;
	private imageUrl;
	private portionSize:number;
	private unit:string;

	constructor() {
		this.name = '';
		this.isVeg = true;
		this.price = 0;
		this.containsEgg = false;
		this.packingCharges = 0;
		this.gst = 0;
		this.cgst = 0;
		this.sgst = 0;
		this.finalPrice = 0;
		this.description = '';
		this.category = '';
		this.subCategory = '';
		this.imageUrl = '';
		this.portionSize = 0;
		this.unit='';
	}
	//
	public get _menuId(): string {
		return this.menuId;
	}
	public get _unit(): string {
		return this.unit;
	}
	public get _imageUrl() {
		return this.imageUrl;
	}

	public get _name(): string {
		return this.name;
	}

	public get _isVeg(): boolean {
		return this.isVeg;
	}

	public get _containsEgg(): boolean {
		return this.containsEgg;
	}

	public get _price(): number {
		return this.price;
	}

	public get _packingCharges(): number {
		return this.packingCharges;
	}

	public get _gst(): number {
		return this.gst;
	}

	public get _cgst(): number {
		return this.cgst;
	}

	public get _sgst(): number {
		return this.sgst;
	}

	public get _finalPrice(): number {
		return this.finalPrice;
	}

	public get _ribbon(): string {
		return this.ribbon;
	}

	public get _description(): string {
		return this.description;
	}

	public get _availableForOrder(): boolean {
		return this.availableForOrder;
	}

	public get _category(): string {
		return this.category;
	}

	public get _subCategory(): string {
		return this.subCategory;
	}
	public get _portionSize(): number {
		return this.portionSize;
	}
	public set _category(value: string) {
		this.category = value;
	}
	public set _subCategory(value: string) {
		this.subCategory = value;
	}
	public set _menuId(value: string) {
		this.menuId = value;
	}

	public set _name(value: string) {
		this.name = value;
	}
	public set _imageUrl(value: any) {
		this.imageUrl = value;
	}

	public set _isVeg(value: boolean) {
		this.isVeg = value;
	}

	public set _containsEgg(value: boolean) {
		this.containsEgg = value;
	}

	public set _price(value: number) {
		this.price = value;
	}

	public set _packingCharges(value: number) {
		this.packingCharges = value;
	}

	public set _gst(value: number) {
		this.gst = value;
	}

	public set _cgst(value: number) {
		this.cgst = value;
	}

	public set _sgst(value: number) {
		this.sgst = value;
	}

	public set _finalPrice(value: number) {
		this.finalPrice = value;
	}

	public set _ribbon(value: string) {
		this.ribbon = value;
	}

	public set _description(value: string) {
		this.description = value;
	}

	public set _availableForOrder(value: boolean) {
		this.availableForOrder = value;
	}
	public set _portionSize(value: number){
		this.portionSize = value;
	}
	public set _unit(value: string) {
		this.unit = value;
	}

	public calculateGST(): any {
		const basePrice = Number(this.price) + Number(this.packingCharges);
		const totalTax = basePrice * Number(this.gst) / 100;
		const totalPrice = basePrice + totalTax;
		this.cgst = Number((totalTax / 2).toFixed(2));
		this.sgst = Number((totalTax / 2).toFixed(2));
		this.finalPrice = Number(totalPrice.toFixed(2));
		this.additionalCharges = [
			{ name: 'packingCharges', value: this.packingCharges },
			{ name: 'restaurantCharges', value: 0 }
		];
	}
}

