import { firebaseConfig } from './core/constants/app-constants';
// import { FirebaseMessagingService } from './shared/services/firebase-messaging.service';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';

import {
	GlobalErrorHandler, AppData, ApiRequestService, ToastService, LoaderService, ResponseInterceptor, TokenInterceptor, CompleteTestService
} from '@shared/index';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OtpPasswordPage } from '@shared/modals/components/otp-password/otp-password.page';
import { StorageService } from './core/services/storage/storage.service';
import { AuthService } from './core/services/auth.service';
import { OutletsPage } from '@shared/modals/components/outlets/outlets.page';
// import { RestaurantService } from './modules/restaurant-profile/services/restaurant.service';
import { UserPage } from '@shared/modals/components/user/user.page';
import { OutletsModalService } from '@shared/modals/services/outlets-modal.service';
import { CommonService } from '@shared/services/common.service';
import { UserModalService } from '@shared/modals/services/user-modal.service';
import { ProfilePopOverComponent } from '@shared/components/profile-pop-over/profile-pop-over.component';
import { MenuAddonsMappingPage } from '@shared/modals/components/menu-addons-mapping/menu-addons-mapping.page';
import { MenuItemCreationPage } from '@shared/modals/components/menu-item-creation/menu-item-creation.page';
import { SharedModule } from '@shared/shared.module';
import { CategoryPage } from '@shared/modals/components/category/category.page';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { DateTimePage } from '@shared/modals/components/date-time/date-time.page';
import { AddonsItemCreationPage } from '@shared/modals/components/addons-item-creation/addons-item-creation.page';
import { OfferItemCreationPage } from '@shared/modals/components/offer-item-creation/offer-item-creation.page';
import { OfferViewPage } from '@shared/modals/components/offer-view/offer-view.page';
import { OrderRejectModalPage } from './modules/orders/components/order-reject-modal/order-reject-modal.page';
import { OrderFilterModalPage } from './modules/orders/components/order-filter-modal/order-filter-modal.page';
import { TermsConditionsPage } from '@shared/modals/components/terms-conditions/terms-conditions.page';
import { CategoryReorderPage } from '@shared/modals/components/category-reorder/category-reorder.page';
import { OutletOfferCreationPage } from '@shared/modals/components/outlet-offer-creation/outlet-offer-creation.page';
import { OfferFormDataPage } from '@shared/modals/components/offer-form-data/offer-form-data.page';
import { PromotionDetailsPage } from './modules/promotion/modals/promotion-details/promotion-details.page';
import { ThemeDetailsPage } from './modules/themes/modals/theme-details/theme-details.page';
import { AutoCompleteModule } from 'ionic4-auto-complete';
import { DatePipe } from '@angular/common';
import { CustomDirectiveModule } from '@shared/utils/custom-directive/custom-directive.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { PromotedMenuItemsPage } from '@shared/modals/components/promoted-menu-items/promoted-menu-items.page';
import { SocketService } from '@shared/services/socket.service';
 
const COMPONENT_LIST = [
	OtpPasswordPage,
	OutletsPage,
	UserPage,
	ProfilePopOverComponent,
	MenuAddonsMappingPage,
	AddonsItemCreationPage,
	MenuItemCreationPage,
	CategoryPage,
	DateTimePage,
	OfferItemCreationPage,
	OfferViewPage,
	OrderRejectModalPage,
	OrderFilterModalPage,
	TermsConditionsPage,
	CategoryReorderPage,
	OutletOfferCreationPage,
	OfferFormDataPage,
	PromotionDetailsPage,
	ThemeDetailsPage,
	PromotedMenuItemsPage
]

@NgModule({
	declarations: [
		AppComponent,
		COMPONENT_LIST
	],
	entryComponents: [
		COMPONENT_LIST,
	],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule, // required animations module
		ToastrModule.forRoot(), // ToastrModule added
		SharedModule,
		AutoCompleteModule,
		VirtualScrollerModule,
		CustomDirectiveModule,
		AngularFireModule.initializeApp(firebaseConfig),
		AngularFireMessagingModule
	],
	providers: [
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		AppData,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ResponseInterceptor,
			multi: true
		},
		StatusBar,
		SplashScreen,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		ApiRequestService,
		LoaderService,
		ToastService,
		StorageService,
		AuthService,
		CompleteTestService,
		OutletsModalService,
		CommonService,
		UserModalService,
		DatePipe,
		// FirebaseMessagingService,
		SocketService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
