import { AutoCompleteService } from 'ionic4-auto-complete';
import { Injectable } from '@angular/core';
import { CITY_STATES_IN, CITY_STATES_US } from 'src/app/core/constants/cities-states';


@Injectable()
export class CompleteTestService implements AutoCompleteService {
	labelAttribute = 'name';
	formValueAttribute = 'state';
	cityStates = [];

	constructor() {
	}

	getResults(keyword: string) {
		console.log('get Results Called');
		const timezoneOffset = String(new Date().getTimezoneOffset());
		if (timezoneOffset === "-330") {
			this.cityStates = CITY_STATES_IN;
		} else {
			this.cityStates = CITY_STATES_US;
		}
		if (keyword !== '') {
			const filterData = this.cityStates.filter((item) => {
				return item.name.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) > -1;
			});
			console.log(filterData);
			return filterData.slice(0, 5);
		} else {
			return this.cityStates.slice(0, 5);
		}


	}
}