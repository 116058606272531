import { getInputFieldValidation } from '@shared/utils/common-functions';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AvailableDateTime } from '@shared/models/available-datetime.model';
import { ToastService } from '@shared/index';
import { MenuItemCreationService } from '../menu-item-creation/service/menu-item-creation.service';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';
@Component({
	selector: 'app-date-time',
	templateUrl: './date-time.page.html',
	styleUrls: ['./date-time.page.scss'],
})
export class DateTimePage implements OnInit {
	dateTimeForm: FormGroup;
	dateTimeModel = new AvailableDateTime();
	today;
	isAm = true;
	submitted = false;
	currentDate;
	
	constructor(private modalController: ModalController, private fb: FormBuilder,
		private toast: ToastService, private navParams: NavParams,
		private menuItemService: MenuItemCreationService) {
	}

	ngOnInit() {
		this.today = new Date().toISOString().split('T')[0];
		document.getElementsByName('date')[0].setAttribute('min', this.today);
		// Issue #51 | Jimit |Max Date validations handle | 04-06-2019 | START
		document.getElementsByName('date')[0].setAttribute('max', '9999-12-31');
		// Issue #51 | Jimit | 04-06-2019 | END
		this.dateTimeModel._nextAvailable = new Date().toISOString().split('T')[0];
		this.currentDate = new Date();
		this.initForm();
	}

	initForm() {
		this.dateTimeForm = this.fb.group({
			'nextAvailable': [this.dateTimeModel['nextAvailable'], Validators.required],
			'hours': [this.dateTimeModel['hours']],
			'minutes': [this.dateTimeModel['minutes']],
		});
	}

	// for checking valid fields
	getValid(fieldName) {
		return getInputFieldValidation(this.dateTimeForm, fieldName, this.submitted);
	}

	// for increase hours and minutes input value when click on plus and minus
	increaseValue(type) {
		if (type === 'HOURS') {
			let value = this.dateTimeForm.controls['hours'].value;
			value = isNaN(value) ? 0 : value;
			value > 11 ? value = 0 : '';
			value++;
			this.dateTimeForm.controls['hours'].setValue(value);
		} else {
			let value = this.dateTimeForm.controls['minutes'].value;
			value = isNaN(value) ? 0 : value;
			value > 44 ? value = -15 : '';
			value = 15 + value;
			this.dateTimeForm.controls['minutes'].setValue(value);
		}
	}

	// for decrease hours and minutes input value when click on plus and minus
	decreaseValue(type) {
		if (type === 'HOURS') {
			let value = this.dateTimeForm.controls['hours'].value;
			value = isNaN(value) ? 0 : value;
			value < 1 ? value = 1 : '';
			value--;
			this.dateTimeForm.controls['hours'].setValue(value);
		} else {
			let value = this.dateTimeForm.controls['minutes'].value;
			value = isNaN(value) ? 0 : value;
			value < 1 ? value = 0 : '';
			value = value - 15;
			value = value === -15 ? 0 : value;
			this.dateTimeForm.controls['minutes'].setValue(value);
		}
	}

	toggle() {
		this.isAm = !this.isAm;
	}

	// for close dialog box
	close() {
		this.modalController.dismiss({
			isClose: true, data: {
				'isAvailable': true,
			}
		});
	}

	// for convert date time to ISOString format
	convertDateAndTimeInISOString() {
		const nextAvailabilityItemDate = new Date(this.dateTimeForm.controls['nextAvailable'].value);
		if (this.isAm) {
			if (this.dateTimeForm.controls['hours'].value === 12) {
				nextAvailabilityItemDate.setHours(0);
			} else {
				nextAvailabilityItemDate.setHours(this.dateTimeForm.controls['hours'].value);
			}
		} else {
			if (this.dateTimeForm.controls['hours'].value === 12) {
				nextAvailabilityItemDate.setHours(this.dateTimeForm.controls['hours'].value);
			} else {
				nextAvailabilityItemDate.setHours((this.dateTimeForm.controls['hours'].value) + 12);
			}
		}
		nextAvailabilityItemDate.setMinutes(this.dateTimeForm.controls['minutes'].value);
		return nextAvailabilityItemDate.toISOString();
	}

	// Checking validation for Next availability time should be greater than current time.
	getCurrentDateTimeValidation() {
		const currentDateTime = Date.parse(this.currentDate);
		const selectDateAndTime = Date.parse(this.dateTimeModel['nextAvailable']);
		console.log('CURRENT DATE TIME------>', currentDateTime);
		console.log('SELECTED DATE TIME------>', selectDateAndTime);
		if (currentDateTime <= selectDateAndTime) {
			return true;
		} else {
			return false;
		}
	}

	// for check date time validation
	async saveAvailableDateTime() {
		this.submitted = true;
		this.dateTimeModel['nextAvailable'] = this.convertDateAndTimeInISOString();
		if (this.dateTimeForm.valid) {
			if (this.dateTimeForm.controls['hours'].value !== 0 ||
				this.dateTimeForm.controls['minutes'].value !== 0) {
				if (await this.getCurrentDateTimeValidation()) {
					this.setNextAvailability();
				} else {
					this.toast.presentToast(ErrorMessage.TIME_SELECTION_CONDITION_MSG, 'warning', 'warning');
				}
			} else {
				this.toast.presentToast(ErrorMessage.PLEASE_SELECT_TIME_MSG, 'warning', 'warning');
			}
		} else {
			return;
		}
	}

	setNextAvailability() {
		this.modalController.dismiss({
			isClose: true, data: {
				'nextAvailable': this.dateTimeModel['nextAvailable'],
				'isAvailable': false,
			}
		});
	}
}
