import { Component, OnInit, Renderer2 } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ToastService } from '@shared/index';
import { promoPlanContent } from 'src/app/core/constants/app-constants';
import { PlanService } from 'src/app/modules/plan/services/plan.service';

@Component({
  selector: 'app-promotion-details',
  templateUrl: './promotion-details.page.html',
  styleUrls: ['./promotion-details.page.scss'],
})
export class PromotionDetailsPage implements OnInit {

  promotionData;
  Promotion = 'Basic';
  interval: any;
  setTimer: any;
  promoPlanContent: any;

  constructor(private navParams: NavParams,
    public modalController: ModalController, private toast: ToastService,
    private planService: PlanService, ) {

    this.promotionData = this.navParams.get('promotionData');
    if (this.promotionData.key != 'basic') {
      this.Promotion = 'Pro';
      this.promoPlanContent = promoPlanContent.pro;
    } else {
      this.promoPlanContent = promoPlanContent.basic;
    }

  }

  ngOnInit() { }

  close() {
    let plan = 'NO_PLAN';
    this.modalController.dismiss({ isClose: true, data: { plan } });
  }

  enrollPlan() {
    let plan;
    if (this.Promotion === 'Basic') {
      plan = "BASIC_PLAN";
      this.modalController.dismiss({ isClose: true, data: { plan } });
    } else {
      this.onPayNow();

    }
  }
  onPayNow() {
    const body = {
      'planName': 'silver',
      'durationInMonths': '3',
      'tax': 0,
      'price': 2500,
      'promoCode': 0,
      'subTotal': 2500
    }
  }
}
