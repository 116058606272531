export const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';
export const SAMPLE_MENU_ITEM_UPLOAD_EXCEL = 'https://laalsadev.sgp1.digitaloceanspaces.com/common_documents/Menu_Upload_Sample.xlsx';
export const GOOGLE_API_KEY = 'AIzaSyB0jz-2g15TatkSFyTK0Qxl0LPv1tErScU';
export const DEVICE_ID = 'web';
export const COUNTRY_CODE = '+91';
export const NEW_COUNTRY_CODES = [{ code: '+91' }, { code: '+1' }];
export const ORDER_PAGE_LIMIT = 50;
export const APP_DETAILS = {
  'deviceId': DEVICE_ID,
  'appVersion': '1.0.0',
  'app': 'restaurant_panel',
};
export const firebaseConfig = {
  apiKey: "AIzaSyCVOGz7Vm8Xj8D_wauRiInUCFO5UIZQB2Y",
  authDomain: "restaurant-panel-d80b6.firebaseapp.com",
  databaseURL: "https://restaurant-panel-d80b6.firebaseio.com",
  projectId: "restaurant-panel-d80b6",
  storageBucket: "restaurant-panel-d80b6.appspot.com",
  messagingSenderId: "521524263993",
  appId: "1:521524263993:web:2f078059829d6fb02861e8",
  measurementId: "G-S16C1TPSM3"
};

// Timer const used for OTP sending
export const COUNTER = 2;
export const SECONDS = 59;
export const PERCENTAGE = 35;
export const OUTLET_FORM_STATUS = 'complete';
export const AVOID_SPACE = '^[a-zA-Z0-9]+(?:(?:\. |[’ ])[a-zA-Z0-9]+)*$';
export const REST_NAME_PATTERN = '^[a-zA-Z0-9]+(?:(?:\. |[’\' ])[a-zA-Z0-9]+)*$';
export const ONLY_CHARACTERS = '^[a-zA-Z]+(?:(?:\. |[’ ])[a-zA-Z]+)*$';
export const ONLY_CHAR = "^[a-zA-Z ]+$";
export const CHAR_WITH_NUMBER = "^[a-zA-Z0-9_ ]*$";
// tslint:disable-next-line: quotemark
export const AVOID_SPACE_ONLY = "^[a-zA-Z0-9!-/:-@Z-`|]+(?:(?:\. |[' ])[a-zA-Z0-9!-/:-@Z-`|]+)*$";
export const ONLY_CHARACTERS_WITH_AND_DOT = '^[a-zA-Z]+(?:(?:\. |[’ ])[.&a-zA-Z.&]+)*$';
// Issue#34,35 | Jimit | 04-06-2019 |  !#$%&\^\* chars added for validations  | START
// tslint:disable-next-line: max-line-length
export const EMAIL_PATTERN = /^(([^<>!#$%&\^\*()\[\]\\.,;:\s@'"?`~]+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Issue#34,35 | Jimit | 04-06-2019  | END


export const EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel';
export const ZIP = '.zip';
export const IMAGE = 'image/x-png,image/gif,image/jpeg';

export const INTERVAL_TIME_ONE_MIN = 1000 * 60;

export const USER_ROLE_PERM = [
  {
    role: 'Owner',
    permissions: {
      menu: true,
      order: true,
      profile: true,
      settings: true,
      report: true,
      outletOn: true,
      offers: true,
      promotion: true
    }
  },
  {
    role: 'Restaurant Manager',
    permissions: {
      menu: true,
      order: true,
      profile: false,
      settings: false,
      report: false,
      outletOn: true,
      offers: true,
      promotion: false
    }
  },
  {
    role: 'Order Manager',
    permissions: {
      menu: false,
      order: true,
      profile: false,
      settings: false,
      report: false,
      outletOn: true,
      offers: false,
      promotion: false
    }
  }
];

export const ALL_PERMISSION = [
  {
    name: 'menu',
    desc: 'List Menu , Menu Management, Mark out of stock',
    value: false,
    isVisible: true
  },
  {
    name: 'order',
    desc: 'Accept, Edit & Cancel Orders. See Order History',
    value: false,
    isVisible: true
  },
  {
    name: 'report',
    desc: 'Analyze Reports & Metrics of Your Business',
    value: false,
    isVisible: true
  },
  {
    name: 'profile',
    desc: 'Profile Creation',
    value: false,
    isVisible: true
  },
  {
    name: 'outletOn',
    desc: 'Making Outlet On/Off For Orders',
    value: false,
    isVisible: true
  }, {
    name: 'offers',
    desc: 'Create Promotions And Offers',
    value: false,
    isVisible: true
  }, {
    name: 'settings',
    desc: 'Setting for your accounts',
    value: false,
    isVisible: false
  },
  {
    name: 'promotion',
    desc: 'promotion for your accounts',
    value: false,
    isVisible: false
  }
];

export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const PREP_TIMING = ['15', '20', '25', '30', '35', '40', '45', '50', '55', '60'];
export const GST_PERCENTAGE = [
  { 'id': 0, 'itemName': '0 %' },
  { 'id': 5, 'itemName': '5 %' },
  { 'id': 12, 'itemName': '12 %' },
  { 'id': 18, 'itemName': '18 %' },
];
export const WEIGHT_TYPE_LIST = ['ml', 'gms']
export const AllowedExcelFormat = [
  'xlsx',
  'xls',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export const MENUS = [
  {
    name: 'Orders', permName: 'order', icon: 'clipboard', url: '/panel/order',
    sub_menus: []
  },
  {
    name: 'Menu', permName: 'menu', icon: 'restaurant', url: '/panel/menu',
    sub_menus: [
      { name: 'Menu Items', url: '/panel/menu/menu-items' },
      { name: 'Addons', url: '/panel/menu/addons' },
      { name: 'Categories', url: '/panel/menu/category' },
      { name: 'Menu Availability Time', url: 'panel/menu/menuOprTime' }
    ]
  },
  {
    name: 'Reports', permName: 'report', icon: 'trending-up', url: '/panel/report',
    sub_menus: []
  },
  {
    name: 'Offers', permName: 'offers', icon: 'gift', url: '/panel/offer',
    sub_menus: []
  },
  {
    name: 'Profile', permName: 'profile', icon: 'business', url: '/panel/profile',
    sub_menus: []
  },
  {
    name: 'Support', permName: 'support', icon: 'help-buoy', url: '/panel/support',
    sub_menus: []
  },
  {
    name: 'Setup', permName: 'settings', icon: 'home', url: '/panel/setup',
    sub_menus: []
  },
  {
    name: 'Theme', permName: 'order', icon: 'apps', url: '/panel/themes',
    sub_menus: []
  },
  // {
  // 	name: 'Promote', permName: 'order', icon: 'trending-up', url: '/panel/promotion',
  // 	sub_menus: []
  // }
];

export const ALLOWED_CERTIFICATE_TYPE = [
  'image/png',
  'image/PNG',
  'image/jpg',
  'image/JPG',
  'image/jpeg',
  'image/JPEG',
  'application/pdf',
];
export const ALLOWED_LOGO_TYPE = [
  'image/png',
  'image/PNG',
  'image/jpg',
  'image/JPG',
  'image/jpeg',
  'image/JPEG',
];

export const ALLOWED_MENU_IMAGE_TYPE = [
  'image/png',
  'image/PNG',
  'image/jpg',
  'image/JPG',
  'image/jpeg',
  'image/JPEG',
];

export const PROFILE_MENU_LIST = [
  { name: 'Basic Details', isCompleted: false },
  // { name: 'Schedule Audit', isCompleted: false },
  { name: 'Operational timings', isCompleted: false },
  { name: 'GSTIN', isCompleted: false },
  { name: 'FSSAI', isCompleted: false },
  { name: 'Financial Details', isCompleted: false },
  { name: 'User Management', isCompleted: null },
  { name: 'Menu Import', isCompleted: null },
];
export const SETUP_MENU = [
  { name: 'Print' },
  { name: 'Notification' },
];

export const ORDER_STATUS_TYPE = {
  food: [
    { id: 1, name: 'New Orders', type: 'NEW_ORDERS', class: 'new-order', modelKey: 'acceptanceWait' },
    { id: 2, name: 'Preparing', type: 'PREPARING', class: 'preparing', modelKey: 'foodPreparation' },
    { id: 3, name: 'Food Ready', type: 'FOOD_READY', class: 'food-ready', modelKey: 'foodReady' },
    { id: 4, name: 'Completed', type: 'COMPLETED', class: 'completed', modelKey: 'outForDelivery' },
    { id: 5, name: 'Past Orders', type: 'PAST_ORDERS', class: 'past-orders', modelKey: 'pastOrders' },
  ],
  grocery: [
    { id: 1, name: 'New Orders', type: 'NEW_ORDERS', class: 'new-order', modelKey: 'acceptanceWait' },
    { id: 2, name: 'In-Progress', type: 'PREPARING', class: 'preparing', modelKey: 'foodPreparation' },
    { id: 3, name: 'Out For Delivery', type: 'FOOD_READY', class: 'food-ready', modelKey: 'foodReady' },
    { id: 4, name: 'Completed', type: 'COMPLETED', class: 'completed', modelKey: 'outForDelivery' },
    { id: 5, name: 'Past Orders', type: 'PAST_ORDERS', class: 'past-orders', modelKey: 'pastOrders' },
  ]

};
export const ORDER_STATUS_BTN_TEXT = {
  food: {
    'acceptanceWait': 'Accept',
    'foodPreparation': 'Mark Food Ready',
    'foodReady': 'Mark Pickup',
  },
  grocery: {
    'acceptanceWait': 'Accept',
    'foodPreparation': 'Out For Delivery',
    'foodReady': 'Mark Delivered',
  }
}

export const ORDER_STATUS = {
  acceptanceWait: { keys: 'acceptanceWait,valetOnWayToConfirm', sort: 1 },
  foodPreparation: { keys: 'foodPreparation', sort: 1 },
  foodReady: { keys: 'foodReady,waitingDeliveryPickup', sort: 1 },
  outForDelivery: { keys: 'outForDelivery,delivered,complete', sort: -1 },
  pastOrders: { keys: 'outForDelivery,delivered,complete,cancelled,rejected,inDispute,enforcedCancel', sort: -1 }
};
export const STATUS_ERROR = {
  suspend: 'Your outlet has been disabled.',
  closed: 'Your outlet has been marked Closed.',
  inactive: 'Your outlet has been marked Inactive.',
};


export const TOOLTIP_OPTIONS = {
  'placement': 'top',
  'show-delay': 500,
  'theme': 'light',
};
export const PLAN_DETAILS = [
  { 'imgUrl': 'assets/images/package_yumzy_app.png', 'title': 'Yumzy App', 'des': 'Yumzy food ordering app makes your restaurant visible to your potential customers, which in turn will help you receive more orders. Time to build your brand more effectively.' },
  { 'imgUrl': 'assets/images/package_restaurant_pannel.png', 'title': 'Restaurant Panel', 'des': 'Our smartly-designed dashboard helps you process your Yumzy orders, manage inventory & menu, create offers, a lot more...' },
  { 'imgUrl': 'assets/images/package_restaurant_ordering.png', 'title': 'Restaurant ordering', 'des': 'Get the advantage of handling your restaurant orders through your Android mobile phone. Download our Android app and manage without sitting at the same spot.' },
  { 'imgUrl': 'assets/images/package_promotions_offers.png', 'title': 'Offer and Promotion engine', 'des': 'We let you be the caption of your ship, by allowing you have complete control over your restaurant’s promotions & offers. We let you decide the amount of discount you want to offer.' },
  { 'imgUrl': 'assets/images/package_food_business_insights.png', 'title': 'Food business insights', 'des': 'Be a smart restaurateur and customize its menu as per the demand of your customers. Yumzy analyzes your customers’ food preference and based on that we offer you food business insights.' },
  { 'imgUrl': 'assets/images/package_customer.png', 'title': 'Know your customers', 'des': 'Get a better idea of your customers’ likes and dislikes with our CRM tools, and customize your menu in a way that generates more profits.' },
];

export const reportIcon = {
  'Revenue Generated': 'assets/reports/revenuegenerated.svg',
  'Tax Component': 'assets/reports/tax.svg',
  'Avg order Value': 'assets/reports/averageorders.svg',
  'Revenue Loss': 'assets/reports/revenueloss.svg',
  'Total Orders': 'assets/reports/totalrorders.svg',
  'Cancelled Order': 'assets/reports/cancelorders.svg',
  'Food Ready Marked': 'assets/reports/foodready.svg',
  'Completed Orders': 'assets/reports/completeorders.svg',
  'New Customers Acquired': 'assets/reports/newcustomers.svg',
  'Repeated Customer': 'assets/reports/repetedcustomers.svg'

}

export const AUDIT_STATUS = {
  'notScheduled': { status: 'Not Scheduled', textClr: '#1c242a', message: 'Greetings! The audit process for your restaurant hasn’t been scheduled yet. Please choose a date & time. Call us for any assistance.' },
  'scheduled': { status: 'Scheduled', textClr: '#fdb62e', message: 'Please note that the audit for your restaurant has been scheduled on above date. And, make sure your restaurant is open on that day.' },
  'auditRejected': { status: 'Audit Rejected', textClr: '#e62b1e', message: 'We are sorry to inform that your restaurant did not pass our audit process.' },
  'complete': { status: 'Complete', textClr: '#249e00', message: 'Congrats! We are happy to inform that your restaurant has successfully cleared our audit process.' },
  'reScheduled': { status: 'Rescheduled', textClr: '#fdb62e', message: 'The audit process has been rescheduled on above date. Please note, no more rescheduling is allowed. ' }
}

export const promoPlanContent = {
  pro: [
    { name: 'Google my business', text: ' Improve the visibility of your restaurant among your customers by listing your brand on Google My Business. We can help you in using this platform to shout out loud about your offers and exclusive dishes.' },
    { name: 'Social media postings', text: 'Using the offers and promotions generated by you, we create engaging content. Then we will share the same on platforms like Facebook and Instagram to gain more traction' },
    { name: 'Social media ads', text: 'Running the right ads can drive in more customers to your business. We can help you with creating and posting ads that feature your offers and other USPs. Price starts at just 500 INR!' },
    { name: 'Email', text: 'Share with us the list of contacts you have, and we will reach out to them with offers and updates.' },
    { name: ' SMS', text: 'Use our SMS marketing services for deeper penetration into the market. We help you stay connected to your customers by keeping them updated about your current offers/promotions.' },
    { name: 'Targeted marketing or re-marketing', text: ' Remarketing can deliver great results when the database of potential customers generated through it is used properly. Upload the database, and we’ll reach out to the audience via all the above-mentioned marketing channels.' },
    { name: 'Banner (advanced)', text: " Stay ahead in this competitive market by placing your restaurant's banner on our Yumzy app. Let the foodies out there discover your brand first. " },
  ],
  basic: [
    { name: 'Google my business', text: 'Improve the visibility of your restaurant among your customers by listing your brand on Google My Business. We can help you in using this platform to shout out loud about your offers and exclusive dishes.' },
    { name: 'Social media postings', text: 'Using the offers and promotions generated by you, we create engaging content. Then we will share the same on platforms like Facebook and Instagram to gain more traction.' },
    { name: 'Social media ads', text: 'Running the right ads can drive in more customers to your business. We can help you with creating and posting ads that feature your offers and other USPs, with a budget of just 500 INR per package.' },
    { name: 'Email', text: 'Share with us the list of contacts you have, and we will reach out to them with offers and updates. In our basic plan, 500 emails are allowed per month.' },
    { name: 'SMS', text: 'Use our SMS marketing services for deeper penetration into the market. We help you stay connected to your customers by keeping them updated about your current offers/promotions. In our basic plan, 500 SMSes are allowed per month.' },
    { name: 'Targeted marketing or re-marketing', text: 'Remarketing can deliver great results when the database of potential customers generated through it is used properly. Upload the database, and we’ll reach out to the audience via all the above-mentioned marketing channels.' },
    { name: 'Banner (advanced)', text: "Stay ahead in this competitive market by placing your restaurant's banner on our Yumzy app. Let the foodies out there discover your brand first." },
  ]
};
// color code for reports
export const CLASS_COLOR_MAP = {
  'revenue-generated': '#28A745',
  'tax-component': '#663399',
  'revenue-value': '#DC3545',
  'avg-order-value': '#FFC107',
  'completed-order': '#28A745',
  'cancelled-orders': '#DC3545',
  'total-orders': '#663399',
  'food-ready': '#FFC107',
  'customer-reordered': '#663399',
  'new-customer': '#28A745',
};

export const UPLOAD_FILE_BEAN = {
  bytes: null,
  contentType: null,
  documentId: null,
  fileType: null,
  name: null,
  size: null
}

export const ADDRESS_BY_CITY = {
  bounds: null,
  types: ['establishment'],
  componentRestrictions: { country: ['in','us'] },
  fields: ['address_components', 'geometry', 'place_id', 'formatted_address'],
}

export const ALERT_CANCEL_BUTTON_OBJ = {
  text: 'Cancel',
  role: 'cancel',
  cssClass: 'secondary',
  handler: (blah) => { }
}