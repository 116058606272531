import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-slot-card',
	templateUrl: './slot-card.component.html',
	styleUrls: ['./slot-card.component.scss'],
})
export class SlotCardComponent implements OnInit {

	@Input() slotDetails;
	@Input() index;
	@Output() editSlot: EventEmitter<any> = new EventEmitter();
	@Output() deleteSlot: EventEmitter<any> = new EventEmitter();
	@Input() editIndex;
	@Input() componentType;

	formattedSlotDetails = {};
	weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	constructor() {

	}
	ngOnInit() {
		console.log(this.slotDetails);
		let weekdayString = '';
		this.slotDetails.days.forEach((day, index) => {
			if (day) {
				weekdayString += this.weekdays[index] + ',';
			}
		});

		this.formattedSlotDetails['weekdaysString'] = weekdayString.replace(/,\s*$/, '');

		const startDateString = this.processTimeString(this.slotDetails['startTime']);
		const endDateString = this.processTimeString(this.slotDetails['endTime']);

		this.formattedSlotDetails['timeSlot'] = startDateString + '-' + endDateString;
		this.formattedSlotDetails['prepTime'] = this.slotDetails['prepTime'];
		this.formattedSlotDetails['index'] = this.index;
		// this.formattedSlotDetails['index'] = this.slotDetails.days;

	}

	processTimeString(timeString) {
		let startDateHourString = timeString.substring(0, 2);
		const startDateMinString = timeString.substring(2, 4);
		let startDateAmPmString = 'AM';
		if (Number(startDateHourString) === 12) {
			startDateAmPmString = 'PM';
		} else if (Number(startDateHourString) > 12) {
			startDateHourString = Number(startDateHourString) - 12;
			startDateAmPmString = 'PM';
		} else if (Number(startDateHourString) > 24) {
			startDateHourString = Number(startDateHourString) - 24;
			startDateAmPmString = 'AM';
		}
		return (startDateHourString + ':' + startDateMinString + ' ' + startDateAmPmString);
	}
	edit() {
		this.editSlot.emit(this.formattedSlotDetails);
	}

	delete() {
		this.deleteSlot.emit(this.formattedSlotDetails);
	}
}
