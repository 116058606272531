import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AppData, ToastService } from '@shared/index';
import { MenuItemService } from 'src/app/modules/menu-management/services/menu-item.service';
import { CommonService } from '@shared/services/common.service';
import { ThemeService } from '../../service/theme.service';
import { PromoItemsAutocompleteService } from '../../service/promo-items-autocomplete.service';
import { processTimeString } from '@shared/utils/common-functions';
import { pipe, of } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';

@Component({
  selector: 'app-theme-details',
  templateUrl: './theme-details.page.html',
  styleUrls: ['./theme-details.page.scss'],

})
export class ThemeDetailsPage implements OnInit {

  themeData;
  timeSlot = '';
  validationTheme = false;
  buttonTheme = 'Validation';
  checkValue = false;
  showMenuItems = false;
  // outletSelectionForm: FormGroup;
  // outletList = AppData.outletList;
  // menuItems: object;
  // isItemAvailable = false;
  // selectedMenuItems = [];
  // outletChipList = [];
  // menuListCopy = [];
  // menuList = [];
  MenuSelectionsArray = [];
  promoItemsList = [];
  menuItemsList = [];
  showMenuList = false;
  searchTerm: string;
  noMenuItems = false;

  constructor(private navParams: NavParams, private menuService: MenuItemService,
    private renderer: Renderer2, private fb: FormBuilder, public modalController: ModalController,
    private toast: ToastService, public commonService: CommonService, private themeService: ThemeService,
    private autoCompleteService: PromoItemsAutocompleteService) {

    console.log(this.navParams.get('themeData'));
    this.themeData = this.navParams.get('themeData');

    AppData.promoItemsList = [];

    if (this.themeData.landingPage == "dishes" || this.themeData.landingPage == "dish") {
      this.showMenuItems = true;
      const body = {
        "outletId": AppData.outletId,
        "promoId": this.themeData.promoId
      }

      this.themeService.getPromoItemList(body).then((res) => {
        console.log("promo item list===>", res);
        const resData = res['body']['data'];
        resData.forEach(element => {
          AppData.promoItemsList.push(element);
        })

        this.menuItemsList = AppData.promoItemsList;

        if (this.menuItemsList.length == 0) {
          this.noMenuItems = true;
        }
      })

    }

    let startTimeString = processTimeString(this.themeData.startTime.replace(':', ''));
    let endTimeString = processTimeString(this.themeData.endTime.replace(':', ''));

    this.timeSlot = startTimeString + '-' + endTimeString;

    if (this.themeData.isEnrolled === true) {
      this.validationTheme = true;
    } else {
      this.validationTheme = false;
    }
    // this.renderer.listen('window', 'click', (e: Event) => {
    //   console.log(this.isItemAvailable);
    //   this.outletSelectionForm.controls['searchValue'].setValue('');
    //   this.isItemAvailable = false;
    // })

  }

  ngOnInit() {

    // this.initForm();
  }

  // initForm() {
  //   this.outletSelectionForm = this.fb.group({
  //     'outlet': ['-1'],
  //     searchValue: []
  //   });
  // }

  close() {
    this.modalController.dismiss({ isClose: true, data: {} });
  }
  message = '';
  onValidation() {

    if ((this.themeData.landingPage == 'dishes' || this.themeData.landingPage == 'dish')) {
      if (this.MenuSelectionsArray.length > 0) {
        this.onValidationProceed();
      } else {
        this.toast.presentToast(ErrorMessage.SELECT_AT_LEAST_ONE_MENU_ITEM_MSG, 'Error', 'error');
      }
    } else {
      this.onValidationProceed();
    }

  }

  onValidationProceed() {

    let obj = {
      'outletId': AppData.outletId,
      'promoId': this.themeData.promoId
    }

    if (this.message != '') {
      console.log(this.checkValue);
      if (this.checkValue != false) {
        obj['items'] = [];
        this.MenuSelectionsArray.forEach(element => {
          obj['items'].push(element.itemId);
        })
        this.themeService.enrollTheme(obj).then(data => {
          console.log(data);
          this.modalController.dismiss({ isClose: true, data: { isEnrolled: true } });
        })
      } else {
        this.toast.presentToast(ErrorMessage.ACCEPT_VALIDATION_MSG, 'Warning', 'warning');
      }
    } else {
      this.themeService.postThemeValidation(obj).then(data => {
        console.log(data);
        this.buttonTheme = 'Enroll';
        this.message = data['body']['data'];
      })
    }
  }

  // enrollPromotion() {
  //   let obj = {
  //     'outletId': AppData.outletId,
  //     'promoId': this.themeData.promoId
  //   }
  // }

  // addOutlets() {

  //   const index = this.outletSelectionForm.get('outlet').value;
  //   console.log(index);
  //   if (index > -1) {
  //     this.outletAdd(this.outletList[index]);
  //   } else if (this.outletSelectionForm.get('outlet').value === 'all') {
  //     this.outletList.forEach(outlet => {
  //       this.outletAdd(outlet);
  //     })
  //   } else if (index == -1) {
  //     this.toast.presentToast(ErrorMessage.PLEASE_SELECT_OUTLET_MSG, 'Warning', 'warning');
  //   }
  //   // after add addon in chip view list setting value empty
  //   this.outletSelectionForm.controls['outlet'].setValue('-1');
  //   console.log(this.outletChipList);
  // }

  // outletAdd(outlet) {
  //   const found = this.outletChipList.some(item => item.outletId === outlet['outletId']);
  //   console.log(found);
  //   if (!found) {
  //     this.outletChipList.push(outlet);
  //   }
  // }

  // removeOutlets(ind) {
  //   console.log(ind);
  //   this.outletChipList.splice(ind, 1);
  // }


  // async searchMenuItem(data) {

  //   console.log(this.outletChipList);
  //   let mObj = {};
  //   for (const outlet of this.outletChipList) {
  //     let res = await this.menuService.menuListData(outlet.outletId)
  //     mObj = { ...mObj, ...res['body']['data'] }
  //   }
  //   this.menuItems = mObj;

  //   this.menuList = Object.values(this.menuItems);

  //   this.menuListCopy = this.menuList;
  //   const searchStr = data['detail']['value'];
  //   console.log(searchStr);
  //   const tempList = JSON.parse(JSON.stringify(this.menuList));
  //   if (searchStr.length > 0) {
  //     this.menuList = JSON.parse(JSON.stringify(this.menuService.filterMenuList(tempList, 'item_name', searchStr.toLowerCase())));
  //   } else {
  //     this.menuList = [];
  //   }

  //   console.log(this.menuItems);
  //   console.log(this.menuList);
  //   if (searchStr.length > 0) {
  //     this.isItemAvailable = true;
  //   }
  // }

  // menuItemSelect(item) {
  //   console.log(item);
  //   const found = this.selectedMenuItems.some(obj => obj.id === item['id']);
  //   console.log(found);
  //   if (!found) {
  //     this.selectedMenuItems.push(item);
  //   }
  //   this.outletSelectionForm.controls['searchValue'].setValue('');
  //   console.log(this.selectedMenuItems);

  // }

  // removeMenuItem(ind) {
  //   console.log(ind);
  //   this.selectedMenuItems.splice(ind, 1);
  // }

  leaveTheme() {

    let obj = {
      'outletId': AppData.outletId,
      'promoId': this.themeData.promoId
    };
    this.themeService.leaveTheme(obj).then(data => {
      console.log(data);
      this.modalController.dismiss({ isClose: true, data: { isEnrolled: false } });
    })
  }

  remove(id: number, item): void {
    var checkbox = document.getElementById(item.itemId);
    checkbox['checked'] = false;
    this.MenuSelectionsArray.splice(id, 1);
  }

  itemSelected(event, isChecked: boolean) {

    if (!isChecked) {
      if (this.MenuSelectionsArray.includes(event)) {

      } else {
        this.MenuSelectionsArray.push(event);
      }

      if (this.MenuSelectionsArray.length > 0) {
        if (!this.MenuSelectionsArray.includes(event)) {
          this.MenuSelectionsArray.push(event);
        }
      } else {
        this.MenuSelectionsArray.push(event);
      }
    } else {
      var index = this.MenuSelectionsArray.indexOf(event);
      this.MenuSelectionsArray.splice(index, 1);

    }
  }

  searchBarClick() {
    this.showMenuList = true;
  }

  sortMenuList(query) {
    this.searchTerm = query;

    this.menuItemsList = AppData.promoItemsList.
      filter((menuList: any) => menuList.name.toUpperCase().includes(query.toUpperCase()))

      this.menuItemsList.forEach(menuList => {
        setTimeout(async () => {
          var checkbox = await document.getElementById(menuList.itemId);
          if (checkbox) {
            if (this.MenuSelectionsArray.includes(menuList)) {
              checkbox['checked'] = true;
            }
          }
        }, 500)
      })

    if (query == '') {
      this.menuItemsList = AppData.promoItemsList;
    }

  }

}
