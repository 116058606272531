export class CreateCategory {
    private isCategory: boolean;
    private name: string;
    private category: string;
    private subCategory: string;

    constructor() {
        this.isCategory = true;
        this.category = '';
        this.subCategory = '';
        this.name = '';
    }
 
	public get _isCategory(): boolean {
		return this.isCategory;
	}
 
	public get _name(): string {
		return this.name;
	}
 
	public get _category(): string {
		return this.category;
	}
 
	public get _subCategory(): string {
		return this.subCategory;
	}
 
	public set _isCategory(value: boolean) {
		this.isCategory = value;
	}
 
	public set _name(value: string) {
		this.name = value;
	}
 
	public set _category(value: string) {
		this.category = value;
	}
 
	public set _subCategory(value: string) {
		this.subCategory = value;
	}
    
}