import { ApiRequestService } from './../../services/api-request.service';
import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { PopoverController, AlertController } from '@ionic/angular';
import { AppData, ToastService } from '@shared/index';

@Component({
	selector: 'app-profile-pop-over',
	templateUrl: './profile-pop-over.component.html',
	styleUrls: ['./profile-pop-over.component.scss'],
})
export class ProfilePopOverComponent implements OnInit {

	@Input() inputData;

	constructor(private authService: AuthService, private route: Router,
		private popoverController: PopoverController, private toast: ToastService,
		private alertController: AlertController, private apiRequestService: ApiRequestService) { }

	ngOnInit() {
	}

	async logout() {
		this.popoverController.dismiss();
		const alert = await this.alertController.create({
			message: 'Are you sure you want to logout?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'secondary',
					handler: (blah) => { }
				}, {
					text: 'Okay',
					handler: () => {
						const outletIds = AppData.outletList.map(item => { return item.outletId })
						const body = {
							'token': AppData.fcmToken,
							'outletIds': outletIds,
							'action': 'unsubscribe'
						}
						console.log(outletIds);
						if (AppData.fcmToken !== '') {
							this.apiRequestService.post('newOrderSubscription', body);
						}
						this.authService.logout();
					}
				}]
		});
		await alert.present();
	}

	profile() {
		console.log('--->USER TYPE', AppData.userData._userType);
		const userType = AppData.userData._userType;
		if (userType === 'owner' || userType === "support") {
			this.route.navigate(['/panel/restaurant-profile']);
			AppData.selectedIndexSubject.next(-1);
		} else {
			this.toast.presentToast('Access denied', 'Error', 'error');
		}
		this.popoverController.dismiss();

	}

	navigate(url) {
		// this.route.navigate([url]);
		this.popoverController.dismiss({ url });
	}

	onMySubscription() {
		if (AppData.userData._userType === 'owner') {
			this.route.navigate(['/panel/plans', { type: 'subscription' }]);
			AppData.selectedIndexSubject.next(-1);
		} else {
			this.toast.presentToast('Access denied', 'Error', 'error');
		}
		this.popoverController.dismiss();
	}
}
