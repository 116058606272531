export class CreateAddons {
    private addonId: string;
    private addonName: string;
    private description: string;
    private selectType: string;
    private optionName: string;
    private cost: number;
    private isVeg: boolean;
    private isVariant: boolean;
    private maxSelection: number;
    private minSelection: number;
    private options: Array<AddOptions>;
    private displayName:string;

    constructor() {
        this.addonId = '';
        this.addonName = '';
        this.description = '';
        this.selectType = 'radio';
        this.optionName = '';
        this.cost = null;
        this.isVeg = false;
        this.isVariant = false;
        this.maxSelection = 0;
        this.minSelection = 0;
        this.options = [];
        this.displayName = '';
    }

    public get _addonId(): string {
        return this.addonId;
    }

    public get _addonName(): string {
        return this.addonName;
    }

    public get _description(): string {
        return this.description;
    }

    public get _selectType(): string {
        return this.selectType;
    }

    public get _optionName(): string {
        return this.optionName;
    }

    public get _cost(): number {
        return this.cost;
    }

    public get _isVeg(): boolean {
        return this.isVeg;
    }

    public get _isVariant(): boolean {
        return this.isVariant;
    }

    public get _maxSelection(): number {
        return this.maxSelection;
    }

    public get _minSelection(): number {
        return this.minSelection;
    }
    public get _displayName(): string {
        return this.displayName;
    }

    public set _addonId(value: string) {
        this.addonId = value;
    }

    public set _addonName(value: string) {
        this.addonName = value;
    }

    public set _description(value: string) {
        this.description = value;
    }

    public set _selectType(value: string) {
        this.selectType = value;
    }
    public set _displayName(value: string) {
        this.displayName = value;
    }

    public set _optionName(value: string) {
        this.optionName = value;
    }

    public set _cost(value: number) {
        this.cost = value;
    }

    public set _isVeg(value: boolean) {
        this.isVeg = value;
    }

    public set _isVariant(value: boolean) {
        this.isVariant = value;
    }

    public set _maxSelection(value: number) {
        this.maxSelection = value;
    }

    public set _minSelection(value: number) {
        this.minSelection = value;
    }

    get _options(): Array<AddOptions> {
        return this.options;
    }

    set _multipleAddonsVariant(value: Array<AddOptions>) {
        this.options = value;
    }


}

export class AddOptions {
    private optionName: string;
    private cost: number;
    private isVeg: boolean;

    constructor() {
        this.optionName = '';
        this.cost = null;
        this.isVeg = false;
    }
    public get _optionName(): string {
        return this.optionName;
    }

    public get _cost(): number {
        return this.cost;
    }

    public get _isVeg(): boolean {
        return this.isVeg;
    }
    public set _optionName(value: string) {
        this.optionName = value;
    }

    public set _cost(value: number) {
        this.cost = value;
    }

    public set _isVeg(value: boolean) {
        this.isVeg = value;
    }
}