import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AppData, ToastService } from '@shared/index';
import { MenuItemService } from 'src/app/modules/menu-management/services/menu-item.service';
import { MenuItemCreationService } from '../menu-item-creation/service/menu-item-creation.service';
import { Subscription } from 'rxjs';
import { IonContent, ModalController } from '@ionic/angular';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { LoaderService } from '@shared/services/loader.service';
import { AddonsItemService } from 'src/app/modules/menu-management/services/addons-item.service';
import { PERCENTAGE } from 'src/app/core/constants/app-constants';

@Component({
  selector: 'app-promoted-menu-items',
  templateUrl: './promoted-menu-items.page.html',
  styleUrls: ['./promoted-menu-items.page.scss'],
})
export class PromotedMenuItemsPage implements OnInit, OnDestroy {
  outletIdSubscription = new Subscription();
  itemDataSubscription = new Subscription();

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(VirtualScrollerComponent) virtualScroller: VirtualScrollerComponent;
  menuObj = {};
  menuObjCopy = {};
  menuListDisplay = [];
  menuListDisplayCopy = [];
  categoryDisplayList = [];
  categoryDisplayListCopy = [];
  percentage = PERCENTAGE;
  // item = 'NEW_MENU_CREATION';
  outletId = '';
  addonsList = [];
  noData = false;

  constructor(
    private menuService: MenuItemService,
    private menuItemService: MenuItemCreationService,
    private loaderService: LoaderService,
    private addonService: AddonsItemService,
    public modalController: ModalController
  ) { }

  // added for rebase
  ngOnInit() {
    this.outletIdSubscription = AppData.outletIdSubject.subscribe((outletId) => {
      this.outletId = outletId;
      this.getAddonsOrVariantData();
      this.getMenuList();
    });

    this.itemDataSubscription = this.menuItemService.itemDataSubject.subscribe((itemData) => {
      if (itemData.length > 0 && this.virtualScroller) {
        this.virtualScroller.scrollToPosition(0);
      }
    });
  }

  search(data) {
    const searchStr = data['detail']['value'];
    const tempList1 = JSON.parse(JSON.stringify(Object.values(this.menuObj)));
    if (searchStr.length > 0) {
      console.log('Assigning the MenuList');
      const Jimit = JSON.parse(JSON.stringify(this.menuService.filterMenuList(tempList1, 'item_name', searchStr.toLowerCase())));
      const { tempList, tempCatList } = this.menuService.transformList(Jimit);
      this.menuListDisplay = tempList;
      this.categoryDisplayList = tempCatList;
    } else {
      this.menuListDisplay = JSON.parse(JSON.stringify(this.menuListDisplayCopy));
      this.categoryDisplayList = JSON.parse(JSON.stringify(this.categoryDisplayListCopy));
    }
  }

  getAddonsOrVariantData() {
    this.addonService.getAddonsOrVariants(AppData.outletId).then((response) => {
      if (Object.keys(response['body']['data']).length) {
        this.addonsList = response['body']['data']['addons'];
      } else {
        this.addonsList = [];
      }
    });
  }

  async getMenuList() {
    await this.loaderService.showLoader();
    this.menuService.getPromotedMenuItems(this.outletId).then(async (res) => {
      this.menuObj = res['body']['data'];
      this.menuObjCopy = JSON.parse(JSON.stringify(this.menuObj));
      const { tempList, tempCatList } = this.menuService.transformList(Object.values(this.menuObj));
      this.menuListDisplay = tempList;
      this.menuListDisplayCopy = JSON.parse(JSON.stringify(this.menuListDisplay));
      if (this.menuListDisplay.length == 0) {
        this.noData = true;
      }
      this.categoryDisplayList = tempCatList;
      this.categoryDisplayListCopy = JSON.parse(JSON.stringify(this.categoryDisplayList));

      await this.loaderService.hideLoader();
    }, (async error => {
      await this.loaderService.hideLoader();
    }));
  }

  removeMenuItem(menuId) {
    for (var i = 0; i < this.menuListDisplay.length; i++) {
      if (this.menuListDisplay[i].id === menuId) {
        this.menuListDisplay.splice(i, 1);
        this.menuListDisplayCopy = JSON.parse(JSON.stringify(this.menuListDisplay));

        break;
      }
    }
  }

  close() {
    this.modalController.dismiss({ isClose: true, data: {} });
  }

  ngOnDestroy() {
    this.outletIdSubscription.unsubscribe();
    this.itemDataSubscription.unsubscribe();
  }

}
