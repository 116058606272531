import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-circular-progressbar',
	templateUrl: './circular-progressbar.component.html',
	styleUrls: ['./circular-progressbar.component.scss'],
})
export class CircularProgressbarComponent {
	public circumference: number = 2 * Math.PI * 47;
	public strokeDashoffset = 0;
	public color = '#0000ff';
	@Input() maxValue = 0;
	@Input() currValue = 0;
	@Input() public progressBarColor: string;

	isCompleted: boolean;

	// ngOnChanges(changes: SimpleChanges) {
	//   if (changes['currValue']) {
	//     const currVal = changes['currValue'].currentValue * 100 / changes['maxValue'].currentValue;
	//     this.onPercentageChanged(currVal)
	//   }
	// }
	ngOnChanges() {
		if (this.currValue) {
			const currVal = this.currValue * 100 / this.maxValue;
			this.onPercentageChanged(currVal);
		}
		if (this.currValue === this.maxValue) {
			this.isCompleted = true;
		} else {
			this.isCompleted = false;
		}
	}
	onPercentageChanged(val: number) {
		const offset = this.circumference - val / 100 * this.circumference;
		this.strokeDashoffset = offset;
	}
}
