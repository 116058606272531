import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OutletsPage } from '../components/outlets/outlets.page';

@Injectable({
	providedIn: 'root'
})
export class OutletsModalService {
	constructor(private modalController: ModalController) { }

	async presentOutletsModal(inputData) {
		const modal = await this.modalController.create({
			component: OutletsPage,
			cssClass: 'outlets-modal-css',
			backdropDismiss: true,
			componentProps: {
				'inputData': inputData
			}
		});
		await modal.present();
		const { data } = await modal.onDidDismiss();
	}

	// // for open outlet list view modal
	async changeOutlets(inputData) {
		const modal = await this.modalController.create({
			component: OutletsPage,
			cssClass: 'profile-outlet-modal',
			backdropDismiss: true,
			componentProps: {
				'inputData': inputData
			}
		});
		await modal.present();
		const { data } = await modal.onDidDismiss();
	}
}
