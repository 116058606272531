import { Injectable } from '@angular/core';
import { ApiRequestService, AppData } from '@shared/index';

@Injectable({
    providedIn: 'root'
})
export class PlanService {
    constructor(private apiService: ApiRequestService) {
    }

    getPlansList() {
        return this.apiService.get('getPlansList');
    }

    createPlanPayment(paymentDetails) {
        return this.apiService.post('createPlanPayment', paymentDetails);
    }

    checkPaymentStatus() {
        return this.apiService.get('checkPaymentStatus')
    }
}