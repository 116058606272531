import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
	CanLoad,
	Route,
	CanActivateChild
} from '@angular/router';
import { AppData } from '@shared/services/app-data.service';
import { MENUS } from 'src/app/core/constants/app-constants';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		console.log(state);
		if (state.url === '/' && !AppData.isAuthenticated) {
			this.router.navigate(['/login']);
			return false;
		}
		MENUS.forEach((menu, index) => {
			if (state.url.indexOf(menu.url) >= 0) {

				// if (menu.url === state.url) {
				AppData.selectedIndexSubject.next(index);
				// }
      }

		});
		return true;
	}
	constructor(public router: Router) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
try{
		console.log(state);
		if (state.url === '/' && !AppData.isAuthenticated) {
			this.router.navigate(['/login']);
			return false;
		}
		MENUS.forEach((menu, index) => {
			if (state.url.indexOf(menu.url) >= 0) {

				// if (menu.url === state.url) {
				AppData.selectedIndexSubject.next(index);
				// }
			}

		});
    return true;
  } catch(error) {
    console.log(error);
  }
	}

	canLoad(route: Route) {
		console.log('route can load');
		console.log(AppData.isAuthenticated);
		if (AppData.isAuthenticated) {
			return true;
		}
		this.router.navigate(['login']);
		return false;
	}
}
