import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SubMenuComponent } from './components/sub-menu/sub-menu.component';
import { ClockComponent } from '../modules/profile/components/operational-time/clock/clock.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FilterPipe } from './utils/filter.pip';
import { CircularProgressbarComponent } from './components/circular-progressbar/circular-progressbar.component';
import { NumberDirective } from './utils/numbers-only.directive';
import { SlotCardComponent } from '../modules/profile/components/operational-time/slot-card/slot-card.component';
import { MenuSelectionsPage } from './modals/components/menu-selections/menu-selections.page';
import { HighlightSearchPipe } from './utils/highlight-search.pipe';
import { MenuDetailsComponent } from '../modules/menu-management/components/menu-details/menu-details.component';

@NgModule({
	declarations: [
		SubMenuComponent,
		ClockComponent,
		FilterPipe,
		CircularProgressbarComponent,
		NumberDirective,
		SlotCardComponent,
		MenuSelectionsPage,
		HighlightSearchPipe,
		MenuDetailsComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		ReactiveFormsModule,
		FormsModule

	],
	exports: [
		SubMenuComponent,
		ClockComponent,
		FilterPipe,
		CircularProgressbarComponent,
		NumberDirective,
		SlotCardComponent,
		MenuSelectionsPage,
		HighlightSearchPipe,
		MenuDetailsComponent
	]
})
export class SharedModule { }
