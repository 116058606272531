import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ToastService } from '@shared/index';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';

@Component({
  selector: 'app-order-filter-modal',
  templateUrl: './order-filter-modal.page.html',
  styleUrls: ['./order-filter-modal.page.scss'],
})
export class OrderFilterModalPage implements OnInit {
  orderFilterForm: FormGroup;
  modalObj;
  outletList = [];
  // newOutletList = [];
  formObj;
  selectAll = false;

  constructor(private modalController: ModalController,
    navParams: NavParams,
    private fb: FormBuilder,
    private toast: ToastService) {
    // Init the parameters and fetching the values passed while calling the modal services
    this.modalObj = navParams.get('modalObj');
    this.outletList = this.modalObj['outletList'];

    console.log('--->', this.outletList);
  }

  ngOnInit() {
    // this.newOutletList = this.modalObj['filterOutlets'];
    if (this.modalObj['obj']) {
      this.formObj = this.modalObj['obj'];
    }
    this.initForm();
    this.setFormValue();
  }

  initForm() {
    const groupControls = this.outletList.map(item => new FormControl(false));
    this.orderFilterForm = this.fb.group({
      'selectedItems': new FormArray(groupControls),
      'selectAll': [this.selectAll]
    });
    this.orderFilterForm.valueChanges.subscribe((formData) => {
      this.checkEveryOutlet(formData.selectedItems);
    });
  }

  setFormValue() {
    if (this.formObj === undefined) {
      const newItem = this.orderFilterForm.controls['selectedItems'].value;
      console.log('-->newItem', newItem);
      newItem.forEach((item, index) => {
        newItem[index] = true;
      });

      this.orderFilterForm.controls['selectedItems'].setValue(newItem);
    } else {
      this.orderFilterForm.controls['selectAll'].setValue(this.formObj['selectAll']);
      if (this.formObj['selectedItems'].length) {
        this.orderFilterForm.controls['selectedItems'].setValue(this.formObj['selectedItems']);
      }
    }
  }

  everyOutletClicked(ev) {
    console.log(this.orderFilterForm.controls);
    this.selectAll = !this.selectAll;
    this.orderFilterForm.controls['selectedItems']['controls'].forEach((formControl, index) => {
      this.orderFilterForm.controls['selectedItems']['controls'][index].setValue(this.selectAll,
        { emitEvent: false });
    });
  }

  checkEveryOutlet(outlet) {
    if (outlet.indexOf(null) > -1 || outlet.indexOf(false) > -1) {
      this.orderFilterForm.controls['selectAll'].setValue(false, { emitEvent: false });
      this.selectAll = false;
    } else {
      this.orderFilterForm.controls['selectAll'].setValue(true, { emitEvent: false });
      this.selectAll = true;
    }
  }

  onFilterOrder() {
    const newOutletList = [];
    const selectedItemList = this.orderFilterForm.value['selectedItems'];
    selectedItemList.forEach((items, index) => {
      if (items === true) {
        newOutletList.push({
          outletId: this.outletList[index].outletId
        });
      }
    });

    console.log('-->', newOutletList);
    const selectItemAry = this.orderFilterForm.controls['selectedItems'].value;
    if (selectItemAry.indexOf(true) > -1) {
      this.modalController.dismiss({
        isClose: true,
        data: {
          'obj': this.orderFilterForm.value,
          'outletItems': newOutletList,
          'isCloseModal': true
        }
      });
    } else {
      this.toast.presentToast(ErrorMessage.SELECT_AT_LEAST_ONE_OUTLET_MSG, 'warning', 'warning');
    }


  }

  close() {
    this.modalController.dismiss({
      isClose: true,
      data: { 'isCloseModal': false }
    });
  }
}
