import { getInputFieldValidation } from '@shared/utils/common-functions';
import { DEVICE_ID, NEW_COUNTRY_CODES } from './../../../../core/constants/app-constants';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiRequestService } from '@shared/index';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { COUNTER, SECONDS } from 'src/app/core/constants/app-constants';
import { URL_KEYS } from '@shared/services/request-url.service';
import { CommonService } from '@shared/services/common.service';

@Component({
	selector: 'app-otp-password',
	templateUrl: './otp-password.page.html',
	styleUrls: ['./otp-password.page.scss'],
})
export class OtpPasswordPage implements OnInit {
	// Forms
	otpForm: FormGroup;
	passwordForm: FormGroup;
	mobileForm: FormGroup;

	// boolean variables for templates
	passwordRequired = false;
	otpsubmitted = false;
	passwordMatched = false;
	isForgotPassword = false;
	showMobile = false;
	showOTP = false;

	// Modal Header Variables
	otpHeader = 'OTP Verification';
	passwordHeader = 'Set Password';
	forgetPasswordHeader = 'Forgot Password';
	header = '';

	// Variable which will be pass to call complete Signup API
	dataTobeSentBack = {};
	inputData = {};

	// Timer Variables
	counter = COUNTER;
	interval: any;
	showResend = false;
	seconds = SECONDS;
	mobileNumber: string;
	countryCodes: any = NEW_COUNTRY_CODES;
	selectedValue: any;

	constructor(
		private modalController: ModalController,
		private apiService: ApiRequestService,
		public navParams: NavParams,
		private fb: FormBuilder) {

		// Init the parameters and fetching the values passed while calling the modal services
		this.inputData = navParams.get('inputData');
		console.log(this.inputData);

		if (this.inputData['displayType'] === 'newUser') {
			this.header = this.otpHeader;
			this.dataTobeSentBack = Object.assign({}, this.inputData);
			this.dataTobeSentBack['mobile'] = this.inputData['mobile'];
			this.dataTobeSentBack['email'] = this.inputData['email'];
			this.isForgotPassword = navParams.get('email');
			// this.showMobile = false;
			this.showOTP = true;
		} else {
			this.header = this.forgetPasswordHeader;
			this.showMobile = true;
		}

	}

	ngOnInit() {
		this.selectedValue = this.countryCodes[0].code
		//  Forms Init
		this.otpForm = this.fb.group({
			otp: ['', [Validators.required, Validators.pattern(/^[0-9]\d{3}$/g), Validators.maxLength(4), Validators.minLength(4)]],
			selectedValue: ['+91']
		});

		this.passwordForm = this.fb.group({
			password: ['', [Validators.required, Validators.minLength(6)]],
			confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
		});

		this.mobileForm = this.fb.group({
			mobile: ['', [Validators.required, Validators.pattern('^[0-9]{10}')]],
			selectedValue: ['+91']
		});

		//  checking the password matching
		this.passwordForm.valueChanges.subscribe((data) => {
			this.checkPassword(data);
		});

		//  Starting the countdown for resend OTP.
		this.startTimer();
	}

	// password match conditions
	checkPassword(data: any) {
		if (data['password'] === data['confirmPassword']) {
			this.passwordMatched = true;
			this.dataTobeSentBack['password'] = data['password'];
		} else {
			this.passwordMatched = false;
		}
	}

	createObjForNewUserToVerifyOTP() {
		const body = {
			'mobile': this.dataTobeSentBack['mobile'],
			'otp': this.otpForm.getRawValue()['otp'],
			'deviceId': DEVICE_ID,
			'countryCode': this.selectedValue
		}
		return body;
	}

	createObjForOldUserToVerifyOTP() {
		const body = {
			'userId': this.mobileForm.getRawValue()['mobile'],
			'otp': this.otpForm.getRawValue()['otp'],
			'countryCode': this.selectedValue
		}
		return body;
	}

	//  Verifying the OTP
	async verify() {
		if (this.inputData['displayType'] === 'newUser') {
			const reqBody = await this.createObjForNewUserToVerifyOTP();
			this.onVerifyNewUser(reqBody);
		} else {
			const reqBody = this.createObjForOldUserToVerifyOTP();
			this.toForgetPassword(reqBody);
		}
	}

	toForgetPassword(reqBody) {
		this.apiService.post('forgetPassword', reqBody).then((response) => {
			this.passwordRequired = true;
			this.showOTP = false;
		});
	}
	onVerifyNewUser(reqBody) {
		let callingUrl: URL_KEYS;
		if (this.inputData['subuserType'] === 'sub') {
			callingUrl = 'subinitSignup';
		} else {
			callingUrl = 'initSignup';
		}
		this.apiService.post(callingUrl, reqBody).then((response) => {
			this.dataTobeSentBack['userId'] = response['body']['data']['userId'] || '';
			this.dataTobeSentBack['registered'] = response['body']['data']['registered'];
			this.dataTobeSentBack['setPassword'] = response['body']['data']['setPassword'];
			this.dataTobeSentBack['userType'] = callingUrl === 'initSignup' ? 'owner' : 'employee';

			if (this.dataTobeSentBack['setPassword']) {
				this.otpsubmitted = true;
				this.header = this.passwordHeader;
				this.passwordRequired = true;
			} else {
				this.modalController.dismiss({ isclose: false, data: this.dataTobeSentBack });
			}

		});
	}

	// Submitting the for the complete signup
	submit() {
		if (this.inputData['displayType'] === 'newUser') {
			if (!this.dataTobeSentBack['setPassowrd']) {
				this.dataTobeSentBack['deviceId'] = 'web';
				this.modalController.dismiss({ isclose: false, data: this.dataTobeSentBack });
			} else {
				this.modalController.dismiss({ isclose: false, data: this.dataTobeSentBack });
			}
		} else {
			const data = {
				password: this.passwordForm.getRawValue()['password']
			};
			this.apiService.post('resetPassword', data).then((response) => {
				this.modalController.dismiss({ isclose: false, data: this.dataTobeSentBack });
			});
		}
	}


	//  close the modal no Further actions requires
	close() {
		this.modalController.dismiss({ isclose: true, data: null });
	}

	wrongNumber() {
		this.modalController.dismiss({ isclose: true, data: { isMobileWrong: true } });
	}

	createObjForSendOTP() {
		const body = {
			'mobile': this.mobileForm.getRawValue()['mobile'],
			'countryCode': this.selectedValue
		}
		return body;
	}

	async resend() {
		const reqBody = await this.createObjForSendOTP();
		reqBody['method'] = 'resendOtp';
		this.apiService.post('sendOtp', reqBody).then((response) => {
			this.seconds = 30;
			if (this.counter >= 0) {
				this.startTimer();
				this.counter--;
			}
		});
	}

	// Timer function for resend the OTP
	startTimer() {
		this.interval = setInterval(() => {
			if (this.seconds > 1) {
				this.showResend = false;
				this.seconds--;
			} else {
				this.showResend = true;
				clearInterval(this.interval);
				if (this.counter === 0) {
					this.showResend = false;
					this.counter = -1;
				}
			}
		}, 1000);
	}

	async getOTP() {
		const reqBody = this.createObjForSendOTP();
		this.mobileNumber =reqBody.countryCode+" "+ reqBody.mobile;
		console.log(this.mobileNumber);
		reqBody['method'] = 'sendOtp';
		this.apiService.post('sendOtp', reqBody).then((response) => {
			this.showOTP = true;
			this.showMobile = false;
		});
	}

	// for checking valid fields
	getValid(fieldName) {
		return getInputFieldValidation(this.mobileForm, fieldName);
	}

	selectedCountry(value: any) {
		this.selectedValue = value;
		console.log(this.selectedValue);
	}
}
