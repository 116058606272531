import { CommonService } from '@shared/services/common.service';
import { Injectable } from '@angular/core';
import { ApiRequestService, AppData } from '@shared/index';
import { filterDataList } from '@shared/utils/common-functions';

@Injectable({
  providedIn: 'root'
})
export class MenuItemService {
  constructor(private apiService: ApiRequestService, private commonService: CommonService) { }
  filterMenuList(menuList, fieldName, searchStr) {
    const filterList = [];
    menuList.forEach((menu, index) => {
      console.log('Processing Data: Index: ', index, ' Data : ', menu);
      let temp2 = this.filterCategory(menu['sub_cat'], fieldName, searchStr);
      if (temp2.length > 0) {
        menu['sub_cat'] = temp2;
        filterList.push(menu);
      }

      temp2 = filterDataList(menu['menu_items'], fieldName, searchStr);
      console.log('temp:', temp2);
      if (temp2.length > 0) {
        menu['menu_items'] = temp2;
        filterList.push(menu);
      }
    });

    return filterList;
  }

  filterCategory(sub_cats, fieldName, searchStr) {
    console.log('Processing Categories : ', sub_cats);
    const retList = [];
    sub_cats.forEach((sub_cat, index) => {
      const temp = filterDataList(sub_cat['menu_items'], fieldName, searchStr);
      if (temp.length > 0) {
        sub_cats[index]['menu_items'] = temp;
        retList.push(sub_cats[index]);
      }
    });
    return retList;
  }

  // for menu outlet all data get
  menuListData(outletId) {
    return this.apiService.get('menuItemsList', '?outletId=' + outletId);
  }

  getPromotedMenuItems(outletId) {
    return this.apiService.get('menuItemsList', `?outletId=${outletId}&type=promotion`);
  }

  menuItemById(obj) {
    return this.apiService.get('menuItemById', '?itemId=' + obj.itemId + '&outletId=' + obj.outletId);
  }
  menuItemDelete(itemId) {
    return this.apiService.get('menuItemDelete', '?itemId=' + itemId + '&outletId=' + AppData.outletId);
  }

  // category delete
  deleteCategory(obj) {
    return this.apiService.get('deleteCategory', '?outletId=' + obj.outletId + '&category=' + obj.cat);
  }

  deleteSubcategory(obj) {
    return this.apiService.get('deleteSubcategory', '?outletId=' + obj.outletId + '&category=' + obj.cat + '&subCategory=' + obj.subcat);
  }

  addMenuSlot(reqData) {
    console.log(reqData);
    return this.apiService.post('addMenuSlot', reqData);
  }

  updateMenuSlot(reqData) {
    return this.apiService.put('addMenuSlot', reqData);
  }

  getMenuSlotDetails(outletId) {
    return this.apiService.get('getMenuSlotDetails', '?outletId=' + outletId);
  }

  transformList(categoryList) {
    const tempList = [];
    const tempCatList = [];
    const returnObj = {
      tempList, tempCatList
    };
    // Processing the Category
    categoryList.forEach((categoty) => {
      // processing Menu Items
      const catDetails = {
        cat_name: '',
        cat_id: '',
        sub_cat: []
      };
      let canBePushed = false;
      catDetails.cat_name = categoty.cat_name;
      catDetails.cat_id = categoty.cat_id;

      categoty.menu_items.forEach((menu, index) => {
        const tempMenu = JSON.parse(JSON.stringify(menu));
        tempMenu.cat_id = categoty.cat_id;
        tempMenu.cat_name = categoty.cat_name;
        index === 0 ? tempMenu.showCat = true : tempMenu.showCat = false;
        tempList.push(tempMenu);
        canBePushed = true;
      });

      // processing Sub Categories
      categoty.sub_cat.forEach((subCategory, catIndex) => {
        const subCat = {
          sub_cat_name: subCategory.sub_cat_name,
          sub_cat_id: subCategory.sub_cat_id
        };
        catDetails.sub_cat.push(subCat);
        // processing Menu Items
        subCategory.menu_items.forEach((menu, index) => {
          const tempMenu = JSON.parse(JSON.stringify(menu));
          tempMenu.cat_id = categoty.cat_id;
          tempMenu.cat_name = categoty.cat_name;
          tempMenu.sub_cat_name = subCategory.sub_cat_name;
          tempMenu.sub_cat_id = subCategory.sub_cat_id;
          catIndex === 0 && index === 0 ? tempMenu.showCat = true : tempMenu.showCat = false;
          index === 0 ? tempMenu.showSubCat = true : tempMenu.showSubCat = false;
          tempList.push(tempMenu);
          canBePushed = true;
        });
      });
      canBePushed ? tempCatList.push(catDetails) : canBePushed = false;
    });

    console.log('Temp List :::', tempList);
    console.log('Temp Cat List :::', tempCatList);
    returnObj.tempList = tempList;
    returnObj.tempCatList = tempCatList;
    return returnObj;
  }
}
