import { STATUS_ERROR } from './../../../../core/constants/app-constants';
import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { AppData } from '@shared/index';
import { CommonService } from '@shared/services/common.service';
import { ProfileService } from 'src/app/modules/profile/service/profile.service';

@Component({
	selector: 'app-outlets',
	templateUrl: './outlets.page.html',
	styleUrls: ['./outlets.page.scss'],
})
export class OutletsPage implements OnInit {
	isSearch = true;
	outletsList = [];
	searchOutlets: string;
	inputData = {};
	isViewAndActive: boolean[] = [true];
	isViewType: string;
	buttonName = 'View';
	callFrom: string;
	isResponse = false;
	outletId: string;
	copyOutletList = [];
	filterOutletList = [];
	searchKey: string = '';
	constructor(navParams: NavParams, private modalController: ModalController, private profileService: ProfileService,
		private commonService: CommonService) {
		// Init the parameters and fetching the values passed while calling the modal services
		this.inputData = navParams.get('inputData');

		AppData.outletIdSubject.subscribe((outletId) => {
			this.outletId = outletId;
			console.log('Outlet id in offers page', this.outletId);
		});
	}

	ngOnInit() {
		this.callFrom = this.inputData['callFrom'];
		this.outletsList = this.inputData['outletList'];
		this.copyOutletList = JSON.parse(JSON.stringify(this.outletsList));

		// for getting modal display type
		this.displayType();
	}

	// for getting modal display type
	displayType() {
		if (this.inputData['displayType'] === 'SUB_HEADER') {
			this.isViewType = 'SUB_HEADER';
		} else {
			this.isViewType = 'MAIN_HEADER';
		}
	}

	statusMsg(status) {
		return STATUS_ERROR[status];
	}

	toggle(outlets, ind) {
		console.log(outlets);
		AppData.outletIdSubject.next(outlets.outletId);
		AppData.outletNameSubject$.next(outlets.displayName);
		AppData.selectedOutLet = ind;
		this.modalController.dismiss({ isclose: false });
		AppData.profileMenuSelectedSubject.next(0);
	}

	isActive(outletId) {
		let buttonCheck = false;
		if (outletId === this.outletId) {
			this.buttonName = 'Active';
			buttonCheck = true;
		} else {
			this.buttonName = 'View';
			buttonCheck = false;
		}
		return buttonCheck;
	}

	// Search Outlets
	onSearch() {
		this.isSearch = false;
	}

	change(outlets) {
		this.isResponse = true;
		const reqData = {
			'outletId': outlets['outletId'],
			'available': outlets['available'] === 'on' ? 'off' : 'on'
		};

		this.profileService.setOnOff(reqData).then((response) => {
			this.outletsList.forEach((obj) => {
				if (obj.outletId === outlets.outletId) {
					obj['available'] = obj['available'] === 'on' ? 'off' : 'on';
				}
			});
			AppData.outletListSubject.next({
				changeIndex: false,
				outletList: this.outletsList
			});
			this.copyOutletList = JSON.parse(JSON.stringify(this.outletsList));
			this.filterList(this.searchKey);
			this.isResponse = false;
		}).catch(error => {
			this.isResponse = false;
		});
	}

	// for disabled out let while inactive status mode
	isDisabled(status) {
		if (this.isViewType === 'MAIN_HEADER' || this.inputData['callFrom'] === 'COMING_FROM_MENU') {
			if (status === 'inactive' || AppData.userData['permissions']['outletOn'] === false) {
				return true;
			} else {
				return false;
			}
		}

	}

	onOutletSearch(val) {
		const searchVal = val['detail']['value'];
		this.searchKey = searchVal;
		this.filterList(searchVal);
	}

	filterList(val) {
		if (val !== '') {
			this.filterOutletList = this.copyOutletList.filter(item => {
				return item['displayName'].toLowerCase().indexOf(val) >= 0;
			});
			this.copyOutletList = JSON.parse(JSON.stringify(this.filterOutletList));
		} else {
			this.copyOutletList = JSON.parse(JSON.stringify(this.outletsList));
		}
	}
}
