import { Injectable } from '@angular/core';
import { ApiRequestService, AppData } from '@shared/index';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    constructor(private apiService: ApiRequestService) {
        console.log('promotion service');

    }

    getThemeList(outletId) {
        return this.apiService.get('promotionList', outletId);
    }

    postThemeValidation(obj) {
        return this.apiService.post('themeValidation', obj);
    }

    enrollTheme(obj) {
        return this.apiService.post('themeEnroll', obj);
    }

    leaveTheme(obj) {
        return this.apiService.post('themeLeave', obj);
    }

    getPromoItemList(obj) {
        return this.apiService.post('promoItemList', obj);
    }
}