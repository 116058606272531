import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiRequestService, AppData } from '@shared/index';

@Injectable({
	providedIn: 'root'
})
export class AddonsItemService {
	addonsDataSubject = new BehaviorSubject([]);
	// addonsList = [];
	constructor(private apiService: ApiRequestService) {
	}

	// for create addons or variants
	createAddonsOrVariant(addonsData) {
		return this.apiService.post('createAddons', addonsData);
	}

	// for getting addons or variants
	getAddonsOrVariants(outletId) {
		return this.apiService.get('getAddons', '?outletId=' + outletId);
	}

	deleteAddons(addonId) {
		return this.apiService.get('deleteAddon', '?outletId=' + AppData.outletId + '&addonId=' + addonId);
	}



}
