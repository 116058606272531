import { Injectable } from '@angular/core';
import { ApiRequestService} from '@shared/index';
import { AppData } from '@shared/index';

@Injectable({
	providedIn: 'root'
})
export class MenuService {
	constructor(private apiService: ApiRequestService) {
	
		console.log("=======>Menu Mapping Service");

	}

	// for menu items mapping
	menuItemMapping(obj){
		return this.apiService.post('addMappingItem', obj);
	}
}