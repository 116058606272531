import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { UserModel } from '@shared/models/user.model';

@Injectable({
	providedIn: 'root'
})
export class AppData {
	// static username = new BehaviorSubject(null);
	static token = new BehaviorSubject(null);
	static baseUrl = environment.baseUrl;
	static socketUrl = environment.socketUrl;
	static googleMapUrl = environment.googleMapUrl;
	static supportUrl = environment.supportUrl;
	static fssaiBaseUrl = environment.fssaiBaseUrl;
	static gstInCheckUrl = environment.gstInCheckUrl;
	static ifscNumberUrl = environment.ifscNumberUrl;
	static termsConditionsUrl = environment.termsConditionsUrl;
	static notificationSound = environment.notificationSound;
	static isAuthenticated = false;
	static userDataSubject = new BehaviorSubject(null);
	static userData: UserModel;
	static restData = new BehaviorSubject(null);
	static outletList = [];
	static outletListSubject = new BehaviorSubject(null);
	static selectedOutLet = 0;
	static outletId = '';
	static outletIdSubject = new BehaviorSubject('');
	static userList = [];
	static userListSubject = new BehaviorSubject([]);
	static selectedIndex = -1;
	static profileMenuSelectedIndex = -1;
	static profileMenuSelectedSubject = new BehaviorSubject(-1);
	static selectedIndexSubject = new BehaviorSubject(-1);
	static outletDetails = new BehaviorSubject(null);
	static promoItemsList = [];
	static outletNameSubject$ = new BehaviorSubject(null);

	static fcmTokenSub$ = new BehaviorSubject('');
	static fcmToken = '';

	// below will be used for checking if we have to change pointer of side menu
	static appPermissionCheck = new BehaviorSubject('init');
	constructor(private storage: StorageService) {
		console.log('app data service initialized');
		console.log(environment);
		if (this.storage.getItem('token') !== null) {
			AppData.token.next(this.storage.getItem('token'));
			AppData.isAuthenticated = true;
		}

		if (this.storage.getItem('userData') !== null) {
			AppData.userDataSubject.next(this.storage.getItem('userData'));
		}

		if (this.storage.getItem('restData') !== null) {
			console.log('reading restData');
			console.log(this.storage.getItem('restData'));
			AppData.restData.next(this.storage.getItem('restData'));
		}

		if (this.storage.getItem('outletId') != null) {
			AppData.outletIdSubject.next(this.storage.getItem('outletId'));
		}

		if (this.storage.getItem('userList') != null) {
			AppData.userList = this.storage.getItem('userList');
		}

		if (!this.storage.getItem('outletList')) {
			const obj = {
				changeIndex: false,
				outletList: this.storage.getItem('outletList') ? this.storage.getItem('outletList') : []
			};
			AppData.outletListSubject.next(obj);
		}

		AppData.token.subscribe((data: string) => {
			console.log('Setting Token');
			this.setToken(data);
		});

		AppData.userDataSubject.subscribe((userData) => {
			this.storage.setItem('userData', userData);
			AppData.userData = Object.assign(new UserModel, userData);
		});

		AppData.outletIdSubject.subscribe((outletId) => {
			this.storage.setItem('outletId', outletId);
			AppData.outletId = outletId;
			console.log('AppData OutletId', AppData.outletId);
		});

		AppData.userListSubject.subscribe((userList) => {
			this.storage.setItem('userList', userList);
			AppData.userList = userList;
		});

		AppData.outletListSubject.subscribe((outletListDetails) => {
			console.log('outletList Subject called', outletListDetails);
			if (outletListDetails) {
				if (outletListDetails.outletList) {
					this.storage.setItem('outletList', outletListDetails.outletList);
				}
				AppData.outletList = outletListDetails.outletList;
				if (AppData.outletList && AppData.outletList.length > 0) {
					if (outletListDetails.changeIndex) {
						AppData.selectedOutLet = 0;
						AppData.outletIdSubject.next(AppData.outletList[AppData.selectedOutLet]['outletId']);
					} else {
						// below condition is added to check new outlet creation is coming.
						if (AppData.selectedOutLet === -1) {
							AppData.selectedOutLet = AppData.outletList.length - 1;
							AppData.outletIdSubject.next(AppData.outletList[AppData.selectedOutLet]['outletId']);
						}
					}

					for (var i = 0; i < AppData.outletList.length; i++) {
						if (AppData.outletList[i].outletId === AppData.outletId) {
							AppData.outletNameSubject$.next(AppData.outletList[i].displayName);
						}
					}
				} else {
					AppData.outletIdSubject.next('');
					this.storage.setItem('outletList', null);
				}
			}
		});

		AppData.restData.subscribe((restData) => {
			if (restData) {
				this.storage.setItem('restData', restData);
			}
		});

		AppData.selectedIndexSubject.subscribe((index) => {
			AppData.selectedIndex = index;
		});

		AppData.profileMenuSelectedSubject.subscribe((index) => {
			AppData.profileMenuSelectedIndex = index;
		});

		AppData.fcmTokenSub$.subscribe((fcmToken) => {
			if (fcmToken) {
				AppData.fcmToken = fcmToken;
			}
		});

	}

	setToken(token: string) {
		this.storage.setItem('token', token);
		console.log('token set');
	}

	isAuthenticatedFunction() {
		return !(this.storage.getItem('token') === null);
	}

}
