import { Injectable } from '@angular/core';
import { ApiRequestService } from '@shared/index';

@Injectable({
	providedIn: 'root'
})
export class CategoryReorderModalService {

    constructor(private apiService: ApiRequestService) {
    }
    
    // for category reorder
	categoryReorder(obj) {
		return this.apiService.post('categoryReorder', obj);
	}
}