import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OtpPasswordPage } from '../components/otp-password/otp-password.page';

@Injectable({
	providedIn: 'root'
})
export class OtpPasswordModalService {

	constructor(private modalController: ModalController) { }

	async presentModal(inputData) {
		return new Promise(async (resolve, reject) => {
			const modal = await this.modalController.create({
				component: OtpPasswordPage,
				cssClass: 'otp-modal',
				backdropDismiss: false,
				componentProps: {
					'inputData': inputData
				}

			});
			await modal.present();
			const { data } = await modal.onDidDismiss();
			console.log(data);
			resolve(data);
		});

	}
}
