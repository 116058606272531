import { getInputFieldValidation } from '@shared/utils/common-functions';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppData } from '@shared/index';
import { CategoryService } from './service/category.service';
import { MenuItemCreationService } from '../menu-item-creation/service/menu-item-creation.service'
import { CreateCategory } from './model/create-category.model';
import { AVOID_SPACE, CHAR_WITH_NUMBER } from 'src/app/core/constants/app-constants';

@Component({
	selector: 'app-category',
	templateUrl: './category.page.html',
	styleUrls: ['./category.page.scss'],
})
export class CategoryPage implements OnInit {

	isSubcategory = true;
	addCategoryForm: FormGroup;
	submitted = false;
	itemCategoryList = [];
	categoryObj = {};
	categoryDisable = false;
	subCategoryDisable = false;
	createCategoryModel = new CreateCategory();
	constructor(private menuItemService: MenuItemCreationService,
		public modalController: ModalController, navParams: NavParams,
		private fb: FormBuilder,
		private categoryService: CategoryService) {

		this.categoryObj = navParams.get('categoryData');
	}

	ngOnInit() {
		this.initForm();

		// for getting category and sub category list from server
		this.getCategoryList();

		// for setting value in edit mode
		this.setEditValue();
	}


	// for getting category and sub category list from server
	getCategoryList() {
		this.menuItemService.getCategory(AppData.outletId).then((res) => {
			if (res['body']['data']['categories'].length > 0) {
				const itemCategoryData = res['body']['data']['categories'];
				const categoryItem = itemCategoryData.filter(category => category.name !== 'unassigned');
				this.itemCategoryList = categoryItem;
			}
		});
	}


	//for setting category and sub category based on condition
	setEditValue() {
		if (this.categoryObj['type'] === 'CATEGORY') {
			this.subCategoryDisable = true;
			this.addCategoryForm.controls['name'].setValue(this.categoryObj['catObj']['name']);
		} else if (this.categoryObj['type'] === 'SUB_CATEGORY') {
			this.categoryDisable = true;
			this.isSubcategory = false;
			this.addCategoryForm.controls['name'].setValue(this.categoryObj['subCatObj']['name']);
			this.addCategoryForm.controls['category'].setValue(this.categoryObj['catObj']['catId']);
		}
	}


	initForm() {
		this.addCategoryForm = this.fb.group({
			'name': [this.createCategoryModel['name'], [Validators.required,
			// , Validators.pattern(CHAR_WITH_NUMBER)
			Validators.minLength(3), Validators.maxLength(30)]],
			'category': [this.createCategoryModel['category']]
		});
	}


	close() {
		this.modalController.dismiss({
			isClose: true,
			data: {
				'isSaveAndEdit': false
			}
		});
	}

	// for checking valid fields
	getValid(fieldName) {
		return getInputFieldValidation(this.addCategoryForm, fieldName, this.submitted);
	}

	// for add or edit category and sub-category 
	saveCategory() {
		this.submitted = true;
		if (this.addCategoryForm.valid) {
			let requestObj = {
				'outletId': AppData.outletId,
				'name': this.addCategoryForm.controls['name'].value
			}
			if (this.categoryObj['catObj'] === undefined || this.categoryObj['catObj'] === '') {
				if (this.isSubcategory === true) {
					this.categoryService.addcategory(requestObj).then(response => {
						this.closeWithData();
					});
				} else {
					requestObj['category'] = this.addCategoryForm.value.category;
					this.categoryService.addSubcategory(requestObj).then(response => {
						this.closeWithData();
					});
				}

			} else {
				if (this.categoryObj['type'] === 'CATEGORY') {
					requestObj['category'] = this.categoryObj['catObj']['catId'];
					this.categoryService.updateCategory(requestObj).then(response => {
						this.closeWithData();
					});
				} else if (this.categoryObj['type'] === 'SUB_CATEGORY') {
					requestObj['category'] = this.addCategoryForm.value.category;
					requestObj['subCategory'] = this.categoryObj['subCatObj']['catId']
					this.categoryService.updateSubcategory(requestObj).then(response => {
						this.closeWithData();
					});
				}
			}
		} else {
			return;
		}
	}

	// for close modal with data
	closeWithData() {
		this.modalController.dismiss({
			isClose: true, data: {
				'isSaveAndEdit': true
			}
		});
	}

	// for change Category and Sub-Category view
	onCategoryChange(value: boolean) {
		this.isSubcategory = value;
		if (this.isSubcategory === true) {
			this.addCategoryForm.controls['name'].reset();
			this.addCategoryForm.get('category').setValidators([]);
			this.addCategoryForm.controls['category'].reset();
		}
		if (this.isSubcategory === false) {
			this.addCategoryForm.controls['name'].reset();
			this.addCategoryForm.get('category').setValidators([Validators.required]);
		}
	}
}
