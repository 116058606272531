import { Injectable } from '@angular/core';
import { ApiRequestService, AppData } from '@shared/index';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class OffersService {
    offersDataSubject = new BehaviorSubject([]);
    constructor(private apiService: ApiRequestService) {
        console.log("=======>offer Service");
    }
    // create offer
    createOffer(obj){
        return this.apiService.post('createOffer', obj);
    }
    // get offers
    getAllOffers(outletId){
        return this.apiService.get('getOffers','?outletId=' + outletId);
    }
    // delete offer
    deleteOffer(offerId){
        return this.apiService.get('deleteOffer','?outletId=' + AppData.outletId + '&couponId=' + offerId);
    }
}