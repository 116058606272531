import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shared/gaurds/auth/auth.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{
		path: 'panel',
		canLoad: [AuthGuard],
		loadChildren: './modules/home/home.module#HomePageModule'
	},
	{ path: 'login', loadChildren: './modules/login/login.module#LoginPageModule' },
	{ path: 'signup', loadChildren: './modules/signup/signup.module#SignupPageModule' },
	{ path: 'otp-password', loadChildren: './shared/modals/components/otp-password/otp-password.module#OtpPasswordPageModule' },
	{ path: '**', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'menu-operational-time', loadChildren: './modules/menu-management/components/menu-operational-time/menu-operational-time.module#MenuOperationalTimePageModule' },


];

@NgModule({
	imports: [RouterModule.forRoot(routes,
		// { enableTracing: true }  // <-- debugging purposes only
	)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
