import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-offer-view',
  templateUrl: './offer-view.page.html',
  styleUrls: ['./offer-view.page.scss'],
})
export class OfferViewPage implements OnInit {
  offerData
  constructor(private navParams: NavParams,
    public modalController: ModalController,) { 
    this.offerData = this.navParams.get('offerViewData');
    console.log(this.offerData);
    
  }

  ngOnInit() {
  }
  
  close() {
		this.modalController.dismiss({ isClose: true, data: {} });
  }

}
