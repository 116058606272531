import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PermissionsService } from '@shared/services/permissions.service';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	ActivatedRoute,
	Router,
	CanActivateChild
} from '@angular/router';
import { AppData } from '../../services/app-data.service';
import { ToastService } from '@shared/services/toast.service';

@Injectable({
	providedIn: 'root'
})
export class PermissionsGuard implements CanActivate, CanActivateChild {

	constructor(private permissionService: PermissionsService, private router: Router, private toast: ToastService) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		const allowedRoles = next.routeConfig.path;
		console.log('Alllowed Roles:', allowedRoles);
		console.log('Alllowed State:', state);
		if (allowedRoles === 'restaurant-profile') {
			if (AppData.userData['userType'] === 'owner') {
				return true;
			} else {
				this.toast.presentToast('Only owner can see Account Details', 'Warning', 'warning');
				return false;
			}
		}
		const isAuthorized = this.permissionService.isAuthorized(allowedRoles);

		if (!isAuthorized) {
			// if not authorized, navigated to home page
			this.toast.presentToast('Permission Denied', 'Warning', 'warning');
			return false;
			// this.router.navigate(['home']);
		}
		return isAuthorized;
	}

	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		const allowedRoles = next.data.allowedRoles;
		const isAuthorized = this.permissionService.isAuthorized(allowedRoles);

		if (!isAuthorized) {
			// if not authorized, navigated to home page
			this.router.navigate(['/home']);
		}

		return isAuthorized;
	}
}
