import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ProfileService } from 'src/app/modules/profile/service/profile.service';
import { OutletDetails } from 'src/app/modules/profile/models/outlet-details.model';
import { AppData } from '@shared/index';
import { OutletsModalService } from '@shared/modals/services/outlets-modal.service';
import { ToastService } from '@shared/services/toast.service';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-sub-menu',
	templateUrl: './sub-menu.component.html',
	styleUrls: ['./sub-menu.component.scss'],
})
export class SubMenuComponent implements OnInit, OnDestroy {
	@Input() isOutlet = false;
	@Input() callFrom: string;
	@Input() promoPlan: string;
	@Output() ProPlan: EventEmitter<any> = new EventEmitter();
	outletList = [];
	outletName;
	outletNameSubscription = new Subscription();
	constructor(private outletModalService: OutletsModalService,
		private profileService: ProfileService,
		private toast: ToastService) {
		console.log('Sub-menu Const Called');
		this.outletList = AppData.outletList;
	}

	ngOnInit() {
		console.log('----->promoPlan', this.promoPlan);
		this.outletNameSubscription = AppData.outletNameSubject$.subscribe((outletName) => {
			if (outletName) {
				this.outletName = outletName;
			}
		});
	}

	ngOnDestroy() {
		this.outletNameSubscription.unsubscribe();
	}

	createNewOutlet() {
		this.profileService.allowAddOutlet().then((response) => {
			if (response['body']['data']['canCreate']) {
				AppData.outletDetails.next(new OutletDetails());
				AppData.outletId = '';
				AppData.profileMenuSelectedSubject.next(0);
				AppData.selectedOutLet = -1;
			} else {
				this.toast.presentToast(ErrorMessage.OUTLET_MAX_SIZE_CREATION_MSG, 'Warning', 'warning');
			}

		});

	}

	// for change outlet view
	onChangeOutlets() {
		console.log('--->Outlet list-->', this.outletList);
		// Issue #61 | Shrikant | In place of this.outletList added AppData.outletList | 08-06-2019 | START
		const inputData = {
			displayType: 'SUB_HEADER',
			outletList: AppData.outletList,
			callFrom: this.callFrom
		};
		// Issue #61 | Shrikant | 08-06-2019 | END
		this.outletModalService.changeOutlets(inputData);
	}

	upgradePlan() {
		console.log('----------->');
		this.ProPlan.emit()
	}

}
