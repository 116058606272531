export const FSSAIFormValidationError = {
    'licenseNumeber': [
        { type: 'required', message: 'Lisence number is required.' },
        { type: 'minlength', message: 'Please enter valid Lisence number.' },
    ],
    'authoName': [
        { type: 'required', message: 'Authorized name is required.' }
    ],
    'addressLineOne': [
        { type: 'required', message: 'Address is required.' }
    ],
    'ownerName': [
        { type: 'required', message: 'Owner name is required.' }
    ],
    'contactNumber': [
        { type: 'required', message: 'Contact number is required.' }
    ],
    'vaildDate': [
        { type: 'required', message: 'Valid date is required.' }
    ],
}

export const GSTInFormValidationError = {
    'gstInNumber': [
        { type: 'required', message: 'GSTIN number is required.' },
        { type: 'pattern', message: 'Please enter valid GSTIN number.' },
    ],
    'confirmGSTNumber': [
        { type: 'required', message: 'Confirm GSTIN number is required.' },
        { type: 'mustMatch', message: 'GSTIN number must match.' }
    ],
    'businessNumber': [
        { type: 'required', message: 'Business number is required.' },
    ],
    'isUnderGST': [
        { type: 'required', message: 'This field is required.' },
    ]
}

export const MenuValidationError = {
    'name': [
        { type: 'required', message: 'Item name is required.' },
        { type: 'minlength', message: 'Item name cannot be less than 3 character.' },
        { type: 'maxlength', message: 'Item name cannot be more than 100 characters long.' },
    ],
    'isVeg': [
        { type: 'required', message: 'Please select meals.' },
    ],
    'price': [
        { type: 'required', message: 'Item price is required.' },
    ],
    'categoryname': [
        { type: 'required', message: 'Please select item category.' },
    ],
}



export const ErrorMessage = {
    MAX_FILE_SIZE_MSG: 'Maximum file 2 mb allowed.',
    ALLOWED_DOC_TYPE_MSG: 'Only pdf, jpg and png are allowed.',
    ALLOW_ITEM_FILE_TYPE_MSG: 'Only jpg and png are allowed.',
    ALLOWED_ONLY_EXCEL_MSG: 'Only excel allowed.',
    UPLOAD_CERTIFICATE_MSG: 'Please upload certificate.',
    ALLOWED_ONLY_ZIP_MSG: 'Only zip file allowed.',
    MAX_SIZE_ALLOWED_FOR_UPLOAD_FILE_MSG: 'Maximum file 200 mb allowed.',
    MAX_VAL_FOR_ADDED_OPTION_MSG: 'Max value can not be greater then added options.',
    MIN_MAX_OPTION_SELECTION_MSG: 'Min should be less then max.',
    ADD_AT_LEAST_ONE_OPTION: 'Please add at least one option.',
    OUTLET_OFF_BEFORE_DELETE_ITEM_MSG: 'Please close outlet before deleting item.',
    OUTLET_OFF_BEFORE_EDIT_ITEM_MSG: 'Please close outlet before editing item.',
    DELETE_CONFIRM_MSG: 'Are you sure do you want to delete?',
    OUTLET_MAX_SIZE_CREATION_MSG: 'You have reached maximum outlet creation limit. please contact support team for info.',
    FILL_OTHER_REASON_MSG: 'Please enter reject reason description.',
    SELECT_REASON_MSG: 'Please select proper reason.',
    SELECT_OUT_OF_STOCK_MSG: 'Please select out of stock item.',
    CERTIFICATE_EXPIRE_DATE_MSG: 'Certificate expire date should be greater than or equal to current date.',
    START_DATE_SHOULD_BE_GREATER_THEN_TODAY_MSG: 'Please check start date is > = the today\'s date',
    SELECT_START_DATE_MSG: 'Please select the start date',
    SELECT_END_DATE_MSG: 'Please select the end date',
    CHECK_END_AND_START_DATE_MSG: 'Please check end date is > = the start date',
    CHECK_START_AND_CURRENT_TIME_MSG: 'Please check start time is > the current time',
    ADD_MENU_ITEM_MSG: 'Please Add Menu Selections',
    CHECK_END_START_DATE_MSG: 'Please check start time is < the end time',
    ADD_ITEM_AND_CATE_HAVE_MENU_ITEM_MSG: 'Please add menu item Or Added Category don\'t have menu items',
    SELECT_ADDONS_MSG: 'Please select addon',
    SELECT_ADDON_VARIANT_MSG: 'Please select addons or variants',
    CREATE_ADDON_VARIANT_BEFORE_MAPPING_MSG: 'Please Add Addons/variants before mapping creation',
    SELECT_CATEGORY_MSG: 'Please select Category',
    ADD_ITEM_UNDER_SUB_CATEGORY_MSG: 'Please Add Menu Items Under selected Sub Category',
    SELECT_MENU_ITEM_MSG: 'Please select Menu Items',
    ADD_ITEM_UNDER_CATEGORY_MSG: 'Please add Menu Items Under selected Category',
    SELECT_SUB_CATEGORY_MSG: 'Please select Sub Category',
    OUTLET_OFF_BEFORE_MENU_MAPPING_MSG: 'Please close outlet before menu mapping.',
    GSTIN_VALIDATIONS_MSG: 'GSTN is not verified. please proceed with Details Submission',
    IFSC_VALIDATIONS_MSG: 'IFSC not verified as of now. Please proceed with details submission',
    CLOSE_PAYMENT_LOADER_MSG: 'Payment was not completed. Please try again after some time',
    PAYMENT_SUCCESS_MSG: 'payment success',
    SELECT_AT_LEAST_ONE_OUTLET_MSG: 'Please select at-least one outlet.',
    OUTLET_OFF_BEFORE_CREATE_ADDON_MSG: 'Please close outlet before create Addon.',
    OUTLET_OFF_BEFORE_CREATE_CAT_SUB_CAT_MSG: 'Please close outlet before create category or subcategory.',
    OUTLET_OFF_BEFORE_CREATE_ITEM_MSG: 'Please close outlet before create menu item.',
    OUTLET_OFF_BEFORE_UPLOAD_EXCEL_MSG: 'Please close outlet before upload menu excel.',
    OUTLET_OFF_BEFORE_UPLOAD_ZIP_MSG: 'Please close outlet before upload menu image zip file.',
    OUTLET_OFF_BEFORE_CREATE_OUTLET_MSG: 'Please close outlet before create outlet offer.',
    OUTLET_OFF_BEFORE_CREATE_OFFER_MSG: 'Please close outlet before create item offer.',
    UPLOAD_AUDIO_DURATION_MSG: 'Please provide notification sound duration more then 3.',
    MAX_VAL_CAN_NOT_LESS_THAN_ONE_MSG: 'Max value Can not less then 1.',
    AUDIT_SCHEDULE_DATE_MSG: 'Please select audit schedule date.',
    CHOOSE_AUDIT_DATE_MSG: 'The audit date you choose has to be at least 2 days from todays date.',
    PAYMENT_FAILED_MSG: 'Payment failed. Please try again after some time.',
    PLEASE_SELECT_OUTLET_MSG: 'Please select outlet',
    UPLOAD_OUTLET_LOGO: 'Please upload outlet logo.',
    ENTER_ADDRESS_ON_MAP: 'Please enter your address on map.',
    SAVE_FSSAI_ALERT_MSG: 'Please complete FSSAI details within 2 months else outlet will be removed.',
    UPLOAD_IMG_FOLDER_MSG: 'Please upload image folder.',
    UPLOAD_MENU_ITEM_MSG: 'Please upload menu item.',
    ZIP_UPLOAD_SUCCESS_MSG: 'Zip uploaded successfully.',
    SELECT_AT_LEAST_ONE_PERMISSION_MSG: 'Please Select At-least one Permission.',
    TIME_SELECTION_CONDITION_MSG: 'Selection time should be greater than current time.',
    PLEASE_SELECT_TIME_MSG: 'Please select time',
    CLOSE_OUTLET_BEFORE_UPDATE_SLOT_MSG: 'Please close outlet before Updating slot.',
    SELECT_AT_LEAST_ONE_DATE_MSG: 'Please select at least one day',
    START_END_TIME_NOT_SAME_MSG: 'Start Time and End Time can\'t be same',
    START_TIME_EARLIER_THAN_END_TIME_MSG: 'Start Time should be earlier than End Time',
    CLOSE_OUTLET_BEFORE_DELETE_MENU_ITEM_SLOT_MSG: 'Please close outlet before deleting slot',
    RIBBON_MAX_LENGTH_MSG: 'Ribbon max length is 15',
    ACTION_ONCE_COMPLETE_CAN_NOT_REVERTED_MSG: 'Are you sure? Action once completed can not be reverted',
    SELECT_AL_LEAST_ONE_DAY_MSG: 'Please select at least one day',
    START_END_TIME_CAN_NOT_SAME_MSG: 'Start Time and End Time can\'t be same',
    ACCEPT_VALIDATION_MSG: 'Please accept the validation',
    SELECT_AT_LEAST_ONE_MENU_ITEM_MSG: 'Please select at-least one menu item',
    OPTIONS_DUPLICATE_MSG: 'Option name should be unique.'
};