import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DateTimePage } from '../components/date-time/date-time.page';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor(private modalController: ModalController) { }

  async openDateTimeModal() {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalController.create({
        component: DateTimePage,
        cssClass: 'date-time-modal-css',
        backdropDismiss: false,
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      console.log("--------->Date Time Data", data);
      resolve(data);
    });
  }

}
