import { getInputFieldValidation } from '@shared/utils/common-functions';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MenuService } from './menu.service';
import { AddonsItemService } from 'src/app/modules/menu-management/services/addons-item.service';
import { AppData, ToastService } from '@shared/index';
import { MenuItemService } from 'src/app/modules/menu-management/services/menu-item.service';
import { MenuMapping } from './model/menu-mapping.model';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';

@Component({
	selector: 'app-menu-addons-mapping',
	templateUrl: './menu-addons-mapping.page.html',
	styleUrls: ['./menu-addons-mapping.page.scss'],
})
export class MenuAddonsMappingPage implements OnInit {

	variantList = [];
	addonList = [];
	menuItemObj = {};
	menuItemList = [];
	tagArray = [];
	selectSubCat = [];

	addonChipList = [];
	arrayMenuitemId = [];
	selectItem = [];
	submitted = false;

	menuMappingForm: FormGroup;
	menuMappingModel = new MenuMapping();

	constructor(private fb: FormBuilder,
		public modalController: ModalController,
		private addonService: AddonsItemService,
		private menuMappingService: MenuService,
		private toast: ToastService) { }

	ngOnInit() {
		this.initForm();
		this.getAddonsOrVariantData();
	}

	initForm() {
		this.menuMappingForm = this.fb.group({
			'variant': [this.menuMappingModel['variant']],
			'addon': [this.menuMappingModel['addon']],
		});
	}

	close() {
		this.modalController.dismiss({ isClose: true, data: { isSave: false } });
	}

	// for getting addons or variant list from server
	getAddonsOrVariantData() {
		this.addonService.getAddonsOrVariants(AppData.outletId).then((response) => {
			if (response['body']['data']['addons'] != null) {
				const addonAndVariant = response['body']['data']['addons'];
				this.variantList = addonAndVariant.filter(variantItem => variantItem.isVariant === true);
				this.addonList = addonAndVariant.filter(addonItem => addonItem.isVariant === false);
			} else {
				this.toast.presentToast(ErrorMessage.CREATE_ADDON_VARIANT_BEFORE_MAPPING_MSG, 'No Addons/Variants', 'error');
			}
		});
	}

	// for checking valid fields
	getValid(fieldName) {
		return getInputFieldValidation(this.menuMappingForm, fieldName, this.submitted);
	}

	menuSelections(MenuItems, event) {
		this.tagArray = event;
	}

	createObjForSaveMenuMapping() {
		let arrayItemArray = [];
		let myNewList = [];
		this.tagArray.forEach((tag) => {
			arrayItemArray = arrayItemArray.concat(tag.menuItems.map(menuItem => menuItem.id));
		});
		const addonIdList = this.addonChipList.map(addons => addons.addonId);

		if (this.menuMappingForm.get('variant').value !== '') {
			addonIdList.push(this.menuMappingForm.get('variant').value);
		}
		if (arrayItemArray.length > 0) {
			myNewList = Array.from(new Set(arrayItemArray));
		}
		const body = {
			'outletId': AppData.outletId,
			'addons': addonIdList,
			'menuItems': myNewList
		}
		return body;
	}

	async saveMenu() {
		const reqBody = await this.createObjForSaveMenuMapping();
		if (reqBody.addons.length > 0) {
			if (reqBody.menuItems.length > 0) {
				this.saveMenuMappingData(reqBody);
			} else {
				this.toast.presentToast(ErrorMessage.ADD_ITEM_AND_CATE_HAVE_MENU_ITEM_MSG, 'Warning', 'warning');
			}
		} else {
			this.toast.presentToast(ErrorMessage.SELECT_ADDON_VARIANT_MSG, 'Warning', 'warning');
		}
	}

	// for saving menu mapping data
	saveMenuMappingData(obj) {
		this.menuMappingService.menuItemMapping(obj).then(res => {
			this.modalController.dismiss({ isClose: true, data: { isSave: true } });
		});
	}

	addAddons() {
		const index = this.menuMappingForm.get('addon').value;
		if (index > -1) {
			const found = this.addonChipList.some(item => item.addonId === this.addonList[index]['addonId']);
			if (!found) {
				this.addonChipList.push(this.addonList[index]);
			}

			// after add addon in chip view list setting value empty
			this.menuMappingForm.controls['addon'].setValue('-1');
		} else {
			this.toast.presentToast(ErrorMessage.SELECT_ADDONS_MSG, 'Warning', 'warning');
		}
	}

	removeAddons(ind) {
		this.addonChipList.splice(ind, 1);
	}
}
