import { Injectable } from '@angular/core';
import { ApiRequestService } from '@shared/index';
import { AppData } from '@shared/index';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MenuItemCreationService {
	// categoryDataSubject = new BehaviorSubject([]);
	// categoryList = [];
	// itemList = [];
	itemDataSubject = new BehaviorSubject([]);
	constructor(private apiService: ApiRequestService) {
	}

	// for menu item create
	menuItemCreate(obj) {
		return this.apiService.post('menuItemCreate', obj);
	}
	menuItemImage(obj) {
		return this.apiService.post('menuItemImage', obj);
	}
	menuItemUpdate(obj) {
		return this.apiService.post('menuItemUpdate', obj);
	}

	// for category get
	getCategory(outletId) {
		return this.apiService.get('getCategory', '?outletId=' + outletId);
	}

	nextAvailability(obj) {
		return this.apiService.post('nextAvailabilityItem', obj);
	}
}
