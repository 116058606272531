import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appIonContentScroll]'
})
export class IonContentScrollDirective {
  constructor(el: ElementRef) {
    console.log('entered into directive', el.nativeElement);
    const stylesheet = `
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
        border-radius: 10px;
      }

        div.inner-scroll.scroll-y {
        background-color: unset !important;
        width: calc(100% - -6px);
      }
    
      ::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
      }
    
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-image: -webkit-gradient(
          linear,
          left bottom,
          left top,
          color-stop(0.44, rgb(122, 153, 217)),
          color-stop(0.72, rgb(122, 153, 217)),
          color-stop(0.86, rgb(122, 153, 217))
        );
      }
    `;

    const shadow = el.nativeElement.shadowRoot || el.nativeElement.attachShadow({ mode: 'open' });
    const styleElmt = shadow.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      shadow.appendChild(barStyle);
    }
  }
}
