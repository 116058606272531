export class MenuMapping {
	private variant: string;
	private addon: string;
	private category: string;
	private subCategory: string;
	private menuItem: string;

	constructor() {
		this.variant = '';
		this.addon = '-1';
		this.category = '';
		this.subCategory = '-1';
		this.menuItem = '-1';
	}



	public get _variant(): string {
		return this.variant;
	}

	public get _addon(): string {
		return this.addon;
	}

	public get _category(): string {
		return this.category;
	}

	public get _subCategory(): string {
		return this.subCategory;
	}

	public get _menuItem(): string {
		return this.menuItem;
	}

	public set _variant(value: string) {
		this.variant = value;
	}

	public set _addon(value: string) {
		this.addon = value;
	}

	public set _category(value: string) {
		this.category = value;
	}

	public set _subCategory(value: string) {
		this.subCategory = value;
	}

	public set _menuItem(value: string) {
		this.menuItem = value;
	}

}
