import { Injectable } from '@angular/core';
import { ApiRequestService, AppData, LoaderService } from '@shared/index';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { GOOGLE_API_KEY } from 'src/app/core/constants/app-constants';

@Injectable({
	providedIn: 'root'
})
export class ProfileService {
	private headers: HttpHeaders;

	constructor(private apiService: ApiRequestService, private router: Router,
		private loaderService: LoaderService) {
		this.headers = new HttpHeaders({
			'api-access-key': '5c164b7d-2957-44ad-bd8f-e81f4d9e2dd4',
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		});
		console.log('=======>Profile Service');

	}

	getCurrentAddress(lat, lng) {
		return this.apiService.get('getCurrentAddress', '?latlng=' + lat + ',' + lng + '&key=' + GOOGLE_API_KEY, '');
	}


	// for check FSSAI Number
	fssaiNumberCheck(obj) {
		return this.apiService.post('fssaiCheck', obj);
	}

	// for GSTIn Number  checked
	gstInNumberCheck(gstNumber: string) {
		return this.apiService.get('gstInCheck', '/' + gstNumber.toLowerCase(), this.headers);
	}

	// for save outlet data
	saveOutletDetails(outletData) {
		return this.apiService.post('outlet', outletData);
	}
	// Save menu item file
	saveMenuMenuExcelFile(menuExcelFile) {
		return this.apiService.post('menuImport', menuExcelFile);
	}

	// for getting outlet details data
	async getOutletData(outletId) {
		// this.loaderService.showLoader();
		this.apiService.get('fetchOutletDetails', '?outletId=' + outletId).then((response) => {
			AppData.outletDetails.next(response['body']['data']);
			// this.outletDetails = response['data'];
			console.log('OUTLETS DATA', response);
			this.getuserList(outletId);
			// this.loaderService.hideLoader();
		});
	}

	getuserList(outletId) {
		this.apiService.get('viewUsers', '?outletId=' + outletId).then((response) => {
			AppData.userListSubject.next(response['body']['data']);
		});
	}

	setOnOff(reqData) {
		return this.apiService.put('setAvailability', reqData);
	}


	// for get bank details by ifsc code
	getBankDetails(ifscNumber) {
		return this.apiService.get('getBankDetails', '/' + ifscNumber);
	}

	getCertificateLink(obj) {
		return this.apiService.post('fssaiCertificateLink', obj);
	}

	// for setting new outlet status
	setOutletStatus(outletId, newStatus) {
		const newOutletList = AppData.outletList;
		newOutletList.find(item => item.outletId === outletId).status = newStatus;
		const obj = {
			changeIndex: false,
			outletList: newOutletList
		};
		AppData.outletListSubject.next(obj);
	}

	allowAddOutlet() {
		return this.apiService.get('allowOutletAdd');
	}

	getUploadStatus() {
		return this.apiService.get('uploadStatus', '?outletId=' + AppData.outletId);
	}

	updateUploadStatus(obj) {
		return this.apiService.post('uploadStatus', obj);
	}

	importProcess() {
		return this.apiService.get('processImageMapping', '?outletId=' + AppData.outletId);
	}

	// for save outlets in local storage
	setOutletDataInLocalStorage(basicDetail, responseData) {
		const outlet = {
			'available': 'off',
			'displayName': basicDetail.displayName,
			'outletId': AppData.outletId,
			'outletName': basicDetail.outletName,
			'status': responseData['newOutletStatus'],
			'config': {
				'notificationConfig': {
					'onClickOrderAccept': false,
					'soundUrl': ''
				},
				'printConfig': {
					'printKot': true
				}
			}
		};
		const outletList = AppData.outletList;
		if (!(outletList.some(ol => ol.outletId === outlet.outletId))) {
			outletList.push(outlet);
		} else {
			outletList.forEach((outletDet, index) => {
				if (outletDet.outletId === outlet.outletId) {
					// to set the previos available status of outlet while modifying the details.
					outlet.available = outletDet.available;
					outletList[index] = outlet;
				}
			});
		}
		const obj = {
			changeIndex: false,
			outletList: outletList
		};
		AppData.outletListSubject.next(obj);
	}

	getCuisinesAndCities() {
		return this.apiService.get('getCuisinesAndCities', `?fields=,cuisines,citiesAndLeadType`);
	}
}
