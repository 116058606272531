import { ALERT_CANCEL_BUTTON_OBJ } from './../../../../core/constants/app-constants';
import { NavParams, ModalController, AlertController, IonContent } from '@ionic/angular';
import { getInputFieldValidation } from '@shared/utils/common-functions';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { CommonService } from '@shared/services/common.service';
import { MenuModel } from './models/menu.model';
import { CategoryModalService } from '../../services/category-modal.service';
import { AppData, ToastService } from '@shared/index';
import { MenuItemCreationService } from './service/menu-item-creation.service';
import { GST_PERCENTAGE, WEIGHT_TYPE_LIST, ALLOWED_MENU_IMAGE_TYPE } from 'src/app/core/constants/app-constants';
import { TimeSlotModel } from 'src/app/modules/profile/components/operational-time/models/time-slot.model';
import { MenuItemService } from 'src/app/modules/menu-management/services/menu-item.service';
import { WEEKDAYS } from 'src/app/core/constants/app-constants';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';
import { LoaderService } from '@shared/services/loader.service';

@Component({
	selector: 'app-menu-item-creation',
	templateUrl: './menu-item-creation.page.html',
	styleUrls: ['./menu-item-creation.page.scss'],
})
export class MenuItemCreationPage implements OnInit {
	itemImageUrl = './assets/uploadphoto.png';
	gstPercentageList = [];
	editMenuItemData = {};
	subCategoryList = [];
	weightTypeList = [];
	itemImageList = [];
	categoryList = [];
	addMenuForm: FormGroup;
	menuModel = new MenuModel();
	submitted = false;
	imageUrl: string;
	file: File;
	base64;
	showCloseIcon = false;
	addonsOrVariantArray = [];
	updateRibbonName = '';
	ribbonCreateUpdateText = 'Add Ribbon';
	showSlots: boolean = false;
	slotLists = [];
	operationTimeForm: FormGroup;
	allSelected = false;
	slotDetails: TimeSlotModel = new TimeSlotModel();
	weekdays = WEEKDAYS;
	enableOperationalForm = false;
	oldOpHours;
	addSlot;
	slotNumber: any;
	component = 'menuItem';
	@ViewChild(IonContent) content: IonContent;

	constructor(public mService: CommonService,
		private fb: FormBuilder, public alertController: AlertController,
		public modalController: ModalController,
		private categoryService: CategoryModalService,
		private menuItemService: MenuItemCreationService,
		navParams: NavParams,
		private toast: ToastService,
		private menuItemsService: MenuItemService,
		private loaderService: LoaderService
	) {
		this.editMenuItemData = navParams.get('inputData');
		if (this.editMenuItemData !== 'NEW_MENU_CREATION' && this.editMenuItemData !== null) {
			if (this.editMenuItemData['opHours'].length > 0) {
				this.showSlots = true;
				this.slotLists = this.editMenuItemData['opHours'];
			}
		}
	}

	ngOnInit() {
		// for getting menu data while edit
		this.menuItemEditData();

		this.initForm();

		// for getting category list from server
		this.getCategorySubCategoryList();

		// for set gst percentage list
		this.gstPercentageList = GST_PERCENTAGE;
		this.weightTypeList = WEIGHT_TYPE_LIST;
	}
	// menu form validation
	initForm() {

		this.addMenuForm = this.fb.group({
			'name': [this.menuModel['name'], [Validators.required,
			Validators.minLength(3), Validators.maxLength(80)]],
			'isVeg': [this.menuModel['isVeg'], Validators.required],
			'containsEgg': [this.menuModel['containsEgg']],
			'price': [this.menuModel['price'], [Validators.required, Validators.minLength(1), Validators.min(1), Validators.maxLength(4)]],
			'portionSize': [this.menuModel['portionSize'], [Validators.required, Validators.minLength(0), Validators.min(0), Validators.maxLength(4)]],
			'packingCharges': [this.menuModel['packingCharges'], [Validators.maxLength(3)]],
			'gst': [this.menuModel['gst']],
			'category': [this.menuModel['category'], [Validators.required]],
			'unit': [this.menuModel['unit'], [Validators.required]],
			'subCategory': [this.menuModel['subCategory']],
			// Issue#45 | Jimit | 07-06-2019 | max length increase to 250| START
			'description': [this.menuModel['description'], [Validators.minLength(3),
			Validators.maxLength(350)]],
			// Issue#45 | Jimit | 07-06-2019 |  max length increase to 250 | END
			'ribbon': ['', [Validators.maxLength(15)]]
		});
		this.addMenuForm.valueChanges.subscribe(data => {
			this.menuModel = Object.assign(this.menuModel, data);
			this.onGSTChange();
		});

		this.initOperationTimeForm();
	}

	initOperationTimeForm() {
		const groupControls = this.slotDetails._days.map(c => new FormControl(c));

		this.operationTimeForm = this.fb.group({
			everyday: [this.allSelected],
			days: new FormArray(groupControls),
			prepTime: [this.slotDetails._prepTime],
			startTime: [this.slotDetails._startTime],
			endTime: [this.slotDetails._endTime]

		});
		this.operationTimeForm.valueChanges.subscribe((formData) => {
			this.checkEveryday(formData.days);
		});
	}

	// for getting category list
	getCategorySubCategoryList() {
		this.menuItemService.getCategory(AppData.outletId).then((res) => {
			this.categoryList = res['body']['data']['categories'];
			// const index = this.categoryList.findIndex(item => item.name === 'unAssigned');
			// this.categoryList.splice(this.categoryList.findIndex(item => item.name === 'unAssigned'), 1);
			this.categoryList = this.categoryList.filter(item => item.name !== 'unassigned');
			if (this.editMenuItemData !== 'NEW_MENU_CREATION') {
				const obj = this.categoryList.find(item =>
					item.catId === this.editMenuItemData['category']);
				if (obj !== undefined && obj['subCategories'].length > 0) {
					this.subCategoryList = obj['subCategories'];
				}

			}
		});
	}
	// click set form data
	menuItemEditData() {
		if (this.editMenuItemData !== 'NEW_MENU_CREATION' && this.editMenuItemData !== null) {
			this.updateRibbonName = this.editMenuItemData['ribbon'];
			if (this.updateRibbonName !== '') {
				this.ribbonCreateUpdateText = "Update Ribbon";
			}

			let packingCharges = 0;
			if (this.editMenuItemData['additionalCharges'].length > 0) {
				this.editMenuItemData['additionalCharges'].map(item => {
					if (item.name === 'packingCharges') {
						packingCharges = item.value;
					}
				});
			}
			if (this.editMenuItemData['imageUrl'].length > 0) {
				if (this.editMenuItemData['imageUrl'].match(AppData.outletId) !== null) {
					this.itemImageUrl = this.editMenuItemData['imageUrl'];
					this.showCloseIcon = true;
				}
			}
			if (this.editMenuItemData['addons'].length > 0) {
				console.log(this.editMenuItemData['addons']);
				if (this.editMenuItemData['addons'][0] !== null)
					this.addonsOrVariantArray = this.editMenuItemData['addons'];
			}

			this.menuModel = Object.assign(this.menuModel, this.editMenuItemData);
			this.menuModel._category = this.menuModel._category === 'unassigned' ? '' : this.menuModel._category;
			this.menuModel._packingCharges = packingCharges;
			this.menuModel.calculateGST();
		}
	}

	remove(id: number): void {
		this.addonsOrVariantArray.splice(id, 1);
	}

	// for checking valid fields
	getValid(fieldName) {
		return getInputFieldValidation(this.addMenuForm, fieldName, this.submitted);
	}

	// GST Calculation
	onGSTChange() {
		this.menuModel.calculateGST();
	}
	close() {
		this.modalController.dismiss({ isClose: true, data: {} });
	}

	// on select category get the subcategory
	onCategoryChange() {
		this.subCategoryList = this.categoryList.filter(item => item.catId === this.addMenuForm.get('category').value)[0].subCategories;
		// this.addMenuForm.controls['subCategory'].setValue('');
		// this.addMenuForm.get('subCategory').setValidators([]);
		if (this.subCategoryList.length > 0) {
			this.addMenuForm.get('subCategory').setValidators([Validators.required]);
			// Issue#101 | Jimit | Sub category value not validated while menu edit | START
			this.addMenuForm.controls['subCategory'].setValue('', { emitEvent: true });
			// Issue#101 | Jimit | Sub category value not validated while menu edit | END
		} else {
			this.addMenuForm.get('subCategory').setValidators([]);
			// this.addMenuForm.get('subCategory').updateValueAndValidity();
			this.addMenuForm.controls['subCategory'].setValue('');
		}
	}

	// upload item image
	onUploadMenuImage(event: any) {
		this.file = event.target.files[0];
		if (event.target.files && this.file) {
			if (ALLOWED_MENU_IMAGE_TYPE.indexOf(this.file.type) !== -1) {
				const totalSizeMB = Math.round(this.file.size / Math.pow(1024, 2));
				if (totalSizeMB < 2) {
					const reader = new FileReader();
					// let file = event.target.files[0];
					reader.readAsDataURL(this.file); // read file as data url
					// called once readAsDataURL is completed
					reader.onload = (event) => {
						this.itemImageList.push({
							'name': this.file.name,
							'fileType': 'itemImage',
							'contentType': this.file.type,
							'size': this.file.size,
							'imageUrl': reader.result,
						});
						this.itemImageUrl = String(reader.result);
						this.base64 = reader.result;
						this.showCloseIcon = true;
					};
				} else {
					this.toast.presentToast(ErrorMessage.MAX_FILE_SIZE_MSG, 'Warning', 'warning');
				}
			} else {
				this.toast.presentToast(ErrorMessage.ALLOW_ITEM_FILE_TYPE_MSG, 'Warning', 'warning');
			}
		}
	}
	// add ribbon
	async openRibbonAlert() {
		const alert = await this.alertController.create({
			inputs: [
				{
					name: 'ribbon',
					type: 'text',
					placeholder: 'Ribbon Name',
					max: 15,
					value: this.updateRibbonName
				}
			],
			buttons: [ALERT_CANCEL_BUTTON_OBJ, {
				text: 'Ok',
				handler: data => {
					if (data.ribbon.length > 15) {
						this.toast.presentToast(ErrorMessage.RIBBON_MAX_LENGTH_MSG, 'Warning', 'warning');
						return false;
					} else {
						if (data.ribbon != '') {
							this.addMenuForm.value['ribbon'] = data.ribbon;
							this.updateRibbonName = data.ribbon;
							this.ribbonCreateUpdateText = 'Update Ribbon';
						} else {
							this.updateRibbonName = '';
							this.addMenuForm.value['ribbon'] = '';
							this.ribbonCreateUpdateText = 'Add Ribbon';
						}
					}
				}
			}
			]
		});
		await alert.present();
	}

	// clear ribbon
	async openRibbonClear() {
		const alert = await this.alertController.create({
			message: ErrorMessage.ACTION_ONCE_COMPLETE_CAN_NOT_REVERTED_MSG,
			buttons: [ALERT_CANCEL_BUTTON_OBJ
				, {
					text: 'Okay',
					handler: () => {
						this.ribbonCreateUpdateText = 'Add Ribbon';
						this.updateRibbonName = '';
						this.addMenuForm.value['ribbon'] = '';
					}
				}
			]
		});
		await alert.present();
	}

	// on submit get menu form data
	onMenuSubmit() {
		this.submitted = true;
		// Object.keys(this.addMenuForm.controls).forEach((key) => this.addMenuForm.get(key).setValue(this.addMenuForm.get(key).value.toString().trim()));
		this.menuModel = Object.assign(this.menuModel, this.addMenuForm.value);
		this.menuModel['name'] = this.addMenuForm.value.name;
		this.menuModel['outletId'] = AppData.outletId;
		this.menuModel['ribbon'] = this.updateRibbonName;
		this.menuModel['portionSize'] = Number(this.addMenuForm.value.portionSize);
		this.menuModel['packingCharges'] = String(this.menuModel['packingCharges']) === "" ? 0 : this.menuModel['packingCharges']
		if (this.base64 !== undefined) {
			this.menuModel['imageUrl'] = {
				data: this.base64,
				name: this.file.name
			};
		}
		if (this.addMenuForm.valid) {
			if (this.editMenuItemData === 'NEW_MENU_CREATION') {
				if (this.base64 === undefined) {
					this.menuModel['imageUrl'] = '';
				}
				// for check form validation
				this.createMenuItem();
			} else {
				if (this.editMenuItemData['imageUrl'] !== '' && this.base64 === undefined) {
					this.menuModel['imageUrl'] = this.itemImageUrl;
				}
				if (this.menuModel['imageUrl'] === './assets/uploadphoto.png') {
					this.menuModel['imageUrl'] = '';
				}
				if (this.base64 !== undefined) {
					this.menuModel['imageUrl'] = {
						data: this.base64,
						name: this.file.name
					};
				}
				this.menuModel['itemId'] = this.editMenuItemData['itemId'];
				this.menuModel['addons'] = this.addonsOrVariantArray.map(addons => addons.addonId);
				this.updateMenuItem();
			}

		} else {
			return;
		}
	}

	// for create menu item
	createMenuItem() {
		this.menuItemService.menuItemCreate(this.menuModel).then((response) => {
			this.modalController.dismiss({ isClose: false, data: {} });
		});
	}

	// for update menu item
	updateMenuItem() {
		this.menuItemService.menuItemUpdate(this.menuModel).then((response) => {
			this.modalController.dismiss({ isClose: false, data: {} });
		});
	}

	// opens category popup
	openCategoryModel() {
		const obj = {
			'catObj': '',
			'subCatObj': '',
			'type': ''
		};
		this.categoryService.openCategoryCreationModal(obj).then((response) => {
			const responseData = response['data'];
			if (responseData['isSaveAndEdit']) {
				this.getCategorySubCategoryList();
			}
		});
	}

	removeImg() {
		this.base64 = undefined;
		if (this.base64 === undefined) {
			this.itemImageUrl = './assets/uploadphoto.png';
			this.menuModel['imageUrl'] = '';
		}
		this.showCloseIcon = false;
	}

	createObjForDeleteSlot(slotData) {
		let reqData = {};
		let itemIdList = [];
		itemIdList.push(this.editMenuItemData['itemId'])
		reqData['outletId'] = AppData.outletId;
		reqData['step'] = 'opHours';
		reqData = Object.assign(reqData, this.slotLists[slotData.index]);
		reqData['type'] = 'deleteTime';
		reqData['itemIds'] = itemIdList;
		return reqData;
	}

	async deleteSlot(slotData) {
		const alert = await this.alertController.create({
			message: ErrorMessage.DELETE_CONFIRM_MSG,
			buttons: [ALERT_CANCEL_BUTTON_OBJ, {
				text: 'Okay',
				handler: async () => {
					const reqBody = await this.createObjForDeleteSlot(slotData)
					this.loaderService.showLoader();
					this.menuItemsService.addMenuSlot(reqBody).then((response) => {
						if (this.slotLists.length > 0) {
							this.showSlots = true;
						}
						this.menuItemEditData();
						this.getSlotList();
					}).catch((err) => {
						this.loaderService.hideLoader();
					});
				}
			}]
		});
		await alert.present();
	}

	checkEveryday(days) {

		if (days.indexOf(false) > -1) {
			this.operationTimeForm.controls['everyday'].setValue(false, { emitEvent: false });
			this.allSelected = false;
		} else {
			this.operationTimeForm.controls['everyday'].setValue(true, { emitEvent: false });
			this.allSelected = true;
		}
	}

	everydayClicked(ev) {
		this.allSelected = !this.allSelected;
		this.operationTimeForm.controls.days['controls'].forEach((formControl, index) => {
			this.operationTimeForm.controls.days['controls'][index].setValue(this.allSelected,
				{ emitEvent: false });
		});

	}

	editSlot(slotData, ind) {
		this.oldOpHours = this.slotLists[slotData.index];
		this.addSlot = false;
		this.enableOperationalForm = true;
		this.slotNumber = ind;
		this.slotDetails = Object.assign(this.slotDetails, this.slotLists[slotData.index]);
		if (this.slotDetails._days.indexOf(false) > -1) {
			this.allSelected = false;
		} else {
			this.allSelected = true;
		}

		this.initOperationTimeForm();

		setTimeout(() => {
			this.scrollToBottom();
		}, 500)
	}

	clear() {
		this.allSelected = false;
		this.initOperationTimeForm();
		this.enableOperationalForm = false;
	}

	addNewSlot() {
		this.addSlot = true;
		this.slotDetails = new TimeSlotModel();
		this.allSelected = false;
		this.initOperationTimeForm();
		this.enableOperationalForm = true;

		setTimeout(() => {
			this.scrollToBottom();
		}, 500)

	}

	setTime(type, data) {
		if (data.isAm) {
			this.operationTimeForm.controls[`${type}`].setValue(data.hour + '' + data.mins, { emitEvent: false });

		} else {
			if (Number(data.hour) === 12) {
				this.operationTimeForm.controls[`${type}`].setValue(data.hour + '' + data.mins, { emitEvent: false });
			} else {
				this.operationTimeForm.controls[`${type}`].setValue((Number(data.hour) + 12) + '' + data.mins, { emitEvent: false });
			}
		}
	}

	addAndUpdateItemSlotObj() {
		let body = {}
		body['outletId'] = AppData.outletId;
		body['step'] = 'opHours';
		body = Object.assign(body, this.operationTimeForm.value);
		return body;
	}

	async updateMenuSlot() {
		if (!this.isError()) {
			const reqBody = {};
			reqBody['outletId'] = AppData.outletId;
			reqBody['step'] = 'opHours';
			reqBody['oldHours'] = this.oldOpHours;
			reqBody['type'] = 'update';
			reqBody['newHours'] = Object.assign({}, this.operationTimeForm.value);
			let myNewList = [];
			myNewList.push(this.editMenuItemData['itemId']);
			reqBody['itemIds'] = myNewList;
			this.loaderService.showLoader();
			this.menuItemsService.addMenuSlot(reqBody).then((response) => {
				if (this.slotLists.length > 0) {
					this.showSlots = true;
				}
				this.allSelected = false;
				this.initOperationTimeForm();
				this.clear();

				this.getSlotList();
			}).catch((err) => {
				this.loaderService.hideLoader();
			});
		}
	}



	async addMenuSlot() {
		if (!this.isError()) {
			const reqBody = await this.addAndUpdateItemSlotObj();
			reqBody['type'] = 'newTime';
			reqBody['itemIds'] = [this.editMenuItemData['itemId']];
			this.loaderService.showLoader();
			this.menuItemsService.addMenuSlot(reqBody).then((response) => {
				this.allSelected = false;
				this.initOperationTimeForm();
				this.clear();

				this.getSlotList();
				this.enableOperationalForm = false;
			}).catch((err) => {
				this.loaderService.hideLoader();
			});;
		}

	}

	getSlotList() {
		const obj = {
			itemId: this.editMenuItemData['itemId'],
			outletId: AppData.outletId
		};

		this.menuItemsService.menuItemById(obj).then((res) => {
			this.slotLists = res['body']['data']['opHours'];
			if (this.slotLists.length > 0) {
				this.showSlots = true;
			} else {
				this.showSlots = false;
			}
		}); 3

		this.loaderService.hideLoader();
	}

	isError() {
		let isError = false;
		const temp = this.operationTimeForm.value['days'].filter((day) => {
			return day;
		});

		if (temp.length === 0) {
			isError = true;
			this.toast.presentToast(ErrorMessage.SELECT_AL_LEAST_ONE_DAY_MSG, 'Error', 'error');
			return isError;
		}

		if (this.operationTimeForm.value['startTime'] === this.operationTimeForm.value['endTime']) {
			this.toast.presentToast(ErrorMessage.START_END_TIME_CAN_NOT_SAME_MSG, 'Error', 'error');
			return true;
		}
		if (this.operationTimeForm.value['startTime'] > this.operationTimeForm.value['endTime']) {
			this.toast.presentToast(ErrorMessage.START_TIME_EARLIER_THAN_END_TIME_MSG, 'Error', 'error');
			return true;
		}
	}

	scrollToBottom() {

		this.content.scrollToBottom(500);

	}
}
