export class GSTInDetails {
    private outletId: string;
    private step: string;
    private gstinNumber: string;
    private rGSTInNumber: string;
    private companyName: string;
    private gstinType: string;
    private gstinEntered: boolean;
    private gstApplicableOn: string;

    constructor() {
        this.outletId = '';
        this.step = 'gstin';
        this.gstinNumber = '';
        this.rGSTInNumber = '';
        this.companyName = '';
        this.gstinType = 'composition';
        this.gstinEntered = false;
        this.gstApplicableOn = 'outlet';
    }

    public get _outletId(): string {
        return this.outletId;
    }

    public get _step(): string {
        return this.step;
    }

    public get _gstinNumber(): string {
        return this.gstinNumber;
    }
    public get _rGSTInNumber(): string {
        return this.rGSTInNumber;
    }

    public get _companyName(): string {
        return this.companyName;
    }

    public get _gstinType(): string {
        return this.gstinType;
    }

    public get _gstinEntered(): boolean {
        return this.gstinEntered;
    }

    public get _gstApplicableOn(): string {
        return this.gstApplicableOn;
    }

    public set _gstApplicableOn(value: string) {
        this._gstApplicableOn = value;
    }
    public set _outletId(value: string) {
        this.outletId = value;
    }

    public set _step(value: string) {
        this.step = value;
    }

    public set _gstinNumber(value: string) {
        this.gstinNumber = value;
    }
    public set _rGSTInNumber(value: string) {
        this.rGSTInNumber = value;
    }
    public set _companyName(value: string) {
        this.companyName = value;
    }

    public set _gstinType(value: string) {
        this.gstinType = value;
    }

    public set _gstinEntered(value: boolean) {
        this.gstinEntered = value;
    }

}