export class BasicDetailsAddress {
    private addressLine: string;
    private apartmentAddress: string;
    private streetAddress1: string;
    private streetAddress2: string;
    private landmark: string;
    private city: string
    private state: string;
    private pincode: string;
    private country: string;

    constructor() {
        this.addressLine = '';
        this.apartmentAddress = '';
        this.streetAddress1 = '';
        this.streetAddress2 = '';
        this.landmark = '';
        this.city = '';
        this.state = '';
        this.pincode = '';
        this.country = 'India';
    }

    public get _addressLine(): string {
        return this.addressLine;
    }

    public get _apartmentAddress(): string {
        return this.apartmentAddress;
    }

    public get _streetAddress1(): string {
        return this.streetAddress1;
    }

    public get _streetAddress2(): string {
        return this.streetAddress2;
    }

    public get _landmark(): string {
        return this.landmark;
    }
    public get _city(): string {
        return this.city;
    }
    public get _state(): string {
        return this.state;
    }

    public get _pincode(): string {
        return this.pincode;
    }

    public get _country(): string {
        return this.country;
    }

    public set _addressLine(value: string) {
        this.addressLine = value;
    }

    public set _apartmentAddress(value: string) {
        this.apartmentAddress = value;
    }

    public set _streetAddress1(value: string) {
        this.streetAddress1 = value;
    }

    public set _streetAddress2(value: string) {
        this.streetAddress2 = value;
    }

    public set _landmark(value: string) {
        this.landmark = value;
    }

    public set _city(value: string) {
        this.city = value;
    }
    public set _state(value: string) {
        this.state = value;
    }

    public set _pincode(value: string) {
        this.pincode = value;
    }

    public set _country(value: string) {
        this.country = value;
    }



}