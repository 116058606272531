import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private audio: HTMLAudioElement;

  constructor() { }

  // for set sound url 
  setSoundUrl(soundUrl) {
    this.audio = new Audio(soundUrl);
    this.audio.load();
    this.audio.loop = true;
    this.audio.addEventListener('loadeddata', () => {
      let duration = this.audio.duration;
      console.log(duration);
      // The duration variable now holds the duration (in seconds) of the audio clip 
    });
    let count = 0;
    setInterval(() => {
      console.log('Audio Element:', count++, this.audio);
    }, 1000 * 60 * 60);
  }

  // for play sound when new notification will come
  playNotification() {
    return Promise.resolve().then(() => {
      this.audio.play();
      setTimeout(() => {
        this.stopNotificationSound();
      }, 1000 * 60 * 3);
    });

    // return this.audio.play();
  }

  // for stop notification sound
  stopNotificationSound() {
    if (this.audio !== undefined) {
      this.audio.pause();
      this.audio.currentTime = 0.0;
    }
  }
}
