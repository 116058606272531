import { Component, OnInit, Output, EventEmitter, OnChanges, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MenuMapping } from '../menu-addons-mapping/model/menu-mapping.model';
import { MenuItemService } from 'src/app/modules/menu-management/services/menu-item.service';
import { AppData, ToastService } from '@shared/index';
import { ErrorMessage } from 'src/app/core/constants/validation-errors';
import { Subscription } from 'rxjs';
import { LoaderService } from '@shared/services/loader.service';

@Component({
	selector: 'app-menu-selections',
	templateUrl: './menu-selections.page.html',
	styleUrls: ['./menu-selections.page.scss'],
})
export class MenuSelectionsPage implements OnInit, OnDestroy, OnChanges {

	menuItemObj = {};
	menuItemList = [];
	tagArray = [];
	selectSubCat = [];
	selectItem = [];
	arrayMenuitemId = [];
	submitted = false;
	menuSelectionForm: FormGroup;
	menuMappingModel = new MenuMapping();
	@Output() emitArrayForm: EventEmitter<any> = new EventEmitter();
	@Input() arrayMenuChip: any;
	@Input() dataTriggered: boolean;
	outletIdSubscription = new Subscription();
	constructor(private fb: FormBuilder,
		private menuService: MenuItemService,
		private toast: ToastService,
	) { }

	ngOnInit() {
		this.initForm();
		this.outletIdSubscription = AppData.outletIdSubject.subscribe((outletId) => {
			this.getMenuList();
		});

	}

	ngOnChanges() {
		this.tagArray = this.arrayMenuChip;

		if (this.dataTriggered) {
			this.selectSubCat = [];
			this.selectItem = [];
			this.getMenuList();
		}
	}

	initForm() {
		this.menuSelectionForm = this.fb.group({
			'category': [this.menuMappingModel['category']],
			'subCategory': [this.menuMappingModel['subCategory']],
			'menuItem': [this.menuMappingModel['menuItem']],
		});
		if (this.arrayMenuChip !== undefined) {
			this.tagArray = this.arrayMenuChip;
		}
	}

	ngOnDestroy() {
		this.outletIdSubscription.unsubscribe();
		console.log('menu selection is destroyed');
	}

	// for getting category and subCategory and items from server
	async getMenuList() {
		let menuItemList = [];
		await this.menuService.menuListData(AppData.outletId).then((res) => {
			this.menuItemObj = res['body']['data'];
			menuItemList = Object.values(res['body']['data']);
			this.menuItemList = menuItemList.filter(category => category.cat_id !== 'unassigned');
		});
	}

	categorySelect() {
		if (this.menuSelectionForm.value.category !== '') {
			this.menuSelectionForm.reset({
				'category': this.menuSelectionForm.value.category,
				'subCategory': '-1',
				'menuItem': '-1'
			});
			if (this.menuSelectionForm.value.category === 'all') {
				this.selectSubCat = [];
				this.selectItem = [];
			} else {
				this.selectSubCat = this.menuItemObj[this.menuSelectionForm.value.category]['sub_cat'];
				if (this.selectSubCat.length === 0) {
					this.selectItem = this.menuItemObj[this.menuSelectionForm.value.category].menu_items;
				}
			}

		} else if (this.menuSelectionForm.value.category === 'all') {
			this.selectSubCat = [];
			this.selectItem = [];
		}
	}

	subcategorySelect() {
		if (this.menuSelectionForm.value.subCategory !== '') {
			console.log(this.menuSelectionForm.value.subCategory);
			this.menuSelectionForm.reset({
				'category': this.menuSelectionForm.value.category,
				'subCategory': this.menuSelectionForm.value.subCategory,
				'menuItem': '-1'
			});
			if (this.menuSelectionForm.value.subCategory === 'all') {
				this.selectItem = [];
			} else {
				this.selectItem = this.menuItemObj[this.menuSelectionForm.value.category].sub_cat[this.menuSelectionForm.value.subCategory].menu_items;
			}
		} else if (this.menuSelectionForm.value.subCategory === 'all') {
			this.selectItem = [];
		}
	}


	addItem() {

		const formValue = this.menuSelectionForm.value;
		let tag;
		if (formValue.category !== '') {
			if (formValue.category === 'all') {
				this.menuItemList.forEach(catObj => {
					tag = {
						name: catObj.cat_name,
						menuItems: this.getMenuIdsFromCategory(catObj)
					};
					this.addValueInTag(tag);
				});
			} else {
				//  Sub Category Present and Not Selected So Show Error
				if (formValue.subCategory === '-1' &&
					this.menuItemObj[formValue.category].sub_cat.length > 0) {
					this.toast.presentToast(ErrorMessage.SELECT_SUB_CATEGORY_MSG, 'Warning', 'warning');

					// All Sub Category Selected So Add Selected Category
				} else if (formValue.subCategory === 'all') {
					tag = {
						name: this.menuItemObj[formValue.category].cat_name,
						menuItems: this.getMenuIdsFromCategory(this.menuItemObj[formValue.category])
					};
					// this.tagArray.push(tag);

					// Sub Category Not Present but Menu Items Present under Category
				} else if (formValue.subCategory === '-1' &&
					this.menuItemObj[formValue.category].menu_items.length > 0) {

					if (formValue.menuItem === '-1') {
						this.toast.presentToast(ErrorMessage.SELECT_MENU_ITEM_MSG, 'Warning', 'warning');

						// All Menu items Selected
					} else if (formValue.menuItem === 'all') {
						tag = {
							name: this.menuItemObj[formValue.category].cat_name,
							menuItems: this.getMenuIdsFromCategory(this.menuItemObj[formValue.category])
						};
						// this.tagArray.push(tag);

						// Menu items Selected
					} else {
						tag = {
							name: this.menuItemObj[formValue.category].menu_items[formValue.menuItem].item_name,
							menuItems: [(this.menuItemObj[formValue.category].menu_items[formValue.menuItem])]
						};
						// this.tagArray.push(tag);
					}

					// Sub Category Selected
				} else {
					let catObj = this.menuItemObj[formValue.category];
					if (catObj['menu_items'].length === 0 && catObj['sub_cat'].length === 0) {
						this.toast.presentToast(ErrorMessage.ADD_ITEM_UNDER_CATEGORY_MSG, 'Warning', 'warning');
					}
					if (formValue.menuItem === '-1' &&
						this.menuItemObj[formValue.category].sub_cat[formValue.subCategory].menu_items.length > 0) {
						this.toast.presentToast(ErrorMessage.SELECT_MENU_ITEM_MSG, 'Warning', 'warning');

						// Menu Item is not present under Selected Sub Category
					} else if (formValue.menuItem === '-1' &&
						this.menuItemObj[formValue.category].sub_cat[formValue.subCategory].menu_items.length === 0) {
						this.toast.presentToast(ErrorMessage.ADD_ITEM_UNDER_SUB_CATEGORY_MSG, 'Warning', 'warning');

						// All menu Items Selected So Add Selected Sub Category
					} else if (formValue.menuItem === 'all') {
						tag = {
							name: this.menuItemObj[formValue.category].cat_name + ' (' + this.menuItemObj[formValue.category].sub_cat[formValue.subCategory].sub_cat_name + ')',
							menuItems: this.menuItemObj[formValue.category].sub_cat[formValue.subCategory].menu_items,
						};
						// this.tagArray.push(tag);

						// Menu Item Selected
					} else {
						tag = {
							name: this.menuItemObj[formValue.category].sub_cat[formValue.subCategory].menu_items[formValue.menuItem].item_name,
							menuItems: [(this.menuItemObj[formValue.category].sub_cat[formValue.subCategory].menu_items[formValue.menuItem])]
						};
						// this.tagArray.push(tag);
					}

				}
			}

		} else {
			this.toast.presentToast(ErrorMessage.SELECT_CATEGORY_MSG, 'Warning', 'warning');
		}

		this.addValueInTag(tag);
		console.log('Tags Array:', this.tagArray);
		this.emitArrayForm.emit(this.tagArray);

	}

	addValueInTag(tag) {
		if (tag) {
			let tempList = [];
			tempList = this.tagArray.filter(tagLi => tagLi.name === tag.name);
			if (tempList.length === 0) {
				this.tagArray.push(tag);
			}
			this.menuSelectionForm.reset({
				'category': '',
				'subCategory': '-1',
				'menuItem': '-1'
			});
		}
	}
	remove(id: number): void {
		this.tagArray.splice(id, 1);
		this.arrayMenuitemId.splice(id, 1);
		this.emitArrayForm.emit(this.tagArray);
	}
	getMenuIdsFromCategory(catgory) {
		let returnMenuItems = [];
		catgory.sub_cat.forEach((subCat) => {
			returnMenuItems = returnMenuItems.concat(subCat.menu_items);
		});
		returnMenuItems = returnMenuItems.concat(catgory.menu_items);

		return returnMenuItems;
	}
}